import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

export const Background = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
`;
export const ModalContent = styled.div`
    max-width: 85%;
    max-height: 90%;
    margin: 0 auto;
    background: #fff;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    padding: 1rem;
    position: relative;
    overflow-y: auto;
    @media screen and (max-width: 1024px){
        height: 90vh;
    }
`;
export const CloseModalButton = styled(MdClose)`
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
`;
export const Form = styled.form`
    width: 100%;
    text-align: center;
    h1{
        margin-bottom: 2rem;
        text-transform: uppercase;
    }
`;
export const FormContent = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
export const FormGroup = styled.div`
    flex: 1 1 20rem;
    margin: 0 0.5rem 1.5rem 0.5rem;
    position: relative;
    svg{
        position: absolute;
        top: 25%;
        right: 1%;
        cursor: pointer;
    }
    img{
        max-width: 3rem;
        height: auto;
    }
`;