import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import { Background, ModalContent, CloseModalButton, Form, FormContent, FormGroup, Delete, ImageGroup, WapperImages, TitlePhoto, Options, BodyImage, Buttons } from './styles';
import UploadImage from './UploadImage';
import api from '../../../../services/api';
import ModalImage from '../../../ModalImage';
import { IoTrashOutline } from "react-icons/io5";
import swal from 'sweetalert';
import jwt from 'jsonwebtoken';
import jwt_decode from "jwt-decode";
import Auth from '../../../../services/auth';
import InputMask from 'react-input-mask';

export default function ModalBicycles({ showModal, setShowModal }) {
    const modalRef = useRef();
    const [id, setId] = useState();
    const [modelo, setModelo] = useState('');
    const [ano, setAno] = useState('');
    const [cor, setCor] = useState('');
    const [chassi, setChassi] = useState('');
    const [condicao, setCondicao] = useState('');
    const [acessorios, setAcessorios] = useState('');
    const [detalhes, setDetalhes] = useState('');
    const [data_aquisicao, setData_aquisicao] = useState('');
    const [foto_chassi, setFoto_chassi] = useState();
    const [foto_total, setFoto_total] = useState();
    const [foto_perfil, setFoto_perfil] = useState();
    const [foto_detalhe, setFoto_detalhe] = useState();
    const [url_foto_chassi, setUrl_foto_chassi] = useState(null);
    const [url_foto_total, setUrl_foto_total] = useState(null);
    const [url_foto_perfil, setUrl_foto_perfil] = useState(null);
    const [url_foto_detalhe, setUrl_foto_detalhe] = useState(null);
    const [default_foto_chassi, setDefault_foto_chassi] = useState(null);
    const [default_foto_total, setDafault_foto_total] = useState(null);
    const [default_foto_perfil, setDafault_foto_perfil] = useState(null);
    const [default_foto_detalhe, setDafault_foto_detalhe] = useState(null);
    const [isPreview, setIsPreview] = useState(false);
    const [haveImage, setHaveImage] = useState(false);
    const [imageSelected, SetImageSelected] = useState('');
    const [show_modal_image, setShow_modal_image] = useState(false);
    const [nameFileSelected, setNameFilesSelectd] = useState('');
    const [token, setToken] = useState(Auth.token())

    const animation = useSpring({
        config: {
            duration: 150
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`
    });
    const keyPress = useCallback(e => {
        if (e.key === 'Escape' && showModal) setShowModal(!showModal);
    }, [setShowModal, showModal]);
    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        jwt.verify(token, 'acibike@citec');
        var decoded = jwt_decode(token);
        setId(decoded.id)
    }, [id]);

    useEffect(() => {
        if (foto_chassi || foto_total || foto_perfil || foto_detalhe) setHaveImage(true); else { setHaveImage(false) };
        if (foto_chassi) foto_chassi.map(file => setUrl_foto_chassi(URL.createObjectURL(file)));
        if (foto_total) foto_total.map(file => setUrl_foto_total(URL.createObjectURL(file)));
        if (foto_perfil) foto_perfil.map(file => setUrl_foto_perfil(URL.createObjectURL(file)));
        if (foto_detalhe) foto_detalhe.map(file => setUrl_foto_detalhe(URL.createObjectURL(file)));
    }, [foto_chassi, foto_total, foto_perfil, foto_detalhe]);

    async function handleDeleteImage(url, query_params, closeModalImage, isPreview) {
        if (isPreview) {
            const data = {
                id: id,
                url: url,
                query_params: query_params,
            };
            swal({
                title: "Você tem certeza?",
                text: "O arquivo sera deletado permanentemente!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        api.post(`admin/bicycles`, { data }).then(response => {
                            if (response.data.success) {
                                swal(response.data.success, {
                                    icon: "success",
                                });
                                if (closeModalImage) setShow_modal_image(!show_modal_image);
                                if (query_params == 'foto_chassi') setUrl_foto_chassi(null);
                                if (query_params == 'foto_total') setUrl_foto_total(null);
                                if (query_params == 'foto_perfil') setUrl_foto_perfil(null);
                                if (query_params == 'foto_detalhe') setUrl_foto_detalhe(null);
                            } else {
                                swal("Erro ao deletar o arquivo!", {
                                    icon: "error",
                                });
                            }
                        })
                    } else {
                        swal("Cancelado!");
                    }
                });
        } else {
            handleDeletePreviewImage(query_params, closeModalImage);
        }

    }
    function handleDeletePreviewImage(query_params, closeModalImage) {
        swal({
            title: "Você tem certeza?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    if (closeModalImage) setShow_modal_image(!show_modal_image);
                    if (query_params === 'foto_chassi') setUrl_foto_chassi(null); setFoto_chassi(null);
                    if (query_params === 'foto_total') setUrl_foto_total(null); setFoto_total(null);
                    if (query_params === 'foto_perfil') setUrl_foto_perfil(null); setFoto_perfil(null);
                    if (query_params === 'foto_detalhe') setUrl_foto_detalhe(null); setFoto_detalhe(null);
                }
            });

    }
    async function handleSubmit(e) {
        e.preventDefault();
        let date;
        if (data_aquisicao) {
            const aux = data_aquisicao.split("T");
            date = aux[0];
        }

        if (haveImage === true) {
            let formData = new FormData();
            formData.append('id_usuario', id);
            formData.append('modelo', modelo);
            formData.append('ano', ano);
            formData.append('cor', cor);
            formData.append('chassi', chassi);
            formData.append('status', condicao);
            formData.append('acessorios', acessorios);
            formData.append('detalhes', detalhes);
            formData.append('data_aquisicao', data_aquisicao);
            formData.append('foto_chassi', url_foto_chassi);
            formData.append('foto_total', url_foto_total);
            formData.append('foto_perfil', url_foto_perfil);
            formData.append('foto_detalhe', url_foto_detalhe);
            if (foto_chassi) foto_chassi.forEach(file => formData.append(`${chassi}_chassi`, file));
            if (foto_total) foto_total.forEach(file => formData.append(`${chassi}_total`, file));
            if (foto_perfil) foto_perfil.forEach(file => formData.append(`${chassi}_perfil`, file));
            if (foto_detalhe) foto_detalhe.forEach(file => formData.append(`${chassi}_detalhe`, file));

            // var data = {};
            // var int = 0
            // formData.forEach((value, key) => {
            //     // data[key] = value != 'null' || value != 'undefined' ? value : undefined
            //     // Valor das fotos não está chegando como undefined em si, mas sim como undefined dentro de uma string
            //     if (value != 'null' || value != 'undefined' || value != null || value != undefined) {
            //         data[key] = value
            //         int++
            //     }
            // });

            try {
                const response = await api.post(`/bikes?auth=${token}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Accept: 'multipart/form-data'
                    }
                });
                if (response.data.error) return swal("Atenção!", response.data.error, "warning");
                if (response.data.success) {
                    swal({
                        title: "Cadastrado com sucesso",
                        icon: "success",
                    })
                }
                setTimeout(() => {
                    window.location.reload();
                }, 700)
                setShowModal(!showModal);
            } catch (error) {
                alert('Primeiro erro: ' + error)
                // swal('Atenção!', `Serviço indisponível no momento!`, 'error');
            }
        } else {
            const data = {
                id_usuario: id,
                modelo: modelo,
                ano: ano,
                cor: cor,
                chassi: chassi,
                status: condicao,
                acessorios: acessorios,
                detalhes: detalhes,
                data_aquisicao: date,
                foto_chassi: url_foto_chassi,
                foto_total: url_foto_total,
                foto_perfil: url_foto_perfil,
                foto_detalhe: url_foto_detalhe,
            }
            try {
                const response = await api.post(`/bikes?auth=${token}`, { data });
                if (response.data.error) {
                    return swal("Atenção!", response.data.error, "warning");
                }
                swal({
                    title: "Cadastrado com sucesso",
                    icon: "success",
                })
                setShowModal(!showModal);
            } catch (error) {
                alert(`Segundo erro: ` + error);
            }
        }

    }
    function handleViewImage(url_image, name, isPreview) {
        setIsPreview(isPreview);
        SetImageSelected(url_image)
        console.log(imageSelected)
        setNameFilesSelectd(name)
        setShow_modal_image(!show_modal_image);
    }


    return (
        <>
            {showModal ? (
                <Background ref={modalRef} onClick={(e) => modalRef.current === e.target ? setShowModal(!showModal) : null}>
                    <animated.div style={animation}>
                        <ModalContent>
                            <Form onSubmit={handleSubmit}>
                                <h1>Cadastrar Bike</h1>
                                <FormContent>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Modelo"
                                            value={modelo}
                                            onChange={e => setModelo(e.target.value)}
                                            maxLength="150"
                                            required
                                        />
                                        <label>Modelo</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputMask
                                            type="text"
                                            mask='9999'
                                            placeholder="Ano de fabricação"
                                            value={ano}
                                            onChange={e => setAno(e.target.value)}
                                            required
                                        />
                                        <label>Ano de fabricação</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Chassi"
                                            title='Ex. 07187253'
                                            value={chassi}
                                            onChange={e => setChassi(e.target.value)}
                                            required
                                        />
                                        <label>Chassi</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <select
                                            value={condicao}
                                            onChange={e => setCondicao(e.target.value)}
                                        >
                                            <option value="REGULAR">Regular</option>
                                            <option value="ROUBADA">Roubada</option>
                                            <option value="PERDIDA">Perdida</option>
                                        </select>
                                        <label>Status</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Acessórios"
                                            title='Digite a lista dos acessórios importantes de sua Bike, por exemplo, Farol de lanterna, Kit Shimano, refletores etc.'
                                            value={acessorios}
                                            onChange={e => setAcessorios(e.target.value)}
                                            required
                                        />
                                        <label>Acessorios</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="date"
                                            placeholder="Data de aquisição"
                                            value={data_aquisicao}
                                            style={{ fontSize: '15px' }}
                                            onChange={e => setData_aquisicao(e.target.value)}
                                        />
                                        <label>Data de aquisição</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Detalhes"
                                            title='Forneça detalhes que só você conhece de sua bicicleta como riscos na pintura, adesivos etc.'
                                            value={detalhes}
                                            onChange={e => setDetalhes(e.target.value)}
                                            maxLength="60"
                                        />
                                        <label>Detalhes</label>
                                    </FormGroup>
                                </FormContent>
                                <WapperImages>
                                    <ImageGroup>
                                        <TitlePhoto>Foto do chassi</TitlePhoto>
                                        {url_foto_chassi ?
                                            <BodyImage>
                                                <img src={url_foto_chassi} data-tooltip="Foto do chassi" onClick={default_foto_chassi ? () => handleViewImage(url_foto_chassi, 'foto_chassi', true) : (() => handleViewImage(url_foto_chassi, 'foto_chassi', false))} />
                                                <Options>
                                                    <IoTrashOutline color="#F00" onClick={!default_foto_chassi ? () => handleDeletePreviewImage('foto_chassi', false) : (() => { handleDeleteImage(url_foto_chassi, 'foto_chassi', false) })} />
                                                </Options>
                                            </BodyImage>

                                            : (
                                                <UploadImage amount={1} msg={`(Foto do chassi) `} setFile={setFoto_chassi} />
                                            )}
                                    </ImageGroup>
                                    <ImageGroup>
                                        <TitlePhoto>Foto Total</TitlePhoto>
                                        {url_foto_total ?
                                            <BodyImage>
                                                <img src={url_foto_total} data-tooltip="Foto Total da bicicleta" onClick={default_foto_total ? () => handleViewImage(url_foto_total, 'foto_total', true) : (() => handleViewImage(url_foto_total, 'foto_total', false))} />
                                                <Options>
                                                    <IoTrashOutline color="#F00" onClick={!default_foto_total ? () => handleDeletePreviewImage('foto_total', false) : (() => { handleDeleteImage(url_foto_total, 'foto_total', false) })} />
                                                </Options>
                                            </BodyImage>
                                            : (

                                                <UploadImage amount={1} msg={`(Foto Total) `} setFile={setFoto_total} />
                                            )}
                                    </ImageGroup>
                                    <ImageGroup>
                                        <TitlePhoto> Foto de peril</TitlePhoto>
                                        {url_foto_perfil ?
                                            <BodyImage>
                                                <img src={url_foto_perfil} data-tooltip="Foto de perfil da bicicleta" onClick={default_foto_perfil ? () => handleViewImage(url_foto_perfil, 'foto_perfil', true) : (() => handleViewImage(url_foto_perfil, 'foto_perfil', false))} />
                                                <Options>
                                                    <IoTrashOutline color="#F00" onClick={!default_foto_perfil ? () => handleDeletePreviewImage('foto_perfil', false) : (() => { handleDeleteImage(url_foto_perfil, 'foto_perfil', false) })} />
                                                </Options>
                                            </BodyImage>
                                            : (
                                                <UploadImage amount={1} msg={`(Foto de perfil) `} setFile={setFoto_perfil} />
                                            )}
                                    </ImageGroup>

                                    <ImageGroup>
                                        <TitlePhoto>Foto dos Detalhes</TitlePhoto>
                                        {url_foto_detalhe ?
                                            <BodyImage>
                                                <img src={url_foto_detalhe} data-tooltip="Foto dos Detalhes da bicicleta" onClick={default_foto_detalhe ? () => handleViewImage(url_foto_detalhe, 'foto_detalhe', true) : (() => handleViewImage(url_foto_detalhe, 'foto_detalhe', false))} />
                                                <Options>
                                                    <IoTrashOutline color="#F00" onClick={!default_foto_detalhe ? () => handleDeletePreviewImage('foto_detalhe', false) : (() => { handleDeleteImage(url_foto_detalhe, 'foto_detalhe', false) })} />
                                                </Options>
                                            </BodyImage>

                                            : (
                                                <UploadImage amount={1} msg={`(Foto do detalhes) `} setFile={setFoto_detalhe} />
                                            )}
                                    </ImageGroup>
                                </WapperImages>
                                <Buttons>
                                    <button>Salvar</button>
                                </Buttons>
                                <ModalImage
                                    showModal={show_modal_image}
                                    setShowModal={setShow_modal_image}
                                    image={imageSelected}
                                    handleDeleteImage={handleDeleteImage}
                                    handleDeletePreviewImage={handleDeletePreviewImage}
                                    isPreview={isPreview}
                                    nameFile={nameFileSelected}
                                />
                            </Form>
                            {/* <Delete>
                                <IoTrashOutline onClick={() => { handleDelete(id); setShowModal(!showModal) }} />
                            </Delete> */}
                            <CloseModalButton onClick={() => setShowModal(!showModal)} />

                        </ModalContent>
                    </animated.div>
                </Background>

            ) : null}
        </>
    );
}