import React, { useState, useEffect } from 'react';
import { Container, Faqs, Faq, FaqQuestion, FaqAnswer } from './styles'
import Navbar from '../Navbar'

export default function FaqComponent({ data, toggleFaq }){

    const [open,setOpen] = useState(false);

    return(
        <Container>
            {
                data.map((d,i) => (
                    <Faqs 
                        key={i}
                        onClick={() => {
                            toggleFaq(i)
                        }}
                    >
                        <Faq>
                            <FaqQuestion>
                                {d.pergunta}
                            </FaqQuestion>
                            <FaqAnswer>
                                {d.resposta}
                            </FaqAnswer>
                        </Faq>
                    </Faqs>
                ))
            }
        </Container>
    )
}