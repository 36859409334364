import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import { Background, ModalContent, CloseModalButton, Form, FormContent, FormGroup, Delete, WapperImages, TitlePhoto, Image, ImageGroup, BodyImage, Options } from './styles';
import api from '../../../services/api';
import swal from 'sweetalert';
import { IoTrashOutline } from "react-icons/io5";
import Auth from '../../../services/auth';

export default function ModalRegister({ showModal, setShowModal, handleDelete, id, setUpdeted }) {
    const modalRef = useRef();
    const [senhaAtual, setSenhaAtual] = useState('');
    const [senhaNova, setSenhaNova] = useState('');
    const [senhaNovaConfirm, setSenhaNovaConfirm] = useState('');
    const [auth, setAuth] = useState(Auth.token())
    const animation = useSpring({
        config: {
            duration: 150
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`
    });
    const keyPress = useCallback(e => {
        if (e.key === 'Escape' && showModal) setShowModal(!showModal);
    }, [setShowModal, showModal]);
    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);
    
    function logout() {
        try {
            var auth2 = auth;
            api.patch(`/logout?auth=${auth2}`).then(response => {
                if (response.error) console.log(response.error);
                else {
                    localStorage.removeItem('token')
                }
            }).catch(error => console.log(error))
        } catch (error) {
            console.log(error)
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();

        if (senhaNova !== senhaNovaConfirm) return swal("Atenção!", "As senhas não estão iguais!", "warning");

        const data = {
            senha_atual: senhaAtual,
            senha_nova: senhaNova
        }

        try {
            await api.patch(`/changepassword/${id}?auth=${auth}`, { data }).then(response => {
                if(response.data.error) return swal('Atenção!', response.data.error, 'warning')
                swal('Sucesso!', 'O logout será feito automaticamente, faça login novamente com sua nova senha', 'success')
                setTimeout(()=>{
                    logout();
                    window.location.reload();
                }, 3000);
            })
        } catch (error) { swal('Atenção!', `Serviço indisponível no momento!`, 'error') }
    }
    return (
        <>
            {showModal ? (
                <Background ref={modalRef} onClick={(e) => modalRef.current === e.target ? setShowModal(!showModal) : null}>
                    <animated.div style={animation}>
                        <ModalContent>
                            <Form onSubmit={handleSubmit}>
                                <h1>Mudar Senha</h1>
                                <FormContent>
                                    <FormGroup>
                                        <input
                                            type="password"
                                            placeholder="Senha Atual"
                                            value={senhaAtual}
                                            onChange={e => setSenhaAtual(e.target.value)}
                                            required
                                        />
                                        <label>Senha Atual</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="password"
                                            placeholder="Senha Nova"
                                            value={senhaNova}
                                            onChange={e => setSenhaNova(e.target.value)}
                                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                            title='Deve conter pelo menos 1 número, 1 letra em maiúsculo e 1 letra em minúsculo, e pelo menos 8 caracteres!'
                                            required
                                        />
                                        <label>Senha Nova</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="password"
                                            placeholder="Confirmar Senha Nova"
                                            value={senhaNovaConfirm}
                                            onChange={e => setSenhaNovaConfirm(e.target.value)}
                                            required
                                        />
                                        <label>Confirmar Senha Nova</label>
                                    </FormGroup>
                                </FormContent>
                                <button type="submit">Confirmar</button>
                            </Form>
                            <CloseModalButton onClick={() => setShowModal(!showModal)} />
                        </ModalContent>
                    </animated.div>
                </Background>
            ) : null}
        </>
    );
}