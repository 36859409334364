import React from 'react';
import { TableContainer, TableHead, TableBody } from './styles';
import { IoCheckmarkDoneOutline, IoCloseSharp, IoPencilOutline, IoTrashOutline } from 'react-icons/io5';

export default function Table({ data, handleClicked, handleDelete }) {
    return (
        <TableContainer>
            <TableHead>
                <tr>
                    <th>REMETENTE</th>
                    <th>EMAIL</th>
                    {/* <th>TELEFONE</th>
                    <th>COMENTARIO</th>
                    <th>RESPOSTA</th> */}
                    <th>DATA RECEBIDA</th>
                    <th>DATA RESPONDIDA</th>
                    <th>RESPONDIDA POR</th>
                    <th>RESPONDIDA</th>
                    <th>EDITAR</th>
                    <th>EXCLUIR</th>
                </tr>
            </TableHead>
            <TableBody>
                {data.map((d, i) => (
                    <tr key={i}>
                        <td data-title='Remetente'>{d.remetente}</td>
                        <td data-title='Email'>{d.email}</td>
                        {/* <td>{d.telefone}</td> */}
                        {/* <td>{d.comentario.length >= 50 ? `${d.comentario.substring(0, 25)}...` : d.comentario}</td> */}
                        {/* {d.resposta != null ? <td>{`${d.resposta.substring(0, 25)}...`}</td> : (
                            <td>-</td>
                        )} */}
                        <td data-title='Data criada'>{d.data_criada}</td>
                        {d.data_respondida != null ? <td data-title='Data respondida'>{d.data_respondida}</td>
                            : (
                                <td data-title='Data respondida'>-</td>
                            )}
                        
                        { d.respondida_por != null
                            ?
                                <td data-title='Respondida por'>{d.respondida_por}</td>
                            :
                                <td data-title='Respondida por'>-</td>
                        }
                        
                        {d.resposta != null ? <td data-title='Respondida'><IoCheckmarkDoneOutline color='green'/></td> : (
                            <td data-title='Respondida'><IoCloseSharp /></td>
                        )}
                        <td data-title='Editar'><IoPencilOutline 
                            color='blue'
                            onClick={() => { handleClicked(d.id) 
                        }}/></td>
                        {/* {
                            d.resposta == null ?  <td><IoPencilOutline onClick={() => { handleClicked(d.id)} }/></td> : <td><IoPencilOutline disabled style={{opacity: 0.5, cursor: "not-allowed"}}/></td> 
                        } */}
                        <td data-title='Excluir'><IoTrashOutline
                            color='red'
                            onClick={ async ()=>{
                                await handleDelete(d.id)
                            }}
                        /></td>
                    </tr>
                ))}
            </TableBody>
        </TableContainer>
    );
}