import styled from 'styled-components';

export const Head = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    h1{
        font-size: 2.5rem;
        color: var(--dark-color);
        margin-bottom: 0.5rem;
        border-bottom: 0.25rem solid var(--dark-color);
        border-radius: var(--border-radius);
    }
    p{
        color: #737380;
    }
`;
export const Wrapper = styled.ul`
    width: 100%;
    list-style: none;
    margin: 2rem 0;
    @media screen and (max-width: 768px){
        padding: 15px;
    }
`;
export const Card = styled.li`
    display: grid;
    grid-template-columns: 0.5fr 2fr;
    align-items: center;
    width: 100%;
    background: #fff;
    border-radius: var(--border-radius);
    overflow: hidden;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    position: relative;
    will-change: transform;
    transition: all .3s linear;
    margin: 0.5rem 0;
    &:hover{
        filter: brightness(99%);
        box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
        transform: translateY(-1px);
    }
    @media screen and (max-width: 768px){
        grid-template-columns: 0.8fr 2fr;
    }
`;
export const CardHeader = styled.div`
    height: 100%;
    background: var(--blue-color);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    h1{
        color: var(--white-color);
    }
    &::before{
        content: '';
        width: 2rem;
        height: 100%;
        background: #fff;
        position: absolute;
        top: 0;
        left: 90%;
        transform: skewX(-5deg);
    }
    @media screen and (max-width: 768px){
        h1{
            font-size: 1.3rem;
        }
    }
`;
export const CardBody = styled.div`
    padding: 0.5rem;
    z-index: 10;
    button{
        padding: .1rem 0.5rem;
        background: transparent;
        border: transparent;
        box-shadow: none;
        color: #737380;
        float: right;
    }
`;
export const CardGroup = styled.div`
    width: 100%;
    display: grid;
    overflow: hidden;
    strong{
        text-transform: uppercase;
        color: #41414d;
        margin-bottom: 0.3rem;
    }
    span{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #737380;
    }
    @media screen and (max-width: 768px){
        strong{
            font-size: 0.9rem;
        }
        span{
            font-size: 0.8rem;
        }
    }
`;