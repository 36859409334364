import React, { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Container, Content, Form, FormContent, FormGroup } from './styles';
import api from '../../services/api';
import swal from 'sweetalert';
import InputMask from 'react-input-mask';

export default function Register(){
    const [tipo_sanguineo,setTipo_sanguineo] = useState('');
    const [doenca,setDoenca] = useState('');
    const [medicacao,setMedicacao] = useState('');
    const [reacoes_medicamentos,setReacoes_medicamentos] = useState('');
    const [convenio,setConvenio] = useState('');
    const [alergias,setAlergias] = useState('');
    const [nome_contato_emergencia,setNome_contato_emergencia] = useState('');
    const [parentesco_contato_emergencia,setParentesco_contato_emergencia] = useState('');
    const [telefone_contato_emergencia,setTelefone_contato_emergencia] = useState('');

    const history = useHistory();
    const { id } = useParams();

    async function handleSubmit(e){
        e.preventDefault();
        try{
            const data = {
                tipo_sanguineo: tipo_sanguineo,
                doenca: doenca,
                medicacao: medicacao,
                reacoes_medicamentos: reacoes_medicamentos,
                convenio: convenio,
                alergias: alergias,
                nome_contato_emergencia: nome_contato_emergencia,
                parentesco_contato_emergencia: parentesco_contato_emergencia,
                telefone_contato_emergencia: telefone_contato_emergencia
            }
            const response = await api.patch(`registercomplement/${atob(id)}`, { data });
            swal('Sucesso!', response.data.success, 'success');
            history.push('/login');
        } catch(error){
            swal('Atenção!', `Serviço indisponível no momento!`, 'error');
        }
    }
    return(
        <Container>
            <Content>
                <Form onSubmit={handleSubmit}>
                    <h1>Dados vitais</h1>
                    <FormContent>
                        <FormGroup>
                            <select
                                value={tipo_sanguineo}
                                onChange={e => setTipo_sanguineo(e.target.value)}
                            >
                                <option value="N/I">Não informado</option>
                                <option value="A+">A+</option>
                                <option value="A-">A-</option>
                                <option value="B+">B+</option>
                                <option value="B-">B-</option>
                                <option value="AB+">AB+</option>
                                <option value="AB-">AB-</option>
                                <option value="O+">O+</option>
                                <option value="O-">O-</option>
                            </select>
                            <label>Tipo Sanguíneo</label>
                        </FormGroup>
                        <FormGroup>
                            <input
                                type="text"
                                placeholder="Doença"
                                value={doenca}
                                onChange={e => setDoenca(e.target.value)}
                                maxLength="150"
                            />
                            <label>Doença</label>
                        </FormGroup>
                        <FormGroup>
                            <input
                                type="text"
                                placeholder="Medicação"
                                value={medicacao}
                                maxLength="150"
                                onChange={e => setMedicacao(e.target.value)}
                            />
                            <label>Medicação</label>
                        </FormGroup>

                         <FormGroup>
                         <input
                                type="text"
                                placeholder="Reações a medicamentos"
                                value={reacoes_medicamentos}
                                onChange={e => setReacoes_medicamentos(e.target.value)}
                                maxLength="150"
                            />
                            <label>Reações a medicamentos</label>
                        </FormGroup>
                        <FormGroup>
                         <input
                                type="text"
                                placeholder="Convênio"
                                value={convenio}
                                onChange={e => setConvenio(e.target.value)}
                                maxLength="150"
                            />
                            <label>Convênio</label>
                        </FormGroup>
                        <FormGroup>
                         <input
                                type="text"
                                placeholder="Alergias"
                                value={alergias}
                                onChange={e => setAlergias(e.target.value)}
                                maxLength="150"
                            />
                            <label>Alergias</label>
                        </FormGroup>
                        <FormGroup>
                            <input
                                type="text"
                                placeholder="Nome do contato de Emergencia"
                                value={nome_contato_emergencia}
                                onChange={e => setNome_contato_emergencia(e.target.value)}
                                minLength="3"
                                maxLength="100"
                            />
                            <label>Nome do contato de emêrgencia</label>
                        </FormGroup>
                        <FormGroup>
                            <input
                                type="text"
                                placeholder="Grau de parentesco"
                                value={parentesco_contato_emergencia}
                                onChange={e => setParentesco_contato_emergencia(e.target.value)}
                                minLength="2"
                                maxLength="30"
                            />
                            <label>Grau de parentesco</label>
                        </FormGroup>
                        <FormGroup>
                            <InputMask
                                placeholder="Contato de emergência"
                                type="text"
                                mask="(99) 99999-9999"
                                value={telefone_contato_emergencia}
                                onChange={e => setTelefone_contato_emergencia(e.target.value)}
                            />
                            <label>Contato de emergência</label>
                        </FormGroup>
                    </FormContent>
                    <button style={{ marginTop: '0.5rem' }}>Criar</button>
                </Form>
                <Link to="/login">Pular</Link>
            </Content>
        </Container>
    );
}