import React from 'react';
import { Header, Overlay, Title, SubTitle, Text } from './styles';
import { useHistory } from 'react-router-dom';

export default function Heading(){

    const history = useHistory();

    function knowMore(){
        history.push({
            pathname: `/knowmore`,
        })
    }

    return(
        <Header>
            <Overlay>
                <Title>ACI Bike</Title>
                <SubTitle>Associação dos Ciclistas de Indaiatuba</SubTitle>
                <Text>Pedalar te ensina que dependo das suas pernas para ir a qualquer lugar, dependo da sua força para chegar aos seus objetivos. <br/> Elas são os motores da sua bicicleta e sua iniciativa, o motor dos seus sonhos!</Text>
                <br />
                <button
                    onClick={() => {
                        knowMore()
                    }}
                >Saber Mais</button>
            </Overlay>
        </Header>
    );
}