import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import { Container, Background, ModalContent, CloseModalButton, Form, FormContent, FormGroup, Delete, ImageGroup, WapperImages, TitlePhoto, Options, BodyImage, Buttons } from './styles';
import UploadImage from './UploadImage';
import api from '../../services/api';
// import ModalImage from '../../components/ModalImage';
import { IoTrashOutline } from "react-icons/io5";
import swal from 'sweetalert';
import jwt from 'jsonwebtoken';
import jwt_decode from "jwt-decode";
import Auth from '../../services/auth';
import InputMask from 'react-input-mask';

export default function ModalBicycles({ showModal, setShowModal }) {
    const modalRef = useRef();
    const [id, setId] = useState(Auth.id());
    const [data_recebimento, setData_recebimento] = useState('');
    const [num_bo, setNum_bo] = useState('');
    const [cor, setCor] = useState('');
    const [endereco, setEndereco] = useState('');
    const [tipo, setTipo] = useState('FURTO');
    const [descricao, setDescricao] = useState('');
    const [chassi, setChassi] = useState('');
    const [guarda, setGuarda] = useState('');
    const [observacoes, setObservacoes] = useState('');
    const [status, setStatus] = useState('');
    const [resultado, setResultado] = useState('');
    const [data_atendimento, setData_atendimento] = useState('');
    const [data_encerramento, setData_encerramento] = useState('');
    const [foto, setFoto] = useState([]);
    const [url_foto, setUrl_foto] = useState(null);
    const [default_foto, setDafault_foto] = useState(null);
    const [isPreview, setIsPreview] = useState(false);
    const [haveImage, setHaveImage] = useState(false);
    const [imageSelected, SetImageSelected] = useState('');
    const [show_modal_image, setShow_modal_image] = useState(false);
    const [nameFileSelected, setNameFilesSelectd] = useState('');
    const [token, setToken] = useState(Auth.token())

    useEffect(() => {
        if (foto.length) {
            setHaveImage(true);
        } else {
            setHaveImage(false);
        };
        if (foto.length) foto.map(file => setUrl_foto(URL.createObjectURL(file)));
    }, [foto]);

    function handleDeletePreviewImage() {
        swal({
            title: "Você tem certeza?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    setUrl_foto(null);
                    setFoto([]);
                }
            });

    }
    async function handleSubmit(e) {
        e.preventDefault();
        // let date;
        // if (data_aquisicao) {
        //     const aux = data_aquisicao.split("T");
        //     date = aux[0];
        // }

        if (haveImage === true) {
            let formData = new FormData();
            formData.append('id_usuario', id);
            formData.append('numero_bo', num_bo);
            formData.append('endereco', endereco);
            formData.append('tipo', tipo);
            formData.append('descricao', descricao);
            formData.append('chassi', chassi);
            formData.append('status', 'PENDENTE');
            if (foto.length) foto.forEach(file => formData.append(`${chassi}`, file));
      
            try {
                const response = await api.post(`/occurrences?auth=${token}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Accept: 'multipart/form-data'
                    }
                });
                if (response.data.error) return swal(response.data.error);
                if (response.data.success) {
                    swal({
                        title: "Cadastrado com sucesso",
                        icon: "success",
                    })
                }
                setTimeout(() => {
                    window.location.reload()
                }, 700)
            } catch (error) {
                console.log(error);
                swal('Atenção!', `Serviço indisponível no momento!`, 'error');
            }
        } else {
            const data = {
                id_usuario: id,
                tipo: tipo,
                numero_bo: num_bo,
                endereco: endereco,
                descricao: descricao,
                chassi: chassi,
                status: 'PENDENTE'
                // data_aquisicao: date,
                // Decidir se vai ser o debaixo ou o comentado acima
            }
            try {
                const response = await api.post(`/occurrences?auth=${token}`, { data });
                if (response.data.error) return alert(response.data.error);
                swal({
                    title: "Cadastrado com sucesso",
                    icon: "success",
                })
                setTimeout(() => {
                    window.location.reload()
                }, 700)
            } catch (error) {
                alert(`Segundo erro: ` + error);
            }
        }

    }

    return (
        <Container>
            <ModalContent>
                <Form onSubmit={handleSubmit}>
                    <h1>Adicionar Ocorrência</h1>
                    <FormContent>
                        <FormGroup>
                            <select
                                value={tipo}
                                onChange={e => setTipo(e.target.value)}
                            >
                                <option value="FURTO">Furto</option>
                                <option value="ROUBO">Roubo</option>
                            </select>
                            <label>Tipo</label>
                        </FormGroup>
                        <FormGroup>
                            <input
                                type="text"
                                placeholder="Endereço"
                                value={endereco}
                                onChange={e => setEndereco(e.target.value)}
                                required
                            />
                            <label>Endereço</label>
                        </FormGroup>
                        <FormGroup>
                            <InputMask
                                type="text"
                                mask='99999'
                                placeholder="Número do B.O"
                                value={num_bo}
                                onChange={e => setNum_bo(e.target.value)}
                                required
                            />
                            <label>Número do B.O</label>
                        </FormGroup>
                        <FormGroup>
                            <input
                                type="text"
                                placeholder="Chassi"
                                value={chassi}
                                onChange={e => setChassi(e.target.value)}
                                required
                            />
                            <label>Chassi</label>
                        </FormGroup>
                        <FormGroup>
                            {/* Mudar para textarea */}
                            <input
                                type="text"
                                placeholder="Descrição"
                                value={descricao}
                                onChange={e => setDescricao(e.target.value)}
                                required
                            />
                            <label>Descrição</label>
                        </FormGroup>
                    </FormContent>
                    <WapperImages>
                        <ImageGroup>
                            <TitlePhoto>Foto dos Detalhes</TitlePhoto>
                            {url_foto ?
                                <BodyImage>
                                    <img src={url_foto} data-tooltip="Foto dos Detalhes da bicicleta" />
                                    <Options>
                                        <IoTrashOutline color="#F00" onClick={() => handleDeletePreviewImage()} />
                                    </Options>
                                </BodyImage>

                                : (
                                    <UploadImage amount={1} msg={`(Foto dos detalhes) `} setFile={setFoto} />
                                )}
                        </ImageGroup>
                    </WapperImages>
                    <Buttons>
                        <button>Salvar</button>
                    </Buttons>
                    {/* <ModalImage
                        showModal={show_modal_image}
                        setShowModal={setShow_modal_image}
                        image={imageSelected}
                        handleDeleteImage={handleDeleteImage}
                        handleDeletePreviewImage={handleDeletePreviewImage}
                        isPreview={isPreview}
                        nameFile={nameFileSelected}
                    /> */}
                </Form>
                {/* <Delete>
                    <IoTrashOutline onClick={() => { handleDelete(id); setShowModal(!showModal) }} />
                </Delete> */}
                {/* <CloseModalButton onClick={() => setShowModal(!showModal)} /> */}

            </ModalContent>
        </Container>
    );
}