import React, { useState, useEffect } from 'react';
import Heading from '../../components/Heading';
import Card from '../../components/Card/Routes';
import Pagination from '../../components/Pagination';
import api from '../../services/api'
import Filters from '../../components/Filters/Routes';
import { Container, Head } from './styles';
import swal from 'sweetalert'
import { Link, useHistory } from 'react-router-dom';
import Auth from '../../services/auth'
import { Logout } from '../../services/logout';

export default function Routes() {

    const history = useHistory()
    const [routes, setRoutes] = useState([]);
    const [routeSelected, setRouteSelected] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [showModal, setShowModal] = useState({ edit: false, image: false });
    const [alreadyLoaded, setAlreadyLoaded] = useState(false);
    const [filters, setFilters] = useState({ show: 'TODOS', search: '' });
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [reload, setReload] = useState(false);
    const [token, setToken] = useState(Auth.token())

    function handleClicked(id) {
        setShowModal({ ...showModal, edit: !showModal.edit });
        setRouteSelected(id);
        history.push({
            pathname: `/routes/${id}`,
            state: id
        })
    }

    // useEffect(() => {
    //     !alreadyLoaded && setLoading(true);
    //     api.get(`routes/?_tipo=${filters.show}&_search=${filters.search}&_page=${page}`).then(response => {
    //         setRoutes(response.data.users);
    //         setTotalPages(response.data.totalPages);
    //         !alreadyLoaded && setLoading(false); !alreadyLoaded && setAlreadyLoaded(true);
    //     }).catch(error => {alert(error); setLoading(false); setError(true);});
    // }, [filters, page, reload, alreadyLoaded]);

    useEffect(() => {
        api.get(`/routes?auth=${token}`).then(response => {
            setRoutes(response.data.routes)
        }).catch(error => {
            // swal('Atenção!', error, 'error')
            if (error.response.status === 401) {
                Logout();
                window.location.reload()
            }
        })
    }, [])

    return (
        <>
            <Heading />
            <Head>
                <h1>Rotas Disponíveis</h1>
            </Head>
            <Container>
                <Card
                    loading={loading}
                    error={error}
                    repeatLoading={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
                    data={routes}
                    list={['nome', 'percurso_total'/*, 'horarios'*/]}
                    handleClicked={handleClicked}
                />
                <Pagination
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                />
                {/* <Filters
                    filters={filters}
                    setFilters={setFilters}
                    data={['TODOS', 'ADMINISTRADOR', 'CICLISTA', 'GUARDA' ]}
                /> */}
            </Container>
        </>
    )
}