import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import { Background, ModalContent, CloseModalButton, Options, Image, WapperImages, TitlePhoto, BodyImage, ImageGroup } from './styles';
import { IoTrashOutline } from "react-icons/io5";
import UploadImage from '../UploadImage/Users';
import swal from 'sweetalert';
import Auth from '../../services/auth'
import api from '../../services/api'

export default function ModalImage({ showModal, setShowModal, image, handleDeleteImage, nameFile, isPreview, id }) {

    const [foto, setFoto] = useState([]);
    const [url_foto, setUrl_foto] = useState(null);
    const [haveImage, setHaveImage] = useState(false);
    const [foto_host, setFoto_host] = useState(true);
    const [token, setToken] = useState(Auth.token());

    const modalRef = useRef();
    const animation = useSpring({
        config: {
            duration: 150
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`
    });

    const keyPress = useCallback(e => {
        if (e.key === 'Escape' && showModal) setShowModal(!showModal);
    }, [setShowModal, showModal]);

    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);

    useEffect(() => {
        if (foto.length) {
            setHaveImage(true);
        } else {
            setHaveImage(false);
        }
        foto.map(file => setUrl_foto(URL.createObjectURL(file)));
    }, [foto])

    function handleDeleteImage(element) {
        swal({
            text: "Tem certeza?",
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setUrl_foto(null)
            }
        })
    }

    async function handleSubmit() {
        try {
            if (!foto_host) {
                let formData = new FormData();
                if (foto) {
                    foto.forEach(file => formData.append(`${id}`, file));
                }
                const response = await api.patch(`admin/updatephoto/${id}?auth=${token}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Accept: 'multipart/form-data'
                    }
                });
                if (response.data.error) return swal("Atenção", response.data.error, "warning");
                swal("Atenção", response.data.success, "success");
                setTimeout(() => {
                    window.location.reload(false);
                }, 700);
            } else {
                swal("Atenção", "Imagem não alterada!", "warning");
            }
        } catch (error) { swal("Atenção", 'Serviço indisponível no momento', "error") }
    }

    return (
        <>
            {showModal ? (
                <Background ref={modalRef} ref={modalRef} onClick={(e) => { if (modalRef.current === e.target) { setShowModal(!showModal); setUrl_foto(null); setFoto_host(true); } }}>
                    <animated.div style={animation}>
                        <WapperImages>
                            <TitlePhoto>Foto do usuário</TitlePhoto>
                            <ImageGroup>
                                {foto_host ?
                                    <BodyImage>
                                        <img onError={e => { setFoto_host(false); }} src={`https://acibike.fiecdev.com.br:25555/usersphoto/${id}.jpeg?auth=${token}`} data-tooltip="Foto do local" />
                                        <IoTrashOutline color="#F00" size={26} style={{ cursor: 'pointer' }} onClick={() => { swal({ title: "Você tem certeza?", icon: "warning", buttons: true, dangerMode: true, }).then((willDelete) => { if (willDelete) { setFoto_host(false); } }); }} />
                                    </BodyImage>
                                    :
                                    url_foto
                                        ?
                                        <BodyImage>
                                            <img src={url_foto} data-tooltip="Foto do local" />
                                            <IoTrashOutline color="#F00" size={26} style={{ cursor: 'pointer' }} onClick={() => { handleDeleteImage('Foto do local') }} />
                                        </BodyImage>
                                        : (
                                            <UploadImage amount={1} msg={`(Foto do usuário) `} setFile={setFoto} />
                                        )}
                            </ImageGroup>
                            <button onClick={() => { handleSubmit() }}>Salvar</button>
                        </WapperImages>
                        {/* <Options>
                                <IoTrashOutline color="#F00" style={{ top: '-20px', fontSize: '1.5rem', color: 'red', cursor: 'pointer' }} onClick={isPreview == true ? () => handleDeleteImage(image, nameFile, true, true) : (() => { handleDeleteImage(image, nameFile, true) })} />
                            </Options> */}
                        <CloseModalButton onClick={() => { setShowModal(!showModal); setFoto_host(true); setUrl_foto(null); }} />
                    </animated.div>
                </Background>
            ) : null}
        </>
    );
}