import styled, { keyframes } from 'styled-components';

export const Container = styled.ul`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    list-style: none;
    @media screen and (max-width: 768px){
        grid-template-columns: repeat(2, 1fr);
        width: 95%;
    }
    @media screen and (max-width: 425px){
        grid-template-columns: 1fr;
    }
`;
export const Card = styled.li`
    width: 100%;
    padding: 1rem;
    background: #fff;
    border-radius: var(--border-radius);
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    position: relative;
    will-change: transform;
    transition: all .3s linear;
    &:hover{
        filter: brightness(99%);
        box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
        transform: translateY(-1px);
    }
`;
const shimmer = keyframes`
    0%{
        background-position: -450px 0;
    }
    100%{
        background-position: 450px 0;
    }
`;

export const CardHeader = styled.div`
    text-align: center;
    padding: 0 0 0.5rem 0;
    font-weight: bold;
    h1{
        font-size: 1.2rem;
    }
    span{
        color: #F00;
    }
`;

export const CardBody = styled.div`
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-gap: 0.3rem;
    align-items: center;
    img{
        width: 100%;
        height: auto;
    }
`;

export const CardFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    small{
        color: #737380;
    }
    button{
        padding: .1rem 0.5rem;
        background: transparent;
        border: transparent;
        box-shadow: none;
        color: #737380;
    }
`;

export const CardGroup = styled.div`
    display: grid;
    padding: 0.5rem;
    overflow: hidden;
    strong{
        text-transform: uppercase;
        color: #41414d;
    }
    span{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #737380;
    }
    div{
        height: 1.2rem;
        border-radius: var(--border-radius);
        background: #d9d9d9;
        margin: 0.05rem 0;
        position: relative;
        overflow: hidden;
        &:first-child{
            width: 30%;
        }
        &:last-child{
            width: 100%;
        }
        &::before{
            position: absolute;
            content: "";
            height: 100%;
            width: 100%;
            background-image: linear-gradient(to right, #d9d9d9 0%, rgba(0,0,0,0.05) 20%, #d9d9d9 40%, #d9d9d9 100%);
            background-repeat: no-repeat;
            background-size: 450px 400px;
            animation: ${shimmer} 1s linear infinite;
        }
    }
`;
export const Options = styled.div`
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
    width: 5rem;
    display: flex;
    justify-content: space-between;
    svg{
        font-size: 1.2rem;
        cursor: pointer;
        will-change: transform;
        transition: all .3s linear;
        &:hover{
            filter: brightness(90%);
            transform: translateY(-1px);
        }
    }
    div{
        width: 1rem;
        height: 1rem;
        border-radius: var(--border-radius);
        background: #d9d9d9;
        position: relative;
        overflow: hidden;
        &::before{
            position: absolute;
            content: "";
            height: 100%;
            width: 100%;
            background-image: linear-gradient(to right, #d9d9d9 0%, rgba(0,0,0,0.05) 20%, #d9d9d9 40%, #d9d9d9 100%);
            background-repeat: no-repeat;
            background-size: 450px 400px;
            animation: ${shimmer} 1s linear infinite;
        }
    }
`;