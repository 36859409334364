import React, { useState, useEffect } from 'react'
import { Container, Title } from './styles'
import Navbar from '../../components/Navbar'
import api from '../../services/api'
import swal from 'sweetalert'
import Table from '../../components/FaqComponent'
import Logo from '../../assets/images/logo.ico'

export default function Faq(){

    const [questions,setQuestions] = useState([]);

    const toggleFaq = index => {
        setQuestions(questions.map((q, i) => {
            if(i === index) {
                q.open = !q.open
            }
            else {
                q.open = false
            }

            return q;
        }))
    }

    useEffect(() => {
        api.get('/faq').then(response => {
            // response.data.faq.forEach((element, index) => {
            //     response.data.faq[index].open = false
            // });
            setQuestions(response.data.faq)
        }).catch(error => swal('Atenção!', error, 'error'))
    }, [])

    return(
        <Container>

        {questions == ''
            ?
            <h1>Sem faqs disponíveis</h1>
            :
            <>
                <img src={Logo} style={{width: 150, height: 150, marginTop: 15}}/>
                <Title>FAQ</Title>
                <Table 
                    data={questions}
                    toggleFaq={toggleFaq}
                />
            </>
        }
        </Container>
    )
}