import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Conferir se vai ser essa estilização do container mesmo

export const Container = styled.div`
    width: 100%;
    background-color: #525659;
    min-height: 87.9vh;
    padding: 4rem calc((100vw - 1000px) / 2) 0 calc((100vw - 1000px) / 2);
    display: flex;
    flex-direction: column;
    align-items: center;
    embed{
        width: 100vw;
        height: 76vh;
    }
    object{
        width: 100vw;
        height: 76vh;
    }
    @media screen and (max-width: 768px){
        width: 100vw;    
    }
`;

export const ButtonAdd = styled(Link)`
    cursor: pointer;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    font-weight: 600;
    padding: .625rem 1.25rem;
    line-height: 1.5;
    border-radius: var(--border-radius);
    text-transform: uppercase;
    will-change: transform;
    letter-spacing: .025em;
    font-size: .875rem;
    color: #fff;
    border: none;
    background: #b8b8b8;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    transition: all .3s linear;
    &:hover{
        filter: brightness(90%);
        box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
        transform: translateY(-1px);
    }

`;

export const Title = styled.h1`
    text-transform: uppercase;
    font-size: 2.5rem;
    color: white;
    margin: 1.5rem;
    border-bottom: 0.25rem solid white;
    border-radius: var(--border-radius);
`;