import styled from 'styled-components';

// Conferir se vai ser essa estilização do container mesmo

export const Container = styled.div`
    width: 100%;
    min-height: 87.9vh;
    padding: 4rem calc((100vw - 1000px) / 2) 0 calc((100vw - 1000px) / 2);
    display: flex;
    flex-direction: column;
    align-items: center;
    object{
        width: 100vw;
        height: 100vh;
    }
    @media screen and (max-width: 768px){
        width: 100vw;    
    }
`;