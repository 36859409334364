import styled from 'styled-components';
import bg from '../../assets/images/register.jpg';

export const Container = styled.div`
    width: 100vw;
    min-height: 100vh;
    background: url(${bg});
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(7, 12, 31, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const Content = styled.div`
    max-width: 90%;
    padding: 2rem;
    background: #fff;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    a{
        margin-top: 1rem;
    }
    @media screen and (max-width: 768px){
        width: 100vw;
        padding: 0;
    }
`;
export const Form = styled.form`
    width: 100%;
    text-align: center;
    h1{
        margin-bottom: 2rem;
        text-transform: uppercase;
    }
`;
export const FormContent = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
export const FormGroup = styled.div`
    flex: 1 1 20rem;
    position: relative;
    margin: 0 0.5rem 1.5rem 0.5rem;
    svg{
        position: absolute;
        top: 25%;
        right: 1%;
        cursor: pointer;
    }
`;