import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';

export const Nav = styled.div`
    width: 100%;
    height: 4rem;
    background: var(--dark-color);
    padding: 0.5rem calc((100vw - 1000px) / 2);
    display: ${props => props.hidden ? 'none' : 'flex'};
    justify-content: space-around;
    font-weight: 500;
    position: fixed;
    z-index: 9999;
`;
export const NavName = styled(Link)`
    color: var(--white-color);
    position: absolute;
    top: 1.5rem;
    left: 1rem;
    @media screen and (min-width: 768px){
        display: none;
    }
`;
export const Bars = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-80%, 80%);
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    cursor: pointer;
    display: none;
    z-index: 20;
    div{
        width: 1.5rem;
        height: 0.15rem;
        background: var(--white-color);
        border-radius: var(--border-radius);
        transform-origin: 1px;
        transition: all .3s linear;
        &:nth-child(1){
            transform: ${props => props.open ? 'rotate(45deg)' : 'rotate(0)'};
        }
        &:nth-child(2){
            transform: ${props => props.open ? 'translateX(100%)' : 'translateX(0)'};
            opacity: ${props => props.open ? 0 : 1};
        }
        &:nth-child(3){
            transform: ${props => props.open ? 'rotate(-45deg)' : 'rotate(0)'};
        }
    }
    @media screen and (max-width: 768px){
        display: inherit;
    }
`;
export const NavResponsive = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 768px){
        flex-flow: column nowrap;
        align-items: flex-start;
        background: var(--dark-color);
        position: fixed;
        transform: ${props => props.open ? 'translateX(0)' : 'translateX(-100%)'};   
        transition: transform .3s linear;
    }
`;
export const NavProfile = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white-color);
    div{
        display: flex;
        justify-content: center;
        width: 32px;
        height: 32px;
        margin: 0 .5rem 0 .6rem;
        background: var(--blue-color);
        border-radius: 50%;
        overflow: hidden;
        img{
            width: 100%;
            height: auto;
        }
    }
    a{
        color: var(--white-color);
    }
    span{
        display: none;
    }
    @media screen and (max-width: 768px){
        flex-flow: column nowrap;
        align-items: flex-start;
        margin: 1rem 0 0 1.5rem;
        div{
            margin: 0 0 1rem 0;
            width: 52px;
            height: 52px;
        }
        span{
            display: inherit;
            font-size: var(--small-font-size);
            margin-top: 0.5rem;
            font-weight: 300;
        }
    }
`;
export const NavMenu = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 768px){
        flex-flow: column nowrap;
        align-items: flex-start;
        margin: 1rem 0 0 0.5rem;
    }
`;
export const NavLink = styled(Link)`
    margin: 0 .25rem;
    padding: .6rem .8rem;
    border-radius: var(--border-radius);
    color: var(--white-color);
    transition: .3s linear;
    &:hover{
        background: var(--blue-color);
    }
    &.active{
        background: var(--blue-color);
    }
    @media screen and (max-width: 768px){
        margin: 0 0 1.5rem .25rem;
        &:hover{
            background: transparent;
            color: var(--blue-color);
        }
        &.active{
            background: transparent;
            color: var(--blue-color);
        }
    }
`;
export const NavOptions = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 768px){
        margin: 0 0 2.5rem 1rem;
    }
`;
export const ItemOption = styled.a`
    font-size: 1.2rem;
    color: var(--white-color);
    margin: 0 .6rem;
    transition: .3s linear;
    line-height: 0;
    &:hover{
        color: var(--blue-color);
    }
`;
export const ItemOptionLink = styled(Link)`
    font-size: 1.2rem;
    color: var(--white-color);
    margin: 0 .6rem;
    transition: .3s linear;
    line-height: 0;
    &:hover{
        color: var(--blue-color);
    }
`;