import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import api from '../../services/api';
import Auth from '../../services/auth';
import { Container } from './styles'
import Map from '../../components/Map/RoutesView';

export default function EditRoute() {
    const history = useHistory();
    const [markers, setMarkers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [directions, setDirections] = useState(null);
    const [auth, setAuth] = useState(Auth.token());

    useEffect(() => {
        const id = history.location.state
        api.get(`/routes/${id}?auth=${auth}`).then(response => {
            console.log(response.data.route.routes[0]);
            setMarkers(response.data.route.waypoints)
            setDirections(JSON.parse(response.data.route.routes[0].directions));
        });
    }, []);

    const handleFindWaypoints = () => {
        const id = history.location.state;
        setMarkers([]);
        setDirections(null);
        api.get(`/routes/${id}?auth=${auth}`).then(response => {
            setDirections(JSON.parse(response.data.route.routes[0].directions));
        });
    }
    useEffect(() => {
        if (markers.length > 2) setLoading(false);
    }, [markers]);


    return (
        <Container>
            {loading === false ?
                <Map
                    markers={markers}
                    setMarkers={setMarkers}
                    directions={directions}
                    handleFindWaypoints={handleFindWaypoints}
                />
                : <h1>LOADING</h1>
            }

        </Container>
    );
}