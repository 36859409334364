import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    padding: 0 calc((100vw - 1000px) / 2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 768px){
        width: 100vw;    
    }
`;
export const Title = styled.h1`
    text-align: center;
    font-size: 2.5rem;
    color: var(--dark-color);
    margin: 1.5rem;
    border-bottom: 0.25rem solid var(--dark-color);
    border-radius: var(--border-radius);
`;
export const Table = styled.table`
    width: 100% !important;
    border-collapse: collapse;
    border-radius: var(--border-radius);
    overflow: hidden;
    background: #fff;
    box-shadow: 0px 3px 12px -3px rgb(0 0 0 / 50%);
`;
export const TableHead = styled.thead`
    background: var(--dark-color);
    color: var(--white-color);
    text-transform: uppercase;
    th{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 1rem 0;
        svg{
            vertical-align: middle;
            font-size: 1.1rem;
        }
    }
    @media screen and (max-width: 768px){
        display: none;   
    }
`;
export const TableBody = styled.tbody`
    text-align: center;
    tr{
        border-bottom: 1px solid #f4f4f4;
        cursor: pointer;
        will-change: transform;
        transition: all .3s linear;
        td{
            padding: 1rem 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &:hover{
            filter: brightness(90%);
            box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
            transform: translateY(-1px);
        }
    }
`;