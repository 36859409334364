import React, { useState, useEffect } from 'react';
import { Container, Title, Options, SerchArea, Pagination, ButtonAdd } from './styles';
import Table from '../../components/Tables/Bikes';
import ModalEdit from '../../components/Modal/Bicycles';
import ModalRegister from '../../components/Modal/Bicycles/Register/index'
import api from '../../services/api';
import { IoSearch, IoAddOutline } from "react-icons/io5";
import swal from 'sweetalert'
import Auth from "../../services/auth";
import { Logout } from '../../services/logout';

/*
    - Tela de visualização das bike ( ADMINISTRADOR )

    - Recebe os dados do banco e passa os dados para o componet "Table" (linha: 94) e para o componet "ModalEdit" (linha: 115)
        path: "../../components/Table"
*/
export default function AdminBicycles() {

    //Array de objetos com as bikes recebidas do banco
    const [bicycles, setBicycles] = useState([]);

    //Um boolean para definir o "visible" do "ModalEdit" 
    const [showModal, setShowModal] = useState(false);

    //Guarda o id da bike selecionada na tabela
    const [bicycleSelected, setBicycleSelected] = useState(0);

    //Guarda o status de filtro das bikes
    const [showBicycles, setShowBicycles] = useState("TODOS");

    //guarda o valor do texto de filtro
    const [search, setSearch] = useState('');

    //pagina Atual da paginação
    const [page, setPage] = useState(1);

    //Total de paginas 
    const [totalPages, setTotalPages] = useState(0);

    const [showModalRegister, setShowModalRegister] = useState(false);

    const [registerBike, setRegisterBike] = useState('')

    const [token, setToken] = useState(Auth.token());

    function handleClicked(chassi) {
        setShowModal(!showModal);
        setBicycleSelected(chassi);
    }

    //Recebe o id da bike e deletda a bike referente ao ID 
    function handleDelete(id, chassi) {
        try {
            swal({
                title: "Você tem certeza?",
                text: "Uma vez deletado a bike terá que ser cadastrada novamente",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {
                        await api.delete(`bikes/${chassi}?auth=${token}`);
                        setBicycles(bicycles.filter(bicycle => bicycle.id !== id));
                        swal("Bike deletada com sucesso!", {
                            icon: "success",
                        })
                    } else {
                        swal("Bike não deletada!")
                    }
                })
        } catch (error) {
            alert('Erro ao deletar a bicicleta!');
        }
    }

    //muda de pagina na paginação
    function handlePage(action) {
        setPage(action === 'back' ? page - 1 : page + 1);
    }

    //Busca no banco novos dados toda vez aque atualiza um campo de filtragem (status, nome, modelo ou ano)
    useEffect(() => {
        api.get(`admin/bikes?_status=${showBicycles}&_search=${search}&_page=${page}&auth=${token}`).then(response => {
            setBicycles(response.data.bikes);
            setTotalPages(response.data.totalPages);
        }).catch(error => {
            console.log(error);
            if (error.response.status === 401) {
                Logout();
            }
        });
    }, [showBicycles, search, page]);

    return (
        <Container>
            <Title>Bicicletas</Title>
            <Options>
                {/* Talvez, futuramente o usuário administrador possa cadastrar bikes diretamente da sua conta, mas no momento não é a prioridade! */}
                <ButtonAdd onClick={() => {
                    setShowModalRegister(!showModalRegister)
                }}>
                    <IoAddOutline id="iconAdd" size={23} />
                </ButtonAdd>
                <ModalRegister
                    showModal={showModalRegister}
                    setShowModal={setShowModalRegister}
                    id={registerBike}
                />
                {/* Campo que recebe o status da bike para filtrar os dados da tabela  */}
                <select
                    value={showBicycles}
                    onChange={e => setShowBicycles(e.target.value)}
                >
                    <option value="TODOS">TODOS</option>
                    <option value="REGULAR">REGULAR</option>
                    <option value="ROUBADA">ROUBADA</option>
                    <option value="PERDIDA">PERDIDA</option>
                </select>
                {/* Campo que recebe o texto para filtrar os dados da tabela (nome, modelo ou ano)*/}
                <SerchArea>
                    <IoSearch style={{ position: "absolute", left: 6 }} />
                    <input
                        type="search"
                        placeholder="Pesquise pelo chassi"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        minLength="3"
                        pattern='(?=.*\d)'
                        title='O campo precisa ter pelo menos 1 número'
                        onKeyDown={(e) => {
                            if (e.key === 0) {
                                e.preventDefault()
                            }
                        }}
                    />
                </SerchArea>
            </Options>

            {/* Manda os dados para o component "Table" que rederiza a tabela */}

            {bicycles == ''
                ?
                <h1>Não há bicicletas cadastradas</h1>
                :
                <>
                    <Table
                        data={bicycles}
                        list={['nome', 'modelo', 'chassi', 'status', 'ano']}
                        handleClicked={handleClicked}
                        handleDelete={handleDelete}
                    />

                    <Pagination>
                        {page <= 1 ? (
                            <button disabled>Anterior</button>
                        ) : (
                            <button onClick={() => handlePage('back')}>Anterior</button>
                        )}
                        <small color="secondary">Página {page} / {totalPages}</small>
                        {page < totalPages ? (
                            <button onClick={() => handlePage('next')}>Próximo</button>
                        ) : (
                            <button disabled>Próximo</button>
                        )}
                    </Pagination>
                </>
            }

            {/* Manda os dados para o component "ModalEdit" que rederiza modal de edição das bikes */}
            <ModalEdit
                showModal={showModal}
                setShowModal={setShowModal}
                handleDelete={handleDelete}
                id={bicycleSelected}
            />
        </Container>
    );
}