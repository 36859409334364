import React from 'react';
import { TableContainer, TableHead, TableBody } from './styles';
import { IoSearch, IoAddOutline, IoPencilOutline, IoTrashOutline } from "react-icons/io5";

/*
    Esse component recebe os dados e rendireza uma tabela com os compos pedidos
        parametros:{
            data: Um array com os dados que deseja renderizar.
            list: Os campos do array que dejesa mostrar na tabela.
            handleClicked: função que recebe o ID do item da linha da tabela que o usuario clicar.
        } 
*/
export default function Table({ data, list, handleClicked, handleDelete }){
    return(
        <TableContainer>
            <TableHead>
                <tr>
                    {list.map((l, i) => (
                        <th key={i}>{l.replace(/_/g, ' ')}</th>
                    ))}
                    <th style={{width: 77}}>Editar</th>
                    <th style={{width: 77}}>Excluir</th>
                </tr>
            </TableHead>
            <TableBody>
                {data.map((d, i) => (
                    <tr key={i}>
                        {list.map((l, i) => (
                            <td key={i} data-title={l}>{d[l].length > 25 ? d[l] = d[l].substring(0,24) + '...' : d[l]}</td>
                        ))}
                        <td data-title='Editar'><IoPencilOutline
                            color='blue'
                            onClick={() => {
                                handleClicked(d.id)
                            }}
                        /></td>
                        <td data-title='Excluir'><IoTrashOutline
                            color='red'
                            onClick={ async ()=>{
                                await handleDelete(d.id)
                            }}
                        /></td>
                    </tr>
                ))}
            </TableBody>
        </TableContainer>
    );
}