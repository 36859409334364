import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom'
import { Container, Head, Data, WapperNews, New, Header, HeaderGroup, AuthorDate, ProfileGroup, ProfileImage, ProfileName, TitleGroup, TitleNew, BodyNew, Link} from './styles';
import { Logout } from '../../services/logout';
import Auth from "../../services/auth";
import profileImg from '../../assets/images/profile.png'

export default function NewsView() {

    const history = useHistory();
    const [news, setNews] = useState([]);
    const [token, setToken] = useState(Auth.token());

    useEffect(() => {
        const id = history.location.state
        api.get(`/news/${id}?auth=${token}`).then(response => {
            setNews(response.data.artigos)
        }).catch(error => {
            if (error.response.status === 401) {
                Logout();
            }
        });
    }, []);

    return (
        <Container>
            {news.map((n, i) => (
                <>
                    <Head>
                        <h1>{n.titulo}</h1>
                        <p>Autor: {n.autor}</p>
                    </Head>
                    <WapperNews>
                        <New key={i}>
                            <Header>
                                <AuthorDate>
                                    <ProfileGroup>
                                        <p style={{ textAlign: "right", right: 0, position: "absolute", top: 30, marginRight: 10 }}>{n.inicio_publicacao}</p>
                                        <Link href={`http://${n.link_fonte}`} target="_blank" style={{ textAlign: "right", right: 0, position: "absolute", top: 50, marginRight: 10, color: "white" }}>Fonte: {n.link_fonte}</Link>
                                        <ProfileImage>
                                            <img onError={e => { e.currentTarget.src = profileImg; }} src={`https://acibike.fiecdev.com.br:25555/newsphoto/${n.id}_logo.jpeg` || profileImg} alt={'Erro'} />
                                        </ProfileImage>
                                        {/* <HeaderGroup>
                                            <Data>
                                                <p>14 de Jan. 2021</p>      
                                            </Data>
                                        </HeaderGroup> */}
                                    </ProfileGroup>
                                </AuthorDate>
                            </Header>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
                                <img style={{ maxWidth: "100%", maxHeight: "100%" }} onError={e => { e.currentTarget.src = profileImg; }} src={`https://acibike.fiecdev.com.br:25555/newsphoto/${n.id}_banner.jpeg` || profileImg} alt={'Erro'} />
                            </div>
                            <BodyNew>
                                {/* <NewImg /> Ainda não será usado. Quando arrumarmos a adição, edição e exclusão de imagens, aí sim poderá ser usado*/}
                                {/* Está perfeito, o único problema é que não fica com os parágrafos do jeito que seria pra ficar. Portanto, arrumar isso */}
                                <p style={{whiteSpace: "pre-line"}}>{n.conteudo}</p>
                            </BodyNew>
                        </New>
                    </WapperNews>
                </>
            ))}
        </Container>
    )
}