import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

export const Background = styled.div`
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    /* padding: 4rem calc((100vw - 1000px) / 2) 0 calc((100vw - 1000px) / 2); */
    position: fixed;
    /* top: -15%; */
    left:0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
`;
export const ModalContent = styled.div`
    background: #f4f4f4;
    height: 550px;
    top:0;
    left:0;
    background: #fff;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    position: relative;

`;
export const CloseModalButton = styled(MdClose)`
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
`;
export const Wrapper = styled.div`
    width:100%;
    height:100%;
    left:0;
`;

export const Options = styled.div`
    width:100%;
    margin-top: -10px;
`;
export const Image = styled.div`
    padding: 30px;
    img{
        max-width: 90%; 
        max-height: 500px;
        min-width: 50%;
        min-height: 80%;
        -webkit-box-shadow:0px 0px 18px 7px #c5c5c5 ;
        -moz-box-shadow:0px 0px 18px 7px #c5c5c5 ;
        box-shadow:0px 0px 18px 7px #c5c5c5 ;
    }
`;

export const WapperImages = styled.div`
    display: flex;
    max-width: fit-content;
    max-height: fit-content;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    background: #fff;
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;
    /* width: 25%; */
    /* -webkit-box-shadow:0px 0px 18px 7px #c5c5c5 ;
    -moz-box-shadow:0px 0px 18px 7px #c5c5c5 ;
    box-shadow: 0px 0px 18px 7px #c5c5c5 ; */
    @media screen and (max-width: 1024px){
        width: 30%;
        /* margin-top: 20px;
        margin-left: 37%; */
        margin-top: 20px;
        width: 90%;
        max-height: 200px;
    }
    /* @media screen and (max-width: 882px){
        max-height: 150px;
    }
    @media screen and (max-width: 768px){
       overflow-y:auto;
    } */
`;

export const TitlePhoto = styled.div`
    display: flex;
    padding-top: 30px;
    justify-content: center;
    width: 300px;
    margin-left: 15px;
    text-transform: uppercase;
    font-size: 1.5rem;
    color: var(--dark-color);
`;

export const ImageGroup = styled.div`
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 30px;
    margin-left: 1%;
    img{
        max-width: 100%;
        max-height: 100%;
        -webkit-box-shadow: 0px 0px 18px 7px #c5c5c5 ;
        -moz-box-shadow: 0px 0px 18px 7px #c5c5c5 ;
        box-shadow: 0px 0px 18px 7px #c5c5c5 ;
    }
    @media screen and (max-width: 1024px){
        /* min-width: 120px; */
    }
`;

export const BodyImage = styled.div`
    height:75%;
    width:100%;
    /* border: solid #f4f5f7 1px; */
`;

