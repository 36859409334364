import styled from 'styled-components';
import bg from '../../assets/images/register.jpg';

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background: url(${bg});
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(7, 12, 31, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const Content = styled.div`
    min-width: 30%;
    padding: 2rem;
    background: #fff;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    display: flex;
    align-items: center;
    justify-content: center;
    /* Se eu tirar esse media query pode acabar ficando mais bonito, mas decidir isso depois */
    /* E se eu tirar, também facilita na hora da responsividade do Políticas de Privacidade */
    @media screen and (max-width: 768px){
        width: 90%;
        padding: 1rem;
    }
`;
export const Form = styled.form`
    width: 100%;
    text-align: center;
    flex-wrap: wrap;
    h1{
        margin-bottom: 2rem;
        text-transform: uppercase;
    }
`;
export const FormGroup = styled.div`
    position: relative;
    margin-bottom: 1.5rem;
    svg{
        position: absolute;
        top: 25%;
        right: 1%;
        cursor: pointer;
    }
`;
export const Column = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
`;
export const LinkGroup = styled.div`
    margin-top: 1rem;
    font-size: var(--small-font-size);
    display: flex;
    justify-content: space-around;
    a{
        color: var(--blue-color);
        &:hover{
            text-decoration: underline;
        }
    }
`;

export const Label = styled.label`
    font-weight: 800;
    font-size: 1rem;
    color: black;
    display: block;
    white-space: nowrap;
    bottom: 50%;
`;

export const AcceptCheckBoxes = styled.div`
    /* border: 1px solid black; */
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    color: black;
    p{
        font-size: 1rem;
        margin-left: 10px;
        margin-bottom: 3px;
    }
    input{
        
    }
    label{
        color: black;
    }
    @media screen and (max-width: 768px){
        flex-direction: column;
    }
`;