import React, { useState, useEffect } from 'react';
import { Container, Title, Options, ButtonAdd } from './styles';
import { IoAddOutline, IoPersonAddSharp } from "react-icons/io5";
import Filters from '../../components/Filters';
import Card from '../../components/Card';
import Pagination from '../../components/Pagination';
import ModalEdit from '../../components/ModalEdit';
import ModalImage from '../../components/ModalImage';
import ModalRegister from '../../components/Modal/Users/Register/index';
import api from '../../services/api';
import swal from 'sweetalert'
import Auth from "../../services/auth";
import { Logout } from '../../services/logout';
import { useLocation } from 'react-router-dom'

export default function AdminUsers() {

    const [users, setUsers] = useState([]);
    const [userSelected, setUserSelected] = useState(0);
    const [filters, setFilters] = useState({ show: 'TODOS', search: '' });
    // const [showModal, setShowModal] = useState({ edit: false, image: false });
    const [showModalEdit, setShowModalEdit] = useState({ edit: false });
    const [showModalImage, setShowModalImage] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [alreadyLoaded, setAlreadyLoaded] = useState(false);
    const [reload, setReload] = useState(false);
    const [error, setError] = useState(false);
    const [showModalRegister, setShowModalRegister] = useState(false);
    const [registerUser, setRegisterUser] = useState('');
    const [token, setToken] = useState(Auth.token());
    const search = useLocation().search;
    const email = new URLSearchParams(search).get('email');

    function handleClickedEdit(id) {
        // setShowModal({ ...showModal, edit: !showModal.edit });
        // setShowModalEdit(true)
        setShowModalEdit({ ...showModalEdit, edit: !showModalEdit.edit });
        setUserSelected(id);
    }

    function handleClickedImage(id) {
        // setShowModal({ ...showModal, image: !showModal.image });
        setShowModalImage(true)
        setUserSelected(id);
    }

    async function handleDelete(id) {
        try {
            swal({
                title: "Você tem certeza?",
                text: "Uma vez deletado o usuário terá que refazer o cadastro",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {
                        await api.delete(`admin/users/${id}?auth=${token}`)
                        setUsers(users.filter(user => user.id !== id));
                        swal("Usuário deletado com sucesso!", {
                            icon: "success",
                        })
                    } else {
                        swal("Usuário não deletado!")
                    }
                })
        } catch (error) { alert('Erro ao deletar usuario!'); }
    }

    useEffect(() => {
        if (email) {
            filters.search = email;
        }
    }, [])

    useEffect(() => {
        !alreadyLoaded && setLoading(true);
        api.get(`admin/users?_tipo=${filters.show}&_search=${filters.search}&_page=${page}&auth=${token}`).then(response => {
            setUsers(response.data.users);
            setTotalPages(response.data.totalPages);
            !alreadyLoaded && setLoading(false); !alreadyLoaded && setAlreadyLoaded(true);
        }).catch(error => {
            console.log(error);
            setLoading(false);
            setError(true);
            if (error.response.status === 401) {
                Logout();
            }
        });
    }, [filters, page, reload, alreadyLoaded]);

    return (
        <Container>
            <Title>Usuários</Title>
            <Options>
                <ButtonAdd onClick={() => {
                    setShowModalRegister(!showModalRegister)
                }}>
                    {/* <IoAddOutline id="iconAdd" /> */}
                    <IoPersonAddSharp
                        size={30}
                        color='white'
                    />
                </ButtonAdd>
                <ModalRegister
                    showModal={showModalRegister}
                    setShowModal={setShowModalRegister}
                    id={registerUser}
                />
            </Options>
            <Filters
                filters={filters}
                setFilters={setFilters}
                data={['TODOS', 'ADMINISTRADOR', 'CICLISTA', 'GUARDA']}
            />
            <Card
                loading={loading}
                error={error}
                repeatLoading={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
                data={users}
                list={['email', 'nome', 'tipo']}
                handleClickedEdit={handleClickedEdit}
                handleClickedImage={handleClickedImage}
                handleDelete={handleDelete}
            />
            <Pagination
                page={page}
                setPage={setPage}
                totalPages={totalPages}
            />
            <ModalEdit
                // showModal={showModal}
                // setShowModal={setShowModal}
                showModal={showModalEdit}
                setShowModal={setShowModalEdit}
                id={userSelected}
            />
            <ModalImage
                // showModal={showModal}
                // setShowModal={setShowModal}
                showModal={showModalImage}
                setShowModal={setShowModalImage}
                list={['foto']}
                data={userSelected}
                id={userSelected}
            />
        </Container>
    );
}