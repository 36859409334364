import React, { useState, useEffect } from 'react';
import { Head, Wrapper, Card, CardHeader, CardBody, CardGroup } from './styles';
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import api from '../../services/api'
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { Logout } from '../../services/logout';
import Auth from "../../services/auth";

export default function NewsCard() {

    const history = useHistory();
    const [repeat] = useState([1, 2, 3]);
    const [news, setNews] = useState([]);
    const [token, setToken] = useState(Auth.token());

    useEffect(() => {
        api.get(`/newsdestaque?auth=${token}`).then(response => {
            if (response.error) swal(response.error);
            else {
                setNews(response.data.artigos);
            }
        }).catch(error => {
            if (error.response.status === 401) {
                Logout();
            }
        });
    }, [])

    function handleClicked(id) {
        history.push({
            pathname: `/news/${id}`,
            state: id
        })
    }

    return (
        <>
            <Head>
                <h1>Notícias em Destaque</h1>
                {/* <p>Tópicos para ficar por dentro de nossa rede de ciclistas.</p> */}
            </Head>
            <Wrapper>
                {news.map(n => (
                    <Card key={n}>
                        <CardHeader>
                            <h1>Notícia</h1>
                        </CardHeader>
                        <CardBody>
                            <CardGroup>
                                {/* <strong>Passeio Ciclístico</strong>
                                <span>No dia 12/08/2022 haverá o passeio ciclístico em Indaiatuba</span> */}
                                <strong>{n.titulo}</strong>
                                <span>{n.conteudo}</span>
                            </CardGroup>
                            <button
                                onClick={() => {
                                    handleClicked(n.id)
                                }}
                            >
                                Leia Mais
                                <IoArrowForwardCircleOutline />
                            </button>
                        </CardBody>
                    </Card>
                ))}
            </Wrapper>
        </>
    );
}