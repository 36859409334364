import React, { useState, useEffect } from 'react';
import { Container, ImageContent, Image, ImageData, ImagePhoto, DataContent, FormGroup, BodyImage, Input } from './styles';
import User from '../../assets/images/profile.png';
import api from '../../services/api';
import Auth from '../../services/auth';
import InputMask from 'react-input-mask';
import swal from 'sweetalert';
import ChangePassword from '../../components/Modal/ChangePassword';
import { IoTrashOutline } from "react-icons/io5";
import { FaEdit } from "react-icons/fa";
import UploadImage from './UploadImage';
import './styles.css';

export default function Profile() {

    const [id, setId] = useState(Auth.id());
    const [auth, setAuth] = useState(Auth.token());
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [data_nasc, setData_nasc] = useState('');
    const [telefone, setTelefone] = useState('');
    const [tipo_sangue, setTipo_sangue] = useState('');
    const [doenca, setDoenca] = useState('');
    const [medicacao, setMedicacao] = useState('');
    const [alergias, setAlergias] = useState('');
    const [reacoes_medicamentos, setReacoes_medicamentos] = useState('');
    const [convenio, setConvenio] = useState('');
    const [nome_contato_emergencia, setNome_contato_emergencia] = useState('');
    const [parentesco_contato_emergencia, setParentesco_contato_emergencia] = useState('');
    const [telefone_contato_emergencia, setTelefone_contato_emergencia] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [foto_host, setFoto_host] = useState(true);
    const [url_foto, setUrl_foto] = useState(null);
    const [foto, setFoto] = useState([]);
    const [haveImage, setHaveImage] = useState(false);

    useEffect(async () => {
        await api.get(`/users/${id}?auth=${auth}`).then(response => {
            setNome(response.data.users[0].nome + ' ' + response.data.users[0].sobrenome)
            setEmail(response.data.users[0].email)
            setTelefone(response.data.users[0].telefone)
            if (response.data.users[0].data_nascimento) {
                const fulldate = response.data.users[0].data_nascimento.split('T');
                const formattedDate = fulldate[0];
                setData_nasc(formattedDate);
            }
            setTipo_sangue(response.data.users[0].tipo_sanguineo ? response.data.users[0].tipo_sanguineo : '');
            setDoenca(response.data.users[0].doenca ? response.data.users[0].doenca : '');
            setMedicacao(response.data.users[0].medicacao ? response.data.users[0].medicacao : '');
            setAlergias(response.data.users[0].alergias ? response.data.users[0].alergias : '');
            setReacoes_medicamentos(response.data.users[0].reacoes_medicamentos ? response.data.users[0].reacoes_medicamentos : '');
            setConvenio(response.data.users[0].convenio ? response.data.users[0].convenio : '');
            setNome_contato_emergencia(response.data.users[0].nome_contato_emergencia ? response.data.users[0].nome_contato_emergencia : '');
            setParentesco_contato_emergencia(response.data.users[0].parentesco_contato_emergencia ? response.data.users[0].parentesco_contato_emergencia : '');
            setTelefone_contato_emergencia(response.data.users[0].telefone_contato_emergencia ? response.data.users[0].telefone_contato_emergencia : '');
        }).catch(error => alert(error));
    }, [id]);

    useEffect(() => {
        if (foto.length || haveImage) {
            setHaveImage(true);
        } else {
            setHaveImage(false);
        }
        foto.map(file => setUrl_foto(URL.createObjectURL(file)));
    }, [foto])

    function handleDeleteImage() {
        swal({
            text: "Tem certeza?",
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setFoto_host(false);
                setHaveImage(false);
                setUrl_foto(null);
                setFoto([]);
            }
        })
    }

    async function handleSubmit(e) {
        e.preventDefault();

        if (haveImage) {
            let formData = new FormData();
            formData.append('nome', nome);
            formData.append('email', email);
            formData.append('data_nascimento', data_nasc);
            formData.append('telefone', telefone);
            formData.append('tipo_sanguineo', tipo_sangue);
            formData.append('doenca', doenca);
            formData.append('medicacao', medicacao);
            formData.append('alergias', alergias);
            formData.append('reacoes_medicamentos', reacoes_medicamentos);
            formData.append('convenio', convenio);
            formData.append('nome_contato_emergencia', nome_contato_emergencia);
            formData.append('parentesco_contato_emergencia', parentesco_contato_emergencia);
            formData.append('telefone_contato_emergencia', telefone_contato_emergencia);
            if (foto.length) foto.forEach(file => formData.append(`${id}`, file));

            const response = await api.patch(`/update/${id}?auth=${auth}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'multipart/form-data'
                }
            });
            if (response.data.error) return response.data.error;
            swal('Sucesso!', 'Usuário editado!', 'success')
            setTimeout(() => {
                window.location.reload();
            }, 700);
        } else {
            try {
                const data = {
                    nome: nome,
                    email: email,
                    data_nascimento: data_nasc,
                    telefone: telefone,
                    tipo_sanguineo: tipo_sangue,
                    doenca: doenca,
                    medicacao: medicacao,
                    alergias: alergias,
                    reacoes_medicamentos: reacoes_medicamentos,
                    convenio: convenio,
                    nome_contato_emergencia: nome_contato_emergencia,
                    parentesco_contato_emergencia: parentesco_contato_emergencia,
                    telefone_contato_emergencia: telefone_contato_emergencia,
                    foto: foto_host
                }
                const response = await api.patch(`/update/${id}?auth=${auth}`, { data })
                if (response.data.error) return response.data.error;
                swal('Sucesso!', 'Usuário editado!', 'success')
                setTimeout(() => {
                    window.location.reload();
                }, 700);
            } catch (error) {
                console.log(error);
            }
        }
    }

    return (
        <Container>
            {/* Acrescentar uma div para ficar o Grid Css, que não seja o Container, para facilitar */}
            {/* Decidir se vai pôr título ou deixar sem, e se for deixar, ver se vai fazer com algumas guias em cima do DataContent, que nem no GitHub */}
            {/* <h1>Profile</h1> */}
            {/* <Form> */}
            <ChangePassword
                showModal={showModal}
                setShowModal={setShowModal}
                id={id}
            />
            <ImageContent>
                <Image>
                    <ImagePhoto>
                        {foto_host ?
                            // <BodyImage>
                            //     <img onError={e => { setFoto_host(false) }} src={`https://acibike.fiecdev.com.br:25555/usersphoto/${id}.jpeg?auth=${auth}`} data-tooltip="Foto do local" />
                            //     <IoTrashOutline color="#F00" size={26} style={{ cursor: 'pointer' }} onClick={() => { swal({ title: "Você tem certeza?", icon: "warning", buttons: true, dangerMode: true, }).then((willDelete) => { if (willDelete) { setFoto_host(false); } }); }} />
                            // </BodyImage>
                            <img onError={e => { setHaveImage(false); setFoto_host(false); }} src={`https://acibike.fiecdev.com.br:25555/usersphoto/${id}.jpeg?auth=${auth}`} data-tooltip="Foto do local" />
                            :
                            url_foto
                                ?
                                <BodyImage>
                                    <img src={url_foto} data-tooltip="Foto do local" />
                                    <IoTrashOutline color="#F00" size={26} style={{ cursor: 'pointer' }} onClick={() => { handleDeleteImage() }} />
                                </BodyImage>
                                : (
                                    <UploadImage amount={1} msg={`(Foto do usuário) `} setFile={setFoto} />
                                )}
                    </ImagePhoto>
                    {foto_host ?
                        <IoTrashOutline color="#F00" size={26} style={{ cursor: 'pointer', alignSelf: "center", marginLeft: 160, marginTop: -50 }} onClick={() => { handleDeleteImage() }} />
                        :
                        null
                    }
                </Image>
                <ImageData>
                    <h3>Dados Pessoais</h3>
                    <FormGroup>
                        <input
                            type="text"
                            placeholder="Nome"
                            value={nome}
                            onChange={e => setNome(e.target.value)}
                        // style={{width: 300}}
                        // disabled
                        />
                        <label>Nome</label>
                    </FormGroup>
                    <FormGroup>
                        <input
                            type="text"
                            placeholder="Email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        // style={{width: 300}}
                        // disabled
                        />
                        <label>Email</label>
                    </FormGroup>
                    <FormGroup>
                        <InputMask
                            type="text"
                            mask='(99) 99999-9999'
                            placeholder="Telefone"
                            value={telefone}
                            onChange={e => setTelefone(e.target.value)}
                            className='input'
                        // style={{width: 300}}
                        />
                        <label>Telefone</label>
                    </FormGroup>
                    <FormGroup>
                        <input
                            type="date"
                            placeholder="Data de Nascimento"
                            value={data_nasc}
                            onChange={e => setData_nasc(e.target.value)}
                        // style={{width: 300}}
                        />
                        <label>Data de Nascimento</label>
                    </FormGroup>
                    <button style={{ backgroundColor: '#d9534f', borderColor: '#d9534f' }}
                        onClick={() => {
                            setShowModal(!showModal)
                        }}>Mudar Senha</button>
                </ImageData>
            </ImageContent>
            <DataContent>
                <h3>Dados Vitais</h3>
                <FormGroup>
                    <select
                        value={tipo_sangue}
                        onChange={e => setTipo_sangue(e.target.value)}
                    // style={{width: 300}}
                    >
                        <option value="N/I">Não informado</option>
                        <option value="A+">A+</option>
                        <option value="A-">A-</option>
                        <option value="B+">B+</option>
                        <option value="B-">B-</option>
                        <option value="AB+">AB+</option>
                        <option value="AB-">AB-</option>
                        <option value="O+">O+</option>
                        <option value="O-">O-</option>
                    </select>
                    <label>Tipo Sanguíneo</label>
                </FormGroup>
                <FormGroup>
                    <input
                        type="text"
                        placeholder="Doença"
                        value={doenca}
                        onChange={e => setDoenca(e.target.value)}
                    // style={{width: 300}}
                    // disabled
                    />
                    <label>Doença</label>
                </FormGroup>
                <FormGroup>
                    <input
                        type="text"
                        placeholder="Medicação"
                        value={medicacao}
                        onChange={e => setMedicacao(e.target.value)}
                    // style={{width: 300}}
                    // disabled
                    />
                    <label>Medicação</label>
                </FormGroup>
                <FormGroup>
                    <input
                        type="text"
                        placeholder="Alergias"
                        value={alergias}
                        onChange={e => setAlergias(e.target.value)}
                    // style={{width: 300}}
                    // disabled
                    />
                    <label>Alergias</label>
                </FormGroup>
                <FormGroup>
                    <input
                        type="text"
                        placeholder="Reações à Medicamentos"
                        value={reacoes_medicamentos}
                        onChange={e => setReacoes_medicamentos(e.target.value)}
                    // style={{width: 300}}
                    // disabled
                    />
                    <label>Reações à Medicamentos</label>
                </FormGroup>
                <FormGroup>
                    <input
                        type="text"
                        placeholder="Convênio"
                        value={convenio}
                        onChange={e => setConvenio(e.target.value)}
                    // style={{width: 300}}
                    // disabled
                    />
                    <label>Convênio</label>
                </FormGroup>
                <FormGroup>
                    <input
                        type="text"
                        placeholder="Nome para Contato/Emergência"
                        value={nome_contato_emergencia}
                        onChange={e => setNome_contato_emergencia(e.target.value)}
                    // style={{width: 300}}
                    // disabled
                    />
                    <label>Nome para Contato/Emergência</label>
                </FormGroup>
                <FormGroup>
                    <input
                        type="text"
                        placeholder="Parentesco para Contato/Emergência"
                        value={parentesco_contato_emergencia}
                        onChange={e => setParentesco_contato_emergencia(e.target.value)}
                    // style={{width: 300}}
                    // disabled
                    />
                    <label>Parentesco para Contato/Emergência</label>
                </FormGroup>
                <FormGroup>
                    <InputMask
                        type="text"
                        mask='(99) 99999-9999'
                        placeholder="Telefone para Contato/Emergência"
                        value={telefone_contato_emergencia}
                        onChange={e => setTelefone_contato_emergencia(e.target.value)}
                        className='input'
                    // style={{width: 300}}
                    // disabled
                    />
                    <label>Telefone para Contato/Emergência</label>
                </FormGroup>
                <button onClick={handleSubmit}>Salvar Dados</button>
            </DataContent>
            {/* </Form> */}
        </Container>
    )
}