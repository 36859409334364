import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import { Background, ModalContent, CloseModalButton, Form, FormContent, FormGroup, Delete, WapperImages, TitlePhoto, Image, ImageGroup, BodyImage, Options, TextArea } from './styles';
import UploadImage from '../../../UploadImage';
import InputMask from 'react-input-mask';
import api from '../../../../services/api';
import swal from 'sweetalert';
import { IoTrashOutline } from "react-icons/io5";
import Auth from '../../../../services/auth';

export default function ModalRegister({ showModal, setShowModal, handleDelete, id, setUpdeted }) {
    const modalRef = useRef();
    const [titulo, setTitulo] = useState('');
    const [conteudo, setConteudo] = useState('');
    const [segmento, setSegmento] = useState('');
    const [autor, setAutor] = useState('');
    const [email, setEmail] = useState('');
    const [link_fonte, setLink_fonte] = useState('');
    const [banner, setBanner] = useState('');
    const [logo, setLogo] = useState('');
    const [haveImage, setHaveImage] = useState(false);
    const [url_banner, setUrl_banner] = useState(null);
    const [url_logo, setUrl_logo] = useState(null);
    const [token, setToken] = useState(Auth.token())
    const animation = useSpring({
        config: {
            duration: 150
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`
    });

    const keyPress = useCallback(e => {
        if (e.key === 'Escape' && showModal) setShowModal(!showModal);
    }, [setShowModal, showModal]);

    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);

    useEffect(() => {
        if(banner || logo) {setHaveImage(true)} else{setHaveImage(false)}
        if(banner) banner.map(file => setUrl_banner(URL.createObjectURL(file)));
        if(logo) logo.map(file => (setUrl_logo(URL.createObjectURL(file))));
    }, [banner, logo]);

    async function handleSubmit(e) {
        e.preventDefault();

            if (haveImage === true) {
                let formData = new FormData();
                formData.append('titulo', titulo);
                formData.append('conteudo', conteudo);
                formData.append('segmento', segmento);
                formData.append('autor', autor);
                formData.append('email', email);
                formData.append('link_fonte', link_fonte);
                if (banner) banner.forEach(file => formData.append('banner', file));
                if (logo) logo.forEach(file => formData.append('logo', file));

                var data = {};
                var int = 0
                formData.forEach((value, key) => {
                    // data[key] = value != 'null' || value != 'undefined' ? value : undefined
                    // Valor das fotos não está chegando como undefined em si, mas sim como undefined dentro de uma string
                    if(value != 'null' || value != 'undefined' || value != null || value != undefined) {
                        data[key] = value
                        int++
                    } 
                });

                try {
                    await api.post(`/admin/news?auth=${token}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Accept: 'multipart/form-data'
                        }
                    } ).then(response => {
                        if(response.data.error) swal('Atenção!', response.data.error, 'warning')
                        swal('Sucesso!', 'Notícia criada', 'success')
                        setTimeout(()=>{
                            window.location.reload(false);
                        }, 700);
                    })
                } catch (error) { swal('Atenção!', `Serviço indisponível no momento!`, 'error') }
            } 
            else {
                const data = {
                    id: id,
                    titulo: titulo,
                    conteudo: conteudo,
                    segmento: segmento,
                    autor: autor,
                    email: email,
                    link_fonte: link_fonte,
                    banner: banner,
                }
                try {
                    const response = await api.post(`/admin/news?auth=${token}`, { data });
                    if(response.data.error) return swal('Atenção', response.data.error, 'warning')
                    swal('Sucesso', 'Notícia criada', 'success')
                    // setUpdeted(true);
                    setTimeout(()=>{
                        window.location.reload(false);
                    }, 700);
                } catch (error) { 
                    // alert(error)
                    swal('Atenção',  `Serviço indisponível no momento!`, 'error') 
                }
        }

    }

    function handleDeleteImage(element) {
        swal({
            text: "Tem certeza?",
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                if (element === 'banner') {
                    setUrl_banner(null);
                    swal({
                        text: "Deletado com sucesso",
                        icon: 'success',
                    });
                } else {
                    if (element === 'logo') {
                        setUrl_logo(null);
                        swal({
                            text: "Deletado com sucesso",
                            icon: 'success',
                        });
                    }
                }


            }
        })
    }

    return (
        <>
            {showModal ? (
                <Background ref={modalRef} onClick={(e) => modalRef.current === e.target ? setShowModal(!showModal) : null}>
                    <animated.div style={animation}>
                        <ModalContent>
                            <Form onSubmit={handleSubmit}>
                                <h1>Criação de notícias</h1>
                                <FormContent>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Título"
                                            value={titulo}
                                            onChange={e => setTitulo(e.target.value)}
                                            required
                                        />
                                        <label>Título</label>
                                    </FormGroup>
                                    <TextArea>
                                        <textarea
                                            type="text"
                                            placeholder="Conteúdo"
                                            value={conteudo}
                                            onChange={e => setConteudo(e.target.value)}
                                            style={{padding: 15, resize: 'none', marginBottom: 27, borderRadius: 6}}
                                            required
                                        />
                                    </TextArea>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Segmento"
                                            value={segmento}
                                            onChange={e => setSegmento(e.target.value)}
                                            min="1"
                                            max="99"
                                            required
                                        />
                                        <label>Segmento</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Autor"
                                            value={autor}
                                            onChange={e => setAutor(e.target.value)}
                                            required
                                        />
                                        <label>Autor</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Link da fonte"
                                            value={link_fonte}
                                            onChange={e => setLink_fonte(e.target.value)}
                                            min="1"
                                            max="99"
                                        />
                                        <label>Link do site</label>
                                    </FormGroup>
                                </FormContent>
                                <WapperImages>
                                    <ImageGroup>
                                        <TitlePhoto>Banner</TitlePhoto>
                                        {!url_banner ? <Image data-tooltip={banner.length === 0 ? 'Nenhuma imagem carregada!' : 'Imagem carregada com sucesso!'}>
                                            <UploadImage amount={1} msg={`(Banner) `} setFile={setBanner} />
                                        </Image> : (
                                            <BodyImage>
                                                <img src={url_banner} data-tooltip="banner" />
                                                <Options>
                                                    <IoTrashOutline color="#F00" onClick={() => { handleDeleteImage('banner') }} />
                                                </Options>
                                            </BodyImage>
                                        )}
                                    </ImageGroup>
                                    <ImageGroup>
                                        <TitlePhoto>Logo</TitlePhoto>
                                        {!url_logo ? <Image data-tooltip={logo.length === 0 ? 'Nenhuma imagem carregada!' : 'Imagem carregada com sucesso!'}>
                                            <UploadImage amount={1} msg={`(Logo) `} setFile={setLogo} />
                                        </Image> : (
                                            <BodyImage>
                                                <img src={url_logo} data-tooltip="Logo" />
                                                <Options>
                                                    <IoTrashOutline color="#F00" onClick={() => { handleDeleteImage('logo') }} />
                                                </Options>
                                            </BodyImage>
                                        )}
                                    </ImageGroup>
                                </WapperImages>
                                <button type="submit">Enviar</button>
                            </Form>
                            {/* <Delete>
                                <IoTrashOutline onClick={() => { handleDelete(id); setShowModal(!showModal) }} />
                            </Delete> */}
                            <CloseModalButton onClick={() => setShowModal(!showModal)} />
                        </ModalContent>
                    </animated.div>
                </Background>
            ) : null}
        </>
    );
}