import jwt from 'jsonwebtoken';
import jwt_decode from "jwt-decode";

export const isAuthenticated = () => {
    const token = localStorage.getItem('token');

    try {
        jwt.verify(token, 'acibike@citec');
        var decoded = jwt_decode(token);
        if (decoded) {
            return true;
        }
    } catch (error) {
        localStorage.clear();
        return false;
    }

};

export const isAuthenticatedGuard = () => {
    const token = localStorage.getItem('token');
return true;
    try {
        jwt.verify(token, 'acibike@citec');
        var decoded = jwt_decode(token);
        if (decoded.type === "GUARDA") return true;
        else return false;
    } catch (error) {
        localStorage.clear();
        return false;
    }

};

export const isAuthenticatedAdmin = () => {
    const token = localStorage.getItem('token');

    try {
        jwt.verify(token, 'acibike@citec');
        var decoded = jwt_decode(token);
        if (decoded.type === "ADMINISTRADOR") return true;
        else return false;
    } catch (error) {
        localStorage.clear();
        return false;
    }

};