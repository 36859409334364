import React, { useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Content, Form, FormGroup, LinkGroup } from './styles';
import GoBack from '../../components/BackToMenu/index'
import { IoEyeOutline, IoEyeOffOutline, IoHome } from "react-icons/io5";
import api from '../../services/api';
import swal from 'sweetalert';
import ReCAPTCHA from "react-google-recaptcha";

export default function Login() {

    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [visible, setVisible] = useState(false);
    const history = useHistory();
    const recaptchaRef = useRef();

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            const token = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset();
            const data = {
                email: email,
                senha: senha,
                retoken: token
            }
            const response = await api.post('/login', { data });
            if (response.data.error) return swal('Atenção!', response.data.error, 'warning');
            localStorage.setItem('token', response.data.token);
            if (response.data.type == 'ADMINISTRADOR') {
                history.push('/admin')
            } else if (response.data.type == 'GUARDA') {
                history.push('/guard/occurrences')
            } else {
                history.push('/home')
            }
        } catch (error) {
            console.log(error);
            swal('Atenção', 'Serviço indisponível no momento!', 'error')
        }
    }
    return (
        <Container>
            <GoBack />
            <Content>
                <Form onSubmit={handleSubmit}>
                    {/* Tentando colocar o botão de voltar ao menu aqui, mas não sei se é a melhor opção */}
                    <h1>Conecte-se</h1>
                    <FormGroup>
                        <input
                            type="text"
                            placeholder="Email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            maxLength="150"
                            required
                        />
                        <label>Email</label>
                    </FormGroup>
                    <FormGroup>
                        <input
                            type={visible ? 'text' : 'password'}
                            autoComplete="current-password"
                            placeholder="Senha"
                            value={senha}
                            onChange={e => setSenha(e.target.value)}
                            minLength="8"
                            maxLength="255"
                            required
                        />
                        <label>Senha</label>
                        {visible ? (
                            <IoEyeOutline size={22} color="#000" onClick={() => setVisible(!visible)} />
                        ) : (
                            <IoEyeOffOutline size={22} color="#000" onClick={() => setVisible(!visible)} />
                        )}
                    </FormGroup>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        size="invisible"
                    />
                    <button type='submit'>Entrar</button>
                    <LinkGroup>
                        <Link to="/recover">Esqueçeu a senha?</Link>
                        <Link to="/register">Novo aqui?</Link>
                    </LinkGroup>
                </Form>
            </Content>
        </Container>
    );
}