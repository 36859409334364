import React from 'react';
import { Container, Card, CardHeader, CardBody, CardFooter, CardGroup, Options } from './styles';
import { IoArrowRedoSharp, IoCarSport } from 'react-icons/io5';
import routeImg from '../../../assets/images/route.png';
import Strava from '../../../assets/images/logo_strava.png';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function CardComponent({ loading, error, repeatLoading, data, list, handleClicked }) {
    return (
        <>
            {loading || error ? (
                <Container>
                    {repeatLoading.map(rl => (
                        <Card key={rl}>
                            {list.map(l => (
                                <CardGroup key={l}>
                                    <div></div>
                                    <div></div>
                                </CardGroup>
                            ))}
                            <Options>
                                <div></div>
                                <div></div>
                                <div></div>
                            </Options>
                        </Card>
                    ))}
                </Container>
            ) : (
                <Container>
                    {data.map((d, i) => (
                        // <Card key={i}>
                        //     {list.map((l, i) => (
                        //         <CardGroup key={i}>
                        //             <strong>{l.replace(/_/g, ' ')}:</strong>
                        //             <span>{d[l]}</span>
                        //         </CardGroup>
                        //     ))}
                        //     <Options>
                        //         <IoArrowRedoSharp color="#000068" style={{position: "absolute", right: 0}} 
                        //             onClick={() => {
                        //                 handleClicked(d.id)
                        //             }} 
                        //         />
                        //     </Options>
                        // </Card>
                        <Card key={i}>
                            <CardHeader>{d.nome}</CardHeader>
                            <CardBody>
                                {/* <IoCarSport size={60}/> */}
                                <img src={routeImg} alt="Rota do dia" />
                                <CardGroup>
                                    {d.waypoints[0]
                                        ?
                                        <>
                                            <strong>partida:</strong>
                                            <span>{d.waypoints[0].endereco}</span>
                                        </>
                                        :
                                        <img style={{ justifySelf: "center" }} src={Strava} />
                                    }
                                </CardGroup>
                                <CardGroup>
                                    <strong>Percurso total:</strong>
                                    <span>{d.percurso_total + 'm'}</span>
                                </CardGroup>
                                <CardGroup>
                                    {d.waypoints[0]
                                        ?
                                        <>
                                            <strong>chegada:</strong>
                                            <span>{d.waypoints[d.waypoints.length - 1].endereco}</span>
                                        </>
                                        :
                                        <button style={{ backgroundColor: "#FF8C00", border: "none" }} onClick={() => {
                                            Swal.fire({
                                                title: 'Descrição da Rota',
                                                text: `${d.descricao}`,
                                                showCloseButton: true,
                                                showConfirmButton: false
                                            })
                                        }}>Descrição</button>
                                    }
                                </CardGroup>
                            </CardBody>
                            <Options>
                                <IoArrowRedoSharp color="#000068" style={{ position: "absolute", right: 0 }}
                                    onClick={() => {
                                        if (d.waypoints[0]) {
                                            handleClicked(d.id);
                                        } else if (d.link_strava.startsWith("http")) {
                                            window.open(d.link_strava, "_blank");
                                        } else {
                                            window.open(`http://${d.link_strava}`, "_blank");
                                        }
                                    }}
                                />
                            </Options>
                        </Card>
                    ))}
                </Container>
            )}
        </>
    );
}