import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import { Background, ModalContent, CloseModalButton, Form, FormGroup, FormContent, Delete, Buttons, TextArea } from './styles';
import api from '../../../services/api';
import { IoTrashOutline } from "react-icons/io5";
import swal from 'sweetalert';
import jwtDecode from 'jwt-decode'
import { Logout } from '../../../services/logout';
import Auth from '../../../services/auth'

export default function ModalMessages({ showModal, setShowModal, id, setUpdeted }) {
    const modalRef = useRef();
    const [nome, setNome] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [comentario, setComentario] = useState('');
    const [resposta, setResposta] = useState('');
    const [respondido,setRespondido] = useState(false);
    const [auth, setAuth] = useState(Auth.token())
    // const [file, setFile] = useState([]);
    const animation = useSpring({
        config: {
            duration: 150
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`
    });
    const keyPress = useCallback(e => {
        if (e.key === 'Escape' && showModal) setShowModal(!showModal);
    }, [setShowModal, showModal]);
    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);

    useEffect(() => {
        api.get(`admin/messages/${id}?auth=${auth}`).then(response => {
            setNome(response.data.mensagens[0].remetente + ' ' + response.data.mensagens[0].remetente_sobrenome);
            setTelefone(response.data.mensagens[0].telefone);
            setEmail(response.data.mensagens[0].email);
            setComentario(response.data.mensagens[0].comentario);
            if (response.data.mensagens[0].resposta) {
                setResposta(response.data.mensagens[0].resposta);
                setRespondido(true)
            }
            else { 
                setRespondido(false)
                setResposta('');
            }
        }).catch(error => {
            if (error.response.status === 401) {
                Logout();
            }
        })
    }, [id]);

    async function handleSubmit(e) {
        e.preventDefault();
        
        const token = localStorage.getItem('token');
        const user = jwtDecode(token);
        const data = {
            respondida_por: user.id,
            email: email,
            id: id,
            resposta: resposta,
            data_respondida: new Date().toISOString()
        }

        try{
            api.patch(`admin/messages/${id}?auth=${auth}`, { data }).then(response => {
                if(response.data.error) swal('Atenção!', response.data.error, 'warning')
                swal('Sucesso!', response.data.success, 'success')
                setTimeout(()=>{
                    window.location.reload(false);
                }, 500);
            });
        } catch(error) { swal('Atenção!', 'Serviço indisponível no momento', 'error') }
    }

    // function handleDelete(id) {
    //     api.delete(`admin/messages/${id}`).then(response => {
    //         if (response.data.success) {
    //             swal({
    //                 text: response.data.success,
    //                 icon: 'success'
    //             });
    //             setUpdeted(response.data.success)
    //             setShowModal(!showModal);
    //         } else {
    //             swal({
    //                 text: "Houve um erro ao tentar excluit o comentario!",
    //                 icon: 'error'
    //             });
    //         }
    //     })
    // }

    return (
        <>
            {showModal ? (
                <Background ref={modalRef} onClick={(e) => modalRef.current === e.target ? setShowModal(!showModal) : null}>
                    <animated.div style={animation}>
                        <ModalContent>
                            <Form onSubmit={handleSubmit}>
                                <h1>Responder</h1>
                                <FormContent>
                                    <FormGroup>
                                        <input
                                            disabled
                                            type="text"
                                            placeholder="Nome"
                                            value={nome}
                                            maxLength="255"
                                        />
                                        <label>Nome</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            disabled
                                            type="text"
                                            placeholder="Telefone"
                                            value={telefone}
                                            required
                                        />
                                        <label>Telefone</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            disabled
                                            type="email"
                                            placeholder="Email"
                                            value={email}
                                            minLength="3"
                                            maxLength="100"
                                            required
                                        />
                                        <label>email</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <TextArea>
                                            <textarea
                                                disabled
                                                type="text"
                                                placeholder="Comentario"
                                                value={comentario}
                                                style={{padding: 15, resize: 'none'}}
                                                required
                                            />
                                            <label>Comentario</label>
                                        </TextArea>
                                    </FormGroup>
                                    <FormGroup>
                                        <TextArea>
                                            { !respondido
                                                ?
                                                    <textarea
                                                        type="text"
                                                        value={resposta}
                                                        onChange={e => setResposta(e.target.value)}
                                                        minLength="1"
                                                        maxLength="255"
                                                        style={{resize: 'none'}}
                                                        required
                                                    />
                                                :
                                                    <textarea
                                                        type="text"
                                                        value={resposta}
                                                        onChange={e => setResposta(e.target.value)}
                                                        minLength="1"
                                                        maxLength="255"
                                                        style={{resize: 'none'}}
                                                        disabled
                                                />
                                            }
                                            <label>Resposta</label>
                                        </TextArea>
                                    </FormGroup>
                                </FormContent>

                                <Buttons>
                                    { !respondido
                                        ? 
                                            <button>Responder</button> 
                                        : 
                                            <button disabled style={{cursor: 'not-allowed'}}>Responder</button>
                                    }
                                </Buttons>
                            </Form>
                            {/* <Delete>
                                <IoTrashOutline onClick={() => { handleDelete(id); }} />
                            </Delete> */}
                            <CloseModalButton onClick={() => setShowModal(!showModal)} />
                        </ModalContent>
                    </animated.div>
                </Background>
            ) : null
            }
        </>
    );
}