import React from 'react';
import { Container, Card, CardGroup, CardHeader, CardBody, CardFooter, Options } from './styles';
import { IoArrowRedoSharp, IoBicycle, IoPencil, IoTrash } from 'react-icons/io5';
import { GiFactory } from 'react-icons/gi';
import { Link, useHistory } from 'react-router-dom';
import routeImg from '../../../assets/images/route.png'

export default function CardComponent({ loading, error, repeatLoading, data, list, handleClicked, handleDelete }){
    return(
        <>
            {loading || error ? (
                <Container>
                    {repeatLoading.map(rl => (
                        <Card key={rl}>
                            {list.map(l => (
                                <CardGroup key={l}>
                                    <div></div>
                                    <div></div>
                                </CardGroup>
                            ))}
                            <Options>
                                <div></div>
                                <div></div>
                                <div></div>
                            </Options>
                        </Card>
                    ))}
                </Container>
            ) : (
                <Container>
                    {data.map((d, i) => (
                        <Card key={i}>
                            {/* <CardHeader>
                                {d.modelo}
                            </CardHeader> */}
                            <CardBody>
                            {/* Futuramente, substituir pela logo adicionada no cadastro */}
                            <IoBicycle size={60}/>
                            {list.map((l, i) => (
                                <CardGroup key={i}>
                                    {/* <img src={routeImg} alt="Rota do dia" /> */}
                                    <strong>{l.replace(/_/g, ' ')}:</strong>
                                    <span>{d[l]}</span>
                                </CardGroup>
                            ))}
                            </CardBody>
                            {/* <CardFooter></CardFooter> */}
                            <Options>
                                <IoPencil color="#000068" style={{position: "absolute", right: 25}}
                                    onClick={() => {
                                        handleClicked(d.chassi)
                                    }} 
                                />
                                <IoTrash color="#cc0000" style={{position: "absolute", right: 0}}
                                    onClick={() => {
                                        handleDelete(d.id, d.chassi)
                                    }} 
                                />
                            </Options>
                        </Card>
                    ))}
                </Container>
            )}
        </>
    );
}