import testImg from '../../assets/images/test.jpg'

import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    padding: 0 calc((100vw - 1000px) / 2);
    /* border: 1px solid red; */
    @media screen and (max-width: 768px){
        width: 100vw;   
        padding: 15px; 
    }
`;

export const WapperNews = styled.div`
    display:flex;
    align-items:center;
    flex-direction: column;
`;

export const New = styled.div`
    width:100%;
    /* height: 600px; */
    /* Talvez tirar esse */
    /* min-height: 300px;  */
    min-height: 230px; 
    height: auto;
    background: #fff;
    border-radius: var(--border-radius);
    /* &:hover{
        filter: brightness(99%);
        box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
        transform: translateY(-1px);
    } */
    margin: 10px;
    filter: brightness(99%);
    box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
    transform: translateY(-1px);
    /* border: 1px solid red; */
`;

export const Header = styled.div`
    width:100%;
    background: black;
    display: flex; 
    flex-direction: column;
    color: #fff;
`;

export const AuthorDate = styled.div`
    width: 100%;
    height: 45%;
    background: #fff;
    padding-top: 5px;
    background: var(--dark-alt-color);

`;

export const ProfileGroup = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 5px;
    @media screen and (max-width: 425px){
        width: 75%;
    }
`;

export const ProfileImage = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 5px;

    img{
        height: 100%;
        width: 100%;
    }
`; 

export const HeaderGroup = styled.div`
    display: flex;
    flex-direction: column;
    /* width: 100%; */
`;

export const ProfileName = styled.div`
    font-size: 20px;
    margin-top: 10px;
`;

export const TitleGroup = styled.div`
    width: 100%;
    background: var(--dark-color);
    padding: 20px;
`

export const TitleNew = styled.div`
    text-transform: uppercase;
    color: #fff;
`;

export const DataGroup= styled.div``;


export const Data = styled.div`
    padding-top: 10px;
`;

export const NewContent = styled.div`
    /* padding: 5px; */
    /* p{
        font-size: 1rem;
    } */
`;

export const BodyNew = styled.div`
    width: 100%;
    /* Se for deixar que nem antes, tirar o height e deixar o min-height de 300px no New e voltar o height desse aqui para auto, e também tirar a estilização do p desse aqui */
    /* height: auto; */
    height: 50px;
    padding: 8px;
    /* border: 1px solid green; */
    /* Talvez deixar assim */
    p{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
`;

export const NewImg = styled.div`
    width: 100%;
    height: 25%;
    background-size: cover;
    background-attachment: fixed;
    background: url(${testImg});
`;