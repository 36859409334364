import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0 1rem 0;
    small{
        opacity: 50%;
    }
    @media screen and (max-width: 768px){
        width: 95%;
    }
`;