import React from 'react';
import { Container, CardsContainer, Card } from './styles';
import { IoPeopleOutline, IoBicycleOutline, IoMapOutline, IoBusinessOutline, IoChatboxEllipsesOutline, IoReaderOutline, IoLockClosedOutline, IoWarningOutline, IoNewspaperOutline } from "react-icons/io5";
import { MdForum } from 'react-icons/md';
export default function Admin(){
    return(
        <Container>
            <CardsContainer>
                <Card to="/admin/users">
                    <IoPeopleOutline />
                    <span>Usuários</span>
                </Card>
                <Card to="/admin/bicycles">
                    <IoBicycleOutline />
                    <span>Bicicletas</span>
                </Card>
                <Card to="/admin/messages">
                    <MdForum />
                    <span>Mensagens</span>
                </Card>
                <Card to="/admin/routes">
                    <IoMapOutline />
                    <span>Rotas</span>
                </Card>
                <Card to="/admin/partnerstores">
                    <IoBusinessOutline />
                    <span>Lojas Parceiras</span>
                </Card>
                <Card to="/admin/faq">
                    <IoChatboxEllipsesOutline />
                    <span>FAQ</span>
                </Card>
                <Card to="/admin/termsofuse">
                    <IoReaderOutline />
                    <span>Termos De Uso</span>
                </Card>
                <Card to="/admin/privacypolicy">
                    <IoLockClosedOutline />
                    <span>Políticas</span>
                </Card>
                <Card to="/guard/occurrences">
                    <IoWarningOutline />
                    <span>Ocorrências</span>
                </Card>
                <Card to="/admin/news">
                    <IoNewspaperOutline />
                    <span>Notícias</span>
                </Card>
            </CardsContainer>
        </Container>
    );
}