import React from 'react';
import { Container, SerchArea } from './styles';
import { IoSearch } from "react-icons/io5";

export default function Option({ filters, setFilters, data }){
    return(
        <Container>
            <select
                value={filters.show}
                onChange={e => setFilters({ ...filters, show: e.target.value })}
            >
                {data.map((d, i) => (
                    <option key={i} value={d}>{d}</option>
                ))}
            </select>
        </Container>
    );
}