import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import { Background, ModalContent, CloseModalButton, Form, FormContent, FormGroup, Delete, WapperImages, TitlePhoto, Image, ImageGroup, BodyImage, Options } from './styles';
import UploadImage from '../../../UploadImage';
import InputMask from 'react-input-mask';
import api from '../../../../services/api';
import swal from 'sweetalert';
import { IoTrashOutline } from "react-icons/io5";
import Auth from '../../../../services/auth';
import Switch from "react-switch";
import { ChromePicker } from 'react-color'

export default function ModalEdit({ showModal, setShowModal, handleDelete, id, setUpdeted, rota, setRota }) {
    const modalRef = useRef();
    const [link, setLink] = useState('');
    const [nome, setNome] = useState('');
    const [descricao, setDescricao] = useState('');
    const [distancia, setDistancia] = useState('');
    const [token, setToken] = useState(Auth.token());
    const [checked, setChecked] = useState(false);
    const [cor_hex, setCor_hex] = useState('');
    const animation = useSpring({
        config: {
            duration: 150
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`
    });
    const keyPress = useCallback(e => {
        if (e.key === 'Escape' && showModal) setShowModal(!showModal);
    }, [setShowModal, showModal]);
    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);

    function onlyNumbers(e) {
        const valor = (e.target.validity.valid) ? e.target.value : distancia;
        setDistancia(valor);
    }

    const handleChangeComplete = data => {
        if (data.hex !== cor_hex) {
            setCor_hex(data.hex);
        }
    };

    useEffect(() => {
        if (showModal) {
            setLink(rota.link_strava);
            setNome(rota.nome);
            setDescricao(rota.descricao);
            setDistancia(rota.percurso_total);
            setChecked(rota.destaque_home);
            setCor_hex(rota.cor_hex);
        }
    }, [rota])

    function closeModal() {
        setRota();
        setLink('');
        setNome('');
        setDescricao('');
        setDistancia('');
        setChecked(false);
        setCor_hex('#000000');
    }

    async function handleSubmit(e) {
        e.preventDefault();

        const data = {
            nome: nome,
            link_strava: link,
            descricao: descricao,
            cor: "Strava",
            cor_hex: cor_hex,
            percurso_total: distancia,
            destaque_home: checked,
        }

        try {
            await api.patch(`/admin/routes/${id}?auth=${token}`, { data }).then(response => {
                if (response.data.error) return swal('Atenção!', response.data.error, 'warning')
                swal('Sucesso!', 'Rota atualizada com sucesso!', 'success')
                setTimeout(() => {
                    window.location.reload(false);
                }, 700);
            })
        } catch (error) { swal('Atenção!', `Serviço indisponível no momento!`, 'error') }
    }
    return (
        <>
            {showModal ? (
                <Background ref={modalRef} onClick={(e) => { if (modalRef.current === e.target) { setShowModal(!showModal); closeModal(); }; }}>
                    <animated.div style={animation}>
                        <ModalContent>
                            <Form onSubmit={handleSubmit}>
                                <h1>Editar Rota</h1>
                                <FormContent>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Link do Strava"
                                            value={link}
                                            onChange={e => setLink(e.target.value)}
                                            maxLength='100'
                                            required
                                        />
                                        <label>Link do Strava</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Nome da rota"
                                            value={nome}
                                            onChange={e => setNome(e.target.value)}
                                            maxLength='100'
                                            required
                                        />
                                        <label>Nome da rota</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Distância total em metros"
                                            value={distancia}
                                            pattern="^[0-9]*$"
                                            onChange={e => { onlyNumbers(e); }}
                                            maxLength='250'
                                            required
                                        />
                                        <label>Distância total em metros</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Descrição"
                                            value={descricao}
                                            onChange={e => setDescricao(e.target.value)}
                                            maxLength='250'
                                            required
                                        />
                                        <label>Descrição</label>
                                    </FormGroup>
                                </FormContent>
                                <center>
                                    <p style={{ marginBottom: 10 }}>Escolha a cor da rota</p>
                                    <ChromePicker color={cor_hex} onChange={handleChangeComplete} />
                                </center>
                                <FormGroup>
                                    <p style={{ marginTop: 20, marginBottom: 10 }}>Deseja que essa rota seja destaque na tela home?</p>
                                    <Switch
                                        onChange={() => { setChecked(!checked); }}
                                        checked={checked}
                                    />
                                    <label>Destaque</label>
                                </FormGroup>
                                <button type="submit">Salvar</button>
                            </Form>
                            <CloseModalButton onClick={() => { setShowModal(!showModal); closeModal(); }} />
                        </ModalContent>
                    </animated.div>
                </Background>
            ) : null}
        </>
    );
}