import React, { useCallback, useState, useRef, useEffect, useMemo } from 'react';
import { GoogleMap, Marker, InfoWindow, useLoadScript, DirectionsRenderer } from "@react-google-maps/api"
import { MarkerOptions } from './styles';
import { IoPencilSharp, IoTrashOutline } from 'react-icons/io5';
import mapStyles from '../../../styles/mapStyles';
import Swal from 'sweetalert2';
import uniqid from 'uniqid';
import api from '../../../services/api';

const mapContainerStyle = {
    width: "100vw",
    height: "100vh",
}
const options = {
    disableDefaultUI: false,
    zoomControl: true,
    styles: mapStyles
}
const libraries = ["places"];

export default function Map({ setRoutes, setShowModalEdit, markers, setMarkers, selected, setSelected, setTotalDistance, totalDistance, markersRef, setId_waypoint, directions, setDirections, cor_hexa }) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
        libraries,
    });
    const [center, setCenter] = useState({ lat: -23.09744828257539, lng: -47.228578108868085 })
    const [waypoints, setWaypoints] = useState([]);
    const [loading, setLoading] = useState(true);
    const [drag, setDrag] = useState(false);
    const [limite, setLimite] = useState(false);
    const mapRef = useRef();

    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    const onMapCLick = useCallback((event) => {
        api.post(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${event.latLng.lat()},${event.latLng.lng()}&key=AIzaSyBeKgr6W_5u-F7TwNUFg32eFZLmdlNUzBI`).then((response) => {
            setMarkers((current) => [...current, {
                id: uniqid(),
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
                formatted_address: response.data.results[0].formatted_address
            }]);
        });
    }, []);

    useEffect(() => {
        if (markers.length >= 27) {
            setLimite(true);
            markers.pop();
        }
        if (markers.length === 1 && !drag) {
            markersRef.current.push({
                id: markers[markers.length - 1].id,
                nome: `ponto - ${markersRef.current.length + 1}`,
                descricao: '',
                endereco: markers[markers.length - 1].formatted_address,
                sequencia_itinerario: markersRef.current.length + 1,
                distancia_relativa: 0,
                lat: markers[markers.length - 1].lat,
                lng: markers[markers.length - 1].lng,
                foto: [],
                start_address: markers[markers.length - 1].formatted_address,
                end_address: '',
            });
        }
        if (markers.length >= 2 && !drag) {
            handleCalcTravel(markers[markers.length - 1]);
            handleRoute();
        }
        setDrag(false);
    }, [markers]);

    const handleCalcTravel = useCallback((marker) => {
        if (marker) {
            markersRef.current.push({
                id: marker.id,
                nome: `ponto - ${markersRef.current.length + 1}`,
                descricao: '',
                endereco: marker.formatted_address,
                sequencia_itinerario: markersRef.current.length + 1,
                distancia_relativa: 0,
                lat: marker.lat,
                lng: marker.lng,
                foto: [],
                start_address: marker.formatted_address,
                end_address: '',
            });
        }
    }, [])

    function handleRoute() {
        setWaypoints([]);
        const directionsService = new window.google.maps.DirectionsService();
        // const origin = markers[0];
        // const destination = markers[markers.length - 1];
        // markers.map(mark => {
        //     setWaypoints(current => [...current, { location: new window.google.maps.LatLng(mark.lat, mark.lng), stopover: true }])
        // })
        // waypoints.shift();
        const waypoints = markers.map(p => ({
            location: { lat: p.lat, lng: p.lng },
            stopover: true
        }))
        const origin = waypoints.shift().location;
        const destination = waypoints.pop().location;
        if (waypoints.length >= 1) {
            directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    travelMode: window.google.maps.TravelMode.BICYCLING,
                    unitSystem: window.google.maps.UnitSystem.METRIC,
                    optimizeWaypoints: true,
                    waypoints: waypoints
                }, (response, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        const legs = response.routes[0].legs;
                        markersRef.current[markersRef.current.length - 2].end_address = legs[legs.length - 1].end_address;
                        markersRef.current[markersRef.current.length - 2].distancia_relativa = legs[legs.length - 1].distance.value;
                        var total = 0;
                        for (var i = 0; i < legs.length; i++) {
                            total += legs[i].distance.value;
                        }

                        setTimeout(function () {
                            setLoading(true);
                            setTotalDistance(total);
                            setDirections(response);
                            setRoutes(legs);
                            setLoading(false);
                            return true
                        }, 200);
                    } else {
                        console.error(status);
                    }
                }
            )
        }
        else {
            directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    travelMode: window.google.maps.TravelMode.BICYCLING,
                    unitSystem: window.google.maps.UnitSystem.METRIC,
                    optimizeWaypoints: true,
                }, (response, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        const legs = response.routes[0].legs
                        markersRef.current[markersRef.current.length - 2].end_address = legs[legs.length - 1].end_address;
                        markersRef.current[markersRef.current.length - 2].distancia_relativa = legs[legs.length - 1].distance.value;

                        var total = 0;
                        for (var i = 0; i < legs.length; i++) {
                            total += legs[i].distance.value;
                        }

                        setTimeout(function () {
                            setLoading(true);
                            setTotalDistance(total);
                            setDirections(response);
                            setRoutes(legs);
                            setLoading(false);
                            return true
                        }, 200);
                    } else {
                        console.log(status);
                    }
                }
            )
        }
    }

    const handleDelete = useCallback((selected) => {
        setTotalDistance(totalDistance - selected.distancia_relativa);
        setMarkers(markers.filter(mark => mark.id != selected.id));
        setMarkers(markers.filter(mark => mark.id != selected.id));
        setSelected('');
        setTimeout(function () {
            handleRoute();
        }, 200);
    }, []);

    const handleDrag = (event, index) => {
        setDrag(true);
        let newList = [...markers];
        // newList = markers;
        newList[index].lat = event.latLng.lat();
        newList[index].lng = event.latLng.lng();
        setMarkers(newList);
        markersRef.current[index].endereco = newList[index].formatted_address;
        markersRef.current[index].lat = event.latLng.lat();
        markersRef.current[index].lng = event.latLng.lng();
        if (markers.length > 1) { handleRoute(); }
        setDrag(false);
    }

    if (loadError) return "Error Loading Maps";
    if (!isLoaded) return "Loaging Maps";
    return (
        <div>
            {/* <Search panTo={panTo} /> */}
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={15}
                center={center}
                options={options}
                onClick={limite ? Swal.fire(
                    'Atenção!',
                    'Limite de 26 marcadores por rota!',
                    'warning'
                ) : onMapCLick}
                onLoad={onMapLoad}
                formatted
            >
                {markers.map((marker, index) =>
                    <Marker style={{ visualViewport }} key={uniqid()}
                        options={{
                            title: `${marker.nome}`,
                            draggable: true,
                            preserveViewport: true,
                            markerOptions: {
                                crossOnDrag: true,
                                clickable: true,
                            }
                        }}
                        label={
                            { text: index + 1 + "", color: "white" }
                        }
                        position={{ lat: marker.lat, lng: marker.lng }}
                        onDragEnd={event => { handleDrag(event, index); }}
                    />
                )}
                {selected ?
                    <InfoWindow key={uniqid()} position={{ lat: selected[0].lat, lng: selected[0].lng }} onCloseClick={() => { setSelected('') }}>
                        <div>
                            <MarkerOptions>
                                <IoPencilSharp style={{ width: '20px', height: '20px' }} onClick={() => { setId_waypoint(selected[0].id); setShowModalEdit(true) }} />
                                <IoTrashOutline style={{ width: '20px', height: '20px' }}
                                    onClick={() => {
                                        handleDelete(selected);
                                    }}
                                />
                            </MarkerOptions>
                        </div>
                    </InfoWindow> : null}
                {loading === false ? <DirectionsRenderer
                    key={uniqid()}
                    directions={directions}
                    options={{
                        draggable: false,
                        suppressMarkers: true,
                        suppressInfoWindows: true,
                        preserveViewport: true,
                        polylineOptions: {
                            strokeColor: cor_hexa,
                            strokeWeight: 4
                        },
                        markerOptions: {
                            crossOnDrag: true,
                            clickable: true,
                        }
                    }}
                /> : null}
            </GoogleMap>
        </div >
    );
}