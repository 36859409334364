import styled from 'styled-components';
import { MdClose, MdKeyboardArrowRight } from 'react-icons/md';

export const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 768px){
        width: 100vw;    
    }
`;

export const Title = styled.h1`
    text-transform: uppercase;
    font-size: 2.5rem;
    color: var(--dark-color);
    margin: 1.5rem;
    border-bottom: 0.25rem solid var(--dark-color);
    border-radius: var(--border-radius);
`;

export const Pagination = styled.div`
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    small{
        opacity: 50%;
    }
`;

export const Form = styled.form`
    width: 44%;
    margin-left: 1%;
    /* margin-top: 10%; */
    margin-top: 130px;
    background: #fff;
    text-align: center;
    position: absolute;
    padding: 20px;
    z-index:1000;
    h1{
        padding: 10px;
        margin-bottom: 1rem;
        text-transform: uppercase;
    }   
    @media screen and (max-width: 1130px){
        width: 98%;
    }
`;

export const WapperTimeLine = styled.div`
    width: 100%;
    position: absolute;
    z-index:1000;
`;

export const FormContent = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const FormGroup = styled.div`
    flex: 1 1 20rem;
    margin: 0 0.5rem 1.5rem 0.5rem;
    position: relative;
`;

export const Buttons = styled.div`
    display: flex;
    align-items:center;
    justify-content: space-around;
    margin-top: 5px;
    button{
        border: none;
        @media screen and (max-width: 555px){
            margin-top: 10px;
        }
    }   
    @media screen and (max-width: 555px){
        flex-direction: column;
    }
`;

export const WapperAddress = styled.div`
    display: flex;
    flex-direction: column;
    width:100%;
    padding: 10px;
    h3{
        margin-right:80%;
    }
`;

export const AddressContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
`;

export const DatasRoutes = styled.div`
    display:flex;
    justify-content: space-around;
`;

export const GroupDatasTravel = styled.div`
    flex: 1 1 20rem;
    position: relative;
    margin-bottom: 5px;
    width: 90%;
    align-items: center;
`;


export const WapperDatesTravel = styled.div`
    display: flex;
    justify-content: space-around;
    width:100%;
    align-items: center;
`;
export const DatesTravel = styled.div`
`

export const WapperColors = styled.div`
    display: table;
    text-align: center;
    justify-content: space-around;
    height: 120px;
    width: 100%;
    @media screen and (max-width: 555px){
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const ContainerColors = styled.div`
    cursor: pointer;
    display: table-cell;
    text-align: center;
    align-items: center;
    min-height: 50px;
    width: 15%;
    border-radius: var(--border-radius);
    opacity:0.5;
    color: #fff;
    :hover{
        transition-duration: 0.7s;
        opacity: 1;
    }
`
export const RowColors = styled.div`
    display: table-row;
`;

export const NameColor = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    margin-top: 20%;
    color: black;
`
//table
export const TableWapper = styled.div`
    width: 100%;
    margin-bottom: 10px;
    @media screen and (max-width: 1130px){
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const TableContainer = styled.table`
    width: 100%;
    height:100%;
    margin-left: auto;
    margin-right: auto;
    min-width: 436px;
    max-height: 300px;
    overflow-y: auto;
    border-collapse: collapse;
    border-radius: var(--border-radius);
    overflow: hidden;
    background: #fff;
    box-shadow: var(--box-shadow);
    @media screen and (max-width: 1440px){
        width: 60%;
        min-width: 0;
        height: 60%;
    }
    @media screen and (max-width: 510px){
        width: 80%;
        height: 60%;
        min-width: 0;
    }
`;

export const TableHead = styled.thead`
    background: var(--dark-color);
    color: var(--white-color);
    text-transform: uppercase;
    th{
        padding: 1rem 0;
    }
    @media screen and (max-width: 510px){
        display: none;
    }
`;

export const TableBody = styled.tbody`
    text-align: center;
    tr{
        border-bottom: 1px solid #f4f4f4;
        cursor: pointer;
        will-change: transform;
        transition: all .3s linear;
        @media screen and (max-width: 510px){
            border-bottom: 1px solid #d1d1d1;
        }
        td{
            padding: 1rem 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @media screen and (max-width: 510px){
                display: block;
                width: 100%;
                text-align: right;
                padding-right: 3%;
                text-align: right;
                position: relative;
                &:before{
                    content: attr(data-title);
                    position: absolute;
                    left: 0;
                    width: 6%;
                    padding-left: 15px;
                    font-size: 15px;
                    font-weight: bold;
                    text-transform: uppercase;
                }
            }
        }
        &:hover{
            filter: brightness(90%);
            box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
            transform: translateY(-1px);
            background: var(--white-color);
        }
    }
`;

export const CloseModalButton = styled(MdClose)`
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
`;

export const OpenModalButton = styled(MdKeyboardArrowRight)`
    cursor: pointer;
    position: absolute;
    top: 180px;
    right: 0;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
    background-color: white;
`;