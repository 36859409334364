import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0.5rem', 
    borderWidth: 2,
    borderRadius: 4, 
    borderColor: '#ddd',
    borderStyle: 'dashed',
    backgroundColor: '#f4f5f7',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    height: '300px',
    width: '90%',
    justifyContent: 'center',
    fontSize: '20px',
    marginLeft: '15px',
    marginBottom: '20px',
    textAlign: 'center'
};
const activeStyle = { borderColor: '#2196f3' };
const acceptStyle = { borderColor: '#00e676' };
const rejectStyle = { borderColor: '#ff1744' };

export default function UploadImage({ setFile }) {
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        accept: 'image/*',
        maxFiles: 1,
        onDrop: acceptedFiles => {
            if (acceptedFiles.length === 0 || acceptedFiles.length > 1) return alert('Selecione uma imagem!');
            setFile(acceptedFiles);

        }
    });
    const style = useMemo(() => ({ ...baseStyle, ...(isDragActive ? activeStyle : {}), ...(isDragAccept ? acceptStyle : {}), ...(isDragReject ? rejectStyle : {}) }), [isDragActive, isDragReject, isDragAccept]);
    return (
        <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <small>Arraste a imagem <br/> ou <br/> clique aqui</small>
        </div>
    );
}