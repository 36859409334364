import React, { useState, useEffect } from 'react';
import { Container, Title, Options, SerchArea, Pagination, DateFilter, GroupDate, Wapper, WapperDates, WapperCheckBox, CheckBoxGroup } from './styles';
import Table from './TableMessages';
import ModalResponse from '../../components/Modal/Messages';
import api from '../../services/api';
import ReactRadioGroup from 'react-simple-radio-button'
import { IoSearch, IoCloseSharp } from "react-icons/io5";
import swal from 'sweetalert'
import Auth from "../../services/auth";
import { Logout } from '../../services/logout';

export default function AdminMessages() {
    const [messages, setMessages] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [messageSelected, setMessageSelected] = useState(0);
    const [showMessages, setShowMessages] = useState('TODAS');
    const [search, setSearch] = useState('');
    const [updated, setUpdeted] = useState(false);
    const [page, setPage] = useState(1);
    // Se acrescentar, chamar na query
    // const [optionFilterDate, setOptionFilterDate] = useState({respondida: true, nao_respondida: true});
    const [initial_date, setInitial_date] = useState('');
    const [final_date, setFinal_date] = useState('');
    const [totalPages, setTotalPages] = useState(0);
    const [token, setToken] = useState(Auth.token());

    function handleClicked(id) {
        setShowModal(!showModal);
        setMessageSelected(id);
    }

    function handleDelete(id) {
        try {
            swal({
                title: "Você tem certeza?",
                text: "Uma vez deletado a mensagem não poderá ser respondida",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {
                        await api.delete(`admin/messages/${id}?auth=${token}`);
                        setMessages(messages.filter(message => message.id !== id));
                        swal("Mensagem deletada com sucesso!", {
                            icon: "success",
                        })
                    } else {
                        swal("Mensagem não deletada!")
                    }
                })
        } catch (error) {
            alert('Erro ao deletar a mensagem!');
        }
    }

    function handlePage(action) {
        setPage(action === 'back' ? page - 1 : page + 1);
    }
    useEffect(() => {
        api.get(`admin/messages?&_search=${search}&_initial_date=${initial_date.split('T')[0]}&_final_date=${final_date.split('T')[0]}&_page=${page}&auth=${token}`).then(response => {
            setMessages(response.data.mensagens);
            setTotalPages(response.data.totalPages);
        }).catch(error => {
            console.log(error);
            if (error.response.status === 401) {
                Logout();
            }
        });
    }, [showMessages, search, page, updated, initial_date, final_date]);

    // useEffect(()=>{
    //     console.log(optionFilterDate);
    // },[optionFilterDate])

    return (
        <Container>
            <Title>Mensagens</Title>
            <Options>
                {/* <select
                    value={showMessages}
                    onChange={e => setShowMessages(e.target.value)}
                >
                    <option value="TODAS">Todas</option>
                    <option value="NAO RESPONDIDA">Não respondida</option>
                    <option value="RESPONDIDA">Respondida</option>
                </select> */}
                {/* <WapperCheckBox>
                    <CheckBoxGroup>
                        <input type="checkbox" value={optionFilterDate.respondida} 
                            onClick={e => optionFilterDate.respondida === true ? setOptionFilterDate(optionFilterDate.respondida = false) : ( setOptionFilterDate(optionFilterDate.respondida = true))} />
                        <h4 >{optionFilterDate.respondida}</h4>
                    </CheckBoxGroup> */}
                {/* <CheckBoxGroup>
                        <input type="checkbox" id="optionFilterDate" name="optionFilterDate" value="NAO RESPONDIDA" onClick={e => setOptionFilterDate(e.target.value)} />
                        <h4>Não respondida</h4>
                    </CheckBoxGroup> */}
                {/* </WapperCheckBox> */}
                <Wapper>
                    <WapperDates style={{ marginRight: 10 }}>
                        <DateFilter>
                            <GroupDate>
                                <h3 style={{ marginRight: 17.8 }}>De: </h3>
                                {/* <IoCloseSharp 
                                    onClick={() => setInitial_date('')} 
                                    style={{position: 'absolute', left: 285}}
                                /> */}
                            </GroupDate>
                            <input
                                type="date"
                                placeholder="data"
                                value={initial_date.split('T')[0] ? initial_date.split('T')[0] : initial_date}
                                onChange={e => setInitial_date(e.target.value)}
                            />
                        </DateFilter>
                        <DateFilter>
                            <GroupDate>
                                <h3 style={{ marginRight: 10 }}>Até: </h3>
                                {/* <IoCloseSharp 
                                    onClick={() => setFinal_date('')} 
                                    style={{position: 'absolute', left: 285}}
                                /> */}
                            </GroupDate>
                            <input
                                // style={{ marginRight: 10/*width: '195px'*/ }} 
                                type="date"
                                placeholder="data"
                                value={final_date.split('T')[0] ? final_date.split('T')[0] : final_date}
                                onChange={e => setFinal_date(e.target.value)}
                            />
                        </DateFilter>
                    </WapperDates>

                </Wapper>

                <SerchArea>
                    <input
                        type="search"
                        placeholder="Pesquise pelo nome do rementente"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        minLength="3"
                    />
                    <IoSearch style={{ position: "absolute", left: 6 }} />
                </SerchArea>
            </Options>

            {messages == ''
                ?
                <h1>Não há mensagens disponíveis</h1>
                :
                <>
                    <Table
                        data={messages}
                        handleClicked={handleClicked}
                        handleDelete={handleDelete}
                    />

                    <Pagination>
                        {page <= 1 ? (
                            <button disabled>Anterior</button>
                        ) : (
                            <button onClick={() => handlePage('back')}>Anterior</button>
                        )}
                        <small color="secondary">Página {page} / {totalPages}</small>
                        {page < totalPages ? (
                            <button onClick={() => handlePage('next')}>Próximo</button>
                        ) : (
                            <button disabled>Próximo</button>
                        )}
                    </Pagination>
                </>
            }


            <ModalResponse
                showModal={showModal}
                setShowModal={setShowModal}
                id={messageSelected}
                setUpdeted={setUpdeted}
            />
        </Container>
    );
}