import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    margin-bottom: 1rem;
    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        width: 95%;
    }
`;
export const SerchArea = styled.div`
    width: 100%;
    position: relative;
    svg{
        position: absolute;
        top: 30%;
        left: 1.5%;
    }
`;