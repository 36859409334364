import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import { Background, ModalContent, CloseModalButton, Form, FormContent, FormGroup, Delete, WapperImages, TitlePhoto, Image, ImageGroup, BodyImage, Options } from './styles';
import UploadImage from '../../../UploadImage';
import api from '../../../../services/api';
import InputMask from 'react-input-mask';
import { IoTrashOutline } from "react-icons/io5";
import swal from 'sweetalert';
import { Logout } from '../../../../services/logout';
import Auth from '../../../../services/auth';

export default function ModalEdit({ showModal, setShowModal, id }) {

    const modalRef = useRef();
    const [nome, setNome] = useState('');
    const [cpf_cnpj, setCpf_cnpj] = useState('');
    const [banner, setBanner] = useState([]);
    const [logo, setLogo] = useState([]);
    const [url_banner, setUrl_banner] = useState(null);
    const [url_logo, setUrl_logo] = useState(null);
    const [segmento, setSegmento] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [link_site, setLink_site] = useState('');
    const [haveImage, setHaveImage] = useState(false);
    const [banner_undefined, setBanner_undefined] = useState(false);
    const [logo_undefined, setLogo_undefined] = useState(false);
    const [token, setToken] = useState(Auth.token());
    const [banner_host, setBanner_host] = useState(true);
    const [logo_host, setLogo_host] = useState(true);

    const animation = useSpring({
        config: {
            duration: 150
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`
    });

    const keyPress = useCallback(e => {
        if (e.key === 'Escape' && showModal) setShowModal(!showModal);
    }, [setShowModal, showModal]);

    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);

    useEffect(() => {
        api.get(`partners/${id}?auth=${token}`).then(response => {
            // console.log(response);
            // clerForm();
            setNome(response.data.parceiros[0].nome);
            setCpf_cnpj(response.data.parceiros[0].cpf_cnpj)
            setSegmento(response.data.parceiros[0].segmento);
            setTelefone(response.data.parceiros[0].telefone);
            setEmail(response.data.parceiros[0].email);
            setLink_site(response.data.parceiros[0].link_site);

            if (response.data.cpf_cnpj) {
                let valid = false;
                let aux = response.data.cpf_cnpj.split('.').join('');
                aux = aux.split('-').join('');
                const clearValue = aux.split('/').join('');
                if (clearValue.length === 11) {
                    let fistPart = `${clearValue.slice(0, 3)}.`
                    let secunfPart = `${clearValue.slice(3, 6)}.`
                    let thirdPart = `${clearValue.slice(6, 9)}`
                    let fourthPart = `-${clearValue.slice(9, 12)}`
                    const formatted_cpf_cnpj = `${fistPart}${secunfPart}${thirdPart}${fourthPart}`;
                    setCpf_cnpj(formatted_cpf_cnpj);
                    valid = true
                }
                if ((clearValue.length === 14)) {
                    let fistPart = `${clearValue.slice(0, 2)}.`
                    let secunfPart = `${clearValue.slice(2, 5)}.`
                    let thirdPart = `${clearValue.slice(5, 8)}/`
                    let fourthPart = `${clearValue.slice(8, 12)}`
                    let fifthPart = `-${clearValue.slice(12, 14)}`
                    const formatted_cpf_cnpj = `${fistPart}${secunfPart}${thirdPart}${fourthPart}${fifthPart}`;
                    setCpf_cnpj(formatted_cpf_cnpj);
                    valid = true
                }
            }

            if (response.data.banner == 'null') { setBanner_undefined(true); setUrl_banner(null); }
            else { setUrl_banner(response.data.banner); }
            if (response.data.logo == 'null') { setLogo_undefined(true); setUrl_logo(null); }
            else setUrl_logo(response.data.logo);
        }).catch(error => {
            if (error.response.status === 401) {
                Logout();
            }
        })
    }, [id]);

    useEffect(() => {
        if (banner || logo) {
            setHaveImage(true);
            console.log(haveImage)
        }
        if (banner) {
            banner.map(file => setUrl_banner(URL.createObjectURL(file)));
            console.log(url_banner);
        }
        if (logo) {
            logo.map(file => (setUrl_logo(URL.createObjectURL(file))));
            console.log(logo);
        }
    }, [banner, logo]);
    async function handlemask(value) {
        if (value) {
            let valid = false;
            let aux = value.split('.').join('');
            aux = aux.split('-').join('');
            const clearValue = aux.split('/').join('');
            if (clearValue.length === 11) {
                let fistPart = `${clearValue.slice(0, 3)}.`
                let secunfPart = `${clearValue.slice(3, 6)}.`
                let thirdPart = `${clearValue.slice(6, 9)}`
                let fourthPart = `-${clearValue.slice(9, 12)}`
                const formatted_cpf_cnpj = `${fistPart}${secunfPart}${thirdPart}${fourthPart}`;
                setCpf_cnpj(formatted_cpf_cnpj);
                valid = true
            }
            if ((clearValue.length === 14)) {
                let fistPart = `${clearValue.slice(0, 2)}.`
                let secunfPart = `${clearValue.slice(2, 5)}.`
                let thirdPart = `${clearValue.slice(5, 8)}/`
                let fourthPart = `${clearValue.slice(8, 12)}`
                let fifthPart = `-${clearValue.slice(12, 14)}`
                const formatted_cpf_cnpj = `${fistPart}${secunfPart}${thirdPart}${fourthPart}${fifthPart}`;
                setCpf_cnpj(formatted_cpf_cnpj);
                valid = true
            }
            if (valid == false) {
                swal({
                    text: "Cpf ou cnpj invalido!",
                    icon: "warning",
                })
            }
        }
    }


    async function handleDeleteImage(url, query_params) {
        const data = {
            id: id,
            url: url,
            query_params: query_params,
        };
        swal({
            title: "Você tem certeza?",
            text: "O arquivo sera deletado permanentemente!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    api.post(`admin/partner/deleteimage`, { data }).then(response => {
                        if (response.data.success) {
                            swal(response.data.success, {
                                icon: "success",
                            });
                            if (query_params == 'banner') setUrl_banner(null); setBanner([]);
                            if (query_params == 'logo') setUrl_logo(null); setLogo([]);
                        } else {
                            swal("Erro ao deletar o arquivo!", {
                                icon: "error",
                            });
                        }
                    })
                } else {
                    swal("Cancelado!");
                }
            });
    }


    async function handleDeletePreviewImage(query_params) {

        swal({
            title: "Você tem certeza?",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    if (query_params === 'banner') { setUrl_banner(null); setBanner(null) }
                    if (query_params === 'logo') { setUrl_logo(null); setLogo(null) }
                } else {
                    swal("Cancelado!");
                }
            });

    }


    async function handleDelete() {
        swal({
            title: "Você tem certeza?",
            text: "O arquivo sera deletado permanentemente!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    api.delete(`admin/partners/${id}?auth=${token}`).then(response => {
                        if (response.data.success) {
                            swal(response.data.success, {
                                icon: "success",
                            });
                            window.location.reload();
                        } else {
                            swal("Erro ao deletar o arquivo!", {
                                icon: "error",
                            });
                        }
                    })
                } else {
                    swal("Cancelado!");
                }
            });
    }


    async function handleSubmit(e) {
        e.preventDefault();
        if (cpf_cnpj.length < 11) {
            swal({
                text: "Cpf ou cnpj invalido!",
                icon: "warning",
            })

        } else {
            if (haveImage === true) {

                let aux = cpf_cnpj.split('.').join('');
                aux = aux.split('/').join('');
                const formatted_cpf_cnpj = aux.split('-').join('');
                let formData = new FormData();

                formData.append('id', id);
                formData.append('nome', nome);
                formData.append('cpf_cnpj', formatted_cpf_cnpj);
                formData.append('segmento', segmento);
                formData.append('telefone', telefone);
                formData.append('email', email);
                formData.append('link_site', link_site);
                formData.append('banner', banner_host);
                formData.append('logo', logo_host);
                if (banner.length) banner.forEach(file => formData.append(`${formatted_cpf_cnpj}_banner`, file));
                if (logo.length) logo.forEach(file => formData.append(`${formatted_cpf_cnpj}_logo`, file));

                var data = {};
                var int = 0
                formData.forEach((value, key) => {
                    // data[key] = value != 'null' || value != 'undefined' ? value : undefined
                    // Valor das fotos não está chegando como undefined em si, mas sim como undefined dentro de uma string
                    if (value != 'null' || value != 'undefined' || value != null || value != undefined) {
                        data[key] = value
                        int++
                    }
                });

                try {
                    const response = await api.patch(`admin/partners?auth=${token}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Accept: 'multipart/form-data'
                        }
                    });
                    if (response.data.error) return swal('Atenção', response.data.error, 'warning')
                    swal('Sucesso!', response.data.success, 'success')
                    setTimeout(() => {
                        window.location.reload(false);
                    }, 700);
                } catch (error) { swal('Atenção!', `Serviço indisponível no momento!`, 'error') }
            } else {
                const data = {
                    id: id,
                    nome: nome,
                    cpf_cnpj: cpf_cnpj,
                    segmento: segmento,
                    telefone: telefone,
                    email: email,
                    link_site: link_site,
                    banner: banner_host,
                    logo: logo_host
                }
                try {
                    console.log(data);
                    const response = await api.patch(`admin/partners?auth=${token}`, { data });
                    if (response.data.success) {
                        swal(response.data.success, {
                            icon: "success",
                        }).then(() => {
                            window.location.reload();
                        })

                    } else {
                        swal("Erro ao atualizar os dados!", {
                            icon: "error",
                        });
                    }
                } catch (error) {
                    alert(`Serviço indisponível no momento!`);
                }
            }
        }
    }


    function handleLength(value) {
        let aux = value.split('.').join('');
        aux = aux.split('-').join('');
        const clearValue = aux.split('/').join('');
        if (clearValue.length <= 14) {
            return true
        } else {
            return false
        }
    }


    function clerForm() {

        setNome(null);
        setCpf_cnpj(null);
        setBanner(null);
        setLogo(null);
        setUrl_banner(null);
        setUrl_logo(null);
        setSegmento(null);
        setTelefone(null);
        setEmail(null);
        setLink_site(null);
        setHaveImage(null);
        setBanner_undefined(null);
        setLogo_undefined(null);

    }


    return (
        <>
            {showModal ? (
                <Background ref={modalRef} onClick={(e) => modalRef.current === e.target ? setShowModal(!showModal) : null}>
                    <animated.div style={animation}>
                        <ModalContent>
                            <Form onSubmit={handleSubmit}>
                                <h1>Parceiros</h1>
                                <FormContent>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Nome"
                                            value={nome}
                                            onChange={e => setNome(e.target.value)}
                                            required
                                        />
                                        <label>Nome</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="CPF ou CNPJ"
                                            value={cpf_cnpj}
                                            onChange={e => {
                                                try {
                                                    const response = handleLength(e.target.value)
                                                    if (response === true) {
                                                        setCpf_cnpj(e.target.value);
                                                    } else {
                                                        alert(cpf_cnpj);
                                                    }
                                                } catch (error) {
                                                    alert(error)
                                                }

                                            }}
                                            onFocus={e => {
                                                let aux = e.target.value.split('.').join('');
                                                aux = aux.split('-').join('');
                                                const clearValue = aux.split('/').join('');
                                                setCpf_cnpj(clearValue);
                                            }}
                                            onBlur={e => handlemask(e.target.value)}
                                            minLength="11"
                                            maxLength='14'
                                            disabled
                                        // required
                                        />
                                        <label>Cpf/Cnpj</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Segmento"
                                            value={segmento}
                                            onChange={e => setSegmento(e.target.value)}
                                            min="1"
                                            max="99"
                                            required
                                        />
                                        <label>Seguimento</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputMask
                                            type="text"
                                            placeholder="Telefone"
                                            value={telefone}
                                            onChange={e => setTelefone(e.target.value)}
                                            min="1"
                                            max="14"
                                            mask="(99) 99999-9999"
                                            required
                                        />
                                        <label>Telefone</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            min="1"
                                            max="99"
                                            required
                                        />
                                        <label>Email</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="link do Site"
                                            value={link_site}
                                            onChange={e => setLink_site(e.target.value)}
                                            min="1"
                                            max="99"
                                        />
                                        <label>Link do site</label>
                                    </FormGroup>
                                </FormContent>
                                <WapperImages>
                                    <ImageGroup>
                                        <TitlePhoto>Banner</TitlePhoto>
                                        {banner_host ?
                                            <BodyImage>
                                                <img onError={e => { setBanner_host(false); }} src={`https://acibike.fiecdev.com.br:25555/partnersphoto/${cpf_cnpj.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")}_banner.jpeg?auth=${token}`} alt={'Erro'} />
                                                <Options>
                                                    <IoTrashOutline color="#F00" onClick={() => { swal({ title: "Você tem certeza?", icon: "warning", buttons: true, dangerMode: true, }).then((willDelete) => { if (willDelete) { setBanner_host(false); } }); }} />
                                                </Options>
                                            </BodyImage>
                                            : url_banner ?
                                                <BodyImage>
                                                    <img src={url_banner} alt={'Erro'} />
                                                    <Options>
                                                        <IoTrashOutline color="#F00" onClick={!banner_undefined ? () => { handleDeletePreviewImage('banner') } : (() => { handleDeleteImage(url_banner, 'banner') })} />
                                                    </Options>
                                                </BodyImage>
                                                : (
                                                    <UploadImage amount={1} msg={`(Banner) `} setFile={setBanner} />
                                                )}
                                    </ImageGroup>
                                    <ImageGroup>
                                        <TitlePhoto>Logo</TitlePhoto>
                                        {/* <img src={url_foto_total ? url_foto_total  : defaultImage} data-tooltip="Foto Total da bicicleta" /> */}
                                        {logo_host ?
                                            <BodyImage>
                                                {/* <img src={url_logo} data-tooltip="Logo" /> */}
                                                {/* Mudar de bike para o nome certo que se deve chamar no link */}
                                                <img onError={e => { setLogo_host(false); }} src={`https://acibike.fiecdev.com.br:25555/partnersphoto/${cpf_cnpj.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")}_logo.jpeg?auth=${token}`} alt={'Erro'} />
                                                <Options>
                                                    <IoTrashOutline color="#F00" onClick={() => { swal({ title: "Você tem certeza?", icon: "warning", buttons: true, dangerMode: true, }).then((willDelete) => { if (willDelete) { setLogo_host(false); } }); }} />
                                                </Options>
                                            </BodyImage>
                                            : url_logo ?
                                                <BodyImage>
                                                    <img src={url_logo} alt={'Erro'} />
                                                    <Options>
                                                        <IoTrashOutline color="#F00" onClick={!logo_undefined ? () => { handleDeletePreviewImage('logo') } : (() => { handleDeleteImage(url_logo, 'logo') })} />
                                                    </Options>
                                                </BodyImage>
                                                : (
                                                    <UploadImage amount={1} msg={`(Logo) `} setFile={setLogo} />
                                                )}
                                    </ImageGroup>
                                </WapperImages>
                                <button type="submit">Enviar</button>
                            </Form>
                            {/* <Delete>
                                <IoTrashOutline onClick={() => { handleDelete(id) }} />
                            </Delete> */}
                            <CloseModalButton onClick={() => setShowModal(!showModal)} />
                        </ModalContent>
                    </animated.div>
                </Background>
            ) : null
            }
        </>
    );
}