import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    padding: 4rem calc((100vw - 1000px) / 2) 0 calc((100vw - 1000px) / 2);
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 768px){
        width: 100vw;    
    }
`;

export const Title = styled.h1`
    text-transform: uppercase;
    font-size: 2.5rem;
    color: var(--dark-color);
    margin: 1.5rem;
    border-bottom: 0.25rem solid var(--dark-color);
    border-radius: var(--border-radius);
`;
export const Options = styled.div`
    width: 95%;
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-around;
    #iconAdd{
        width: 30px; 
        height: 30px;
    }
`;

export const ButtonAdd = styled(Link)`
    cursor: pointer;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    font-weight: 600;
    padding: .625rem 1.25rem;
    line-height: 1.5;
    border-radius: var(--border-radius);
    text-transform: uppercase;
    will-change: transform;
    letter-spacing: .025em;
    font-size: .875rem;
    color: #fff;
    border: none;
    background: #b8b8b8;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    transition: all .3s linear;
    &:hover{
        filter: brightness(90%);
        box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
        transform: translateY(-1px);
    }

`;
export const SerchArea = styled.div`
    width: 100%;
    position: relative;
    margin-top: 5px;
    
    input{
       width: 90%;
       margin-left: 10px;
    }
    svg{
        position: absolute;
        top: 30%;
        left: 0.7%;
    }
`;
export const Pagination = styled.div`
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    small{
        opacity: 50%;
    }
`;