import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    padding: 4rem calc((100vw - 1000px) / 2) 0 calc((100vw - 1000px) / 2);
    display: flex;
    align-items: center;
    @media screen and (max-width: 768px){
        width: 100vw;    
    }
`;

export const CardsContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    /* Só se usa o grid-template-ares para localizar elementos quando se cria linhas também, somente colunas já se auto corrige */

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr 1fr;   
    }
`;

export const Card = styled(Link)`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border-radius: var(--border-radius);
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    margin: 0.3rem;
    will-change: transform;
    transition: all .3s linear;
    opacity: 0.7;
    svg{
        font-size: 2rem;
        color: var(--dark-color);
        margin: 0.5rem;
    }
    span{
        margin: 0.5rem;
        color: var(--dark-color);
        text-transform: uppercase;
    }
    &:hover{
        opacity:1;
        filter: brightness(98%);
        box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
        transform: translateY(-1px);
    }
`;