import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Title, Form, FormContent, FormGroup, Buttons, TextArea, SocialMedias, ItemOption } from './styles';
import { IoLogoFacebook, IoLogoInstagram } from "react-icons/io5";
import api from '../../services/api';
import swal from 'sweetalert';
import { NavOptions } from '../../components/Navbar/styles';
import InputMask from 'react-input-mask'

export default function Messages() {

    const [nome, setNome] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [comentario, setComentario] = useState('');
    const [open, setOpen] = useState(false);

    async function handleSubmit(e) {
        e.preventDefault();

        const data = {
            remetente: nome,
            email: email,
            telefone: telefone,
            comentario: comentario,
            data_criada: new Date().toISOString()
        }
        
        try{
            const response = await api.post('/messages', { data });
            if(response.data.error) swal('Atenção!', response.data.error, 'warning')
            swal('Sucesso!', 'Mensagem enviada!', 'success')
            setTimeout(()=>{
                window.location.reload(false);
            }, 500);
        } catch(error) { swal('Atenção', 'Serviço indisponível no momento', 'error') }
    }

    return (
        <Container>
            <Form onSubmit={handleSubmit}>
                <h1>Fale Conosco</h1>
                <FormContent>
                    <FormGroup>
                        <input
                            type="text"
                            placeholder="Nome"
                            value={nome}
                            onChange={e => setNome(e.target.value)}
                            maxLength="70"
                            required
                        />
                        <label>Nome</label>
                    </FormGroup>
                    <FormGroup>
                        <InputMask
                            type="text"
                            mask='(99) 99999-9999'
                            placeholder="Telefone"
                            value={telefone}
                            onChange={e => setTelefone(e.target.value)}
                            maxLength="150"
                            required
                        />
                        <label>Telefone</label>
                    </FormGroup>
                    <FormGroup>
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            minLength="3"
                            maxLength="100"
                            required
                        />
                        <label>email</label>
                    </FormGroup>
                    <TextArea>
                        <textarea
                            type="text"
                            placeholder="Comentario"
                            value={comentario}
                            onChange={e => setComentario(e.target.value)}
                            maxLength="255"
                            style={{padding: 15, resize: 'none'}}
                            required
                        />
                    </TextArea>
                </FormContent>
                <Buttons>
                    <button>Enviar Mensagem</button>
                </Buttons>
            </Form>

            {/* <SocialMedias>
                <ItemOption href="https://www.facebook.com/acindaiatuba/" target="_blank" rel="noreferrer" onClick={() => setOpen(!open)}>
                    <IoLogoFacebook size={50} />
                </ItemOption>
                <ItemOption href="https://www.instagram.com/aci.bike/" target="_blank" rel="noreferrer" onClick={() => setOpen(!open)}>
                    <IoLogoInstagram size={50} />
                </ItemOption>
            </SocialMedias> */}
        </Container>
    );
}