import styled from 'styled-components';

// Conferir se vai ser essa estilização do container mesmo

export const Container = styled.div`
    width: 100%;
    min-height: 87.9vh;
    padding: 4rem calc((100vw - 1000px) / 2) 0 calc((100vw - 1000px) / 2);
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 1050px){
        padding-bottom: 30px;
        padding-left: 30px;
        padding-right: 30px;
    }
    @media screen and (max-width: 768px){
        width: 100vw;    
    }
`;