import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import { Link, useHistory } from 'react-router-dom';
import { Background, ModalContent, CloseModalButton, Form, FormContent, FormGroup } from './styles';
import api from '../../../../services/api';
import swal from 'sweetalert';
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import InputMask from 'react-input-mask'
import Auth from "../../../../services/auth";

export default function ModalRegister({ showModal, setShowModal, handleDelete, id, setUpdeted }) {
    const modalRef = useRef();
    const [email, setEmail] = useState('');
    const [nome, setNome] = useState('');
    const [senha,setSenha] = useState('');
    const [confirmSenha,setConfirmSenha] = useState('');
    const [data_nascimento,setData_nascimento] = useState('');
    const [sexo,setSexo] = useState('');
    const [tipo,setTipo] = useState('CICLISTA');
    const [telefone,setTelefone] = useState('');
    const [token,setToken] = useState(Auth.token());
    
    const animation = useSpring({
        config: {
            duration: 150
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`
    });

    const keyPress = useCallback(e => {
        if (e.key === 'Escape' && showModal) setShowModal(!showModal);
    }, [setShowModal, showModal]);
    
    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);

    const [visible, setVisible] = useState(false);
    const history = useHistory();

    async function handleSubmit(e) {
        e.preventDefault();

        if (senha !== confirmSenha) return swal("Atenção!", "As senhas não estão iguais!", "warning");
        
        try {
            const data = {
                email: email,
                senha: senha,
                confirmSenha: confirmSenha,
                nome: nome,
                data_nascimento: data_nascimento,
                sexo: sexo,
                tipo: tipo,
                telefone: telefone,
            }

            const response = await api.post(`/admin/register?auth=${token}`, { data });
            if (response.data.error) return swal("Atenção", response.data.error, "warning");
            swal("Sucesso!", "Usuário cadastrado!", "success");
            setTimeout(()=>{
                window.location.reload(false);
            }, 500);
        } catch (error) { swal( 'Atenção', 'Serviço indisponível no momento!', 'error') }

    }

    function handleLength(value) {
        let aux = value.split('.').join('');
        aux = aux.split('-').join('');
        const clearValue = aux.split('/').join('');
        if (clearValue.length <= 14) {
            return true
        } else {
            return false
        }
    }
    
    return (
        <>
            {showModal ? (
                <Background ref={modalRef} onClick={(e) => modalRef.current === e.target ? setShowModal(!showModal) : null}>
                    <animated.div style={animation}>
                        <ModalContent>
                            <Form onSubmit={handleSubmit}>
                                <h1>Cadastro de Usuário</h1>
                                <FormContent>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            min="1"
                                            max="99"
                                            pattern="[^'\x22]+"
                                            title='Não é possível preencher com aspas'
                                            required
                                        />
                                        <label>Email</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type={visible ? 'text' : 'password'}
                                            autoComplete="new-password"
                                            placeholder="Senha"
                                            value={senha}
                                            onChange={e => setSenha(e.target.value)}
                                            minLength="8"
                                            maxLength="255"
                                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                            title='Deve conter pelo menos 1 número, 1 letra em maiúsculo e 1 letra em minúsculo, e pelo menos 8 caracteres!'
                                            required
                                        />
                                        <label>Senha</label>
                                        {visible ? (
                                            <IoEyeOutline size={22} color="#000" onClick={() => setVisible(!visible)} />
                                        ) : (
                                            <IoEyeOffOutline size={22} color="#000" onClick={() => setVisible(!visible)} />
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type={visible ? 'text' : 'password'}
                                            autoComplete="new-password"
                                            placeholder="Confirmar Senha"
                                            value={confirmSenha}
                                            onChange={e => setConfirmSenha(e.target.value)}
                                            minLength="8"
                                            maxLength="255"
                                            required
                                        />
                                        <label>Confirmar Senha</label>
                                        {visible ? (
                                            <IoEyeOutline size={22} color="#000" onClick={() => setVisible(!visible)} />
                                        ) : (
                                            <IoEyeOffOutline size={22} color="#000" onClick={() => setVisible(!visible)} />
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Nome"
                                            value={nome}
                                            onChange={e => setNome(e.target.value)}
                                            required
                                        />
                                        <label>Nome</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputMask
                                            type="text"
                                            mask='(99) 99999-9999'
                                            placeholder="Telefone"
                                            value={telefone}
                                            onChange={e => setTelefone(e.target.value)}
                                            maxLength="150"
                                            required
                                        />
                                        <label>Telefone</label>
                                    </FormGroup>
                                    {/* 
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="link do Site"
                                            value={link_site}
                                            onChange={e => setLink_site(e.target.value)}
                                            min="1"
                                            max="99"
                                        />
                                        <label>Link do site</label>
                                    </FormGroup> */}
                                    <FormGroup>
                                        <input
                                            type="date"
                                            placeholder="Data de nascimento"
                                            value={data_nascimento}
                                            onChange={e => setData_nascimento(e.target.value)}
                                            required
                                        />
                                        <label>Data de nascimento</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <select
                                            value={sexo}
                                            onChange={e => setSexo(e.target.value)}
                                        >
                                            <option value="Masculino">Masculino</option>
                                            <option value="Feminino">Feminino</option>
                                        </select>
                                        <label>Sexo</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <select
                                            value={tipo}
                                            onChange={e => setTipo(e.target.value)}
                                            // disabled
                                        >
                                            <option value="CICLISTA">Ciclista</option>
                                            <option value="ADMINISTRADOR">Administrador</option>
                                            <option value="GUARDA">Guarda</option>
                                        </select>
                                        <label>Tipo de usuário</label>
                                    </FormGroup>
                                </FormContent>
                                <button type="submit">Cadastrar</button>
                            </Form>
                            <CloseModalButton onClick={() => setShowModal(!showModal)} />
                        </ModalContent>
                    </animated.div>
                </Background>
            ) : null}
        </>
    );
}