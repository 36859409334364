import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom'
import api from '../../services/api';
import Map from '../../components/Map/Edit';
import Auth from '../../services/auth'
import { Container } from './styles'

export default function EditRoute() {

    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(Auth.token);
    const [markers, setMarkers] = useState([]);
    const [directions, setDirections] = useState(null);
    const [routes, setRoutes] = useState('');
    const [selected, setSelected] = useState('');
    const [totalDistance, setTotalDistance] = useState(0);
    const [id_waypoint, setId_waypoint] = useState([]);
    const markersRef = useRef([]);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [checked, setChecked] = useState(false);
    const [nome, setNome] = useState('');
    const [descricao, setDescricao] = useState('');
    const [cor, setCor] = useState('');
    const [cor_hex, setCor_hex] = useState('');
    const [descricao_ponto, setDescricao_ponto] = useState([]);

    const renameJson = (waypoints) => {
        waypoints.forEach((waypoint, index) => {
            waypoints[index].lat = waypoint.latitude;
            // delete waypoints[index].latitude;
            waypoints[index].lng = waypoint.longitude;
            // delete waypoints[index].longitude;
        });
    }

    useEffect(() => {
        const id = history.location.state
        api.get(`/admin/routes/${id}?auth=${token}`).then(response => {
            // console.log(response.data.route);
            setTotalDistance(response.data.route.routes[0].percurso_total);
            setNome(response.data.route.routes[0].nome);
            setDescricao(response.data.route.routes[0].descricao);
            setCor(response.data.route.routes[0].cor);
            setCor_hex(response.data.route.routes[0].cor_hex);
            renameJson(response.data.route.waypoints)
            setMarkers(response.data.route.waypoints)
            setDirections(JSON.parse(response.data.route.routes[0].directions));
            setChecked(response.data.route.routes[0].destaque_home)
        });
    }, []);

    const handleFindWaypoints = () => {
        const id = history.location.state;
        setMarkers([]);
        setDirections(null);
        api.get(`/admin/routes/${id}?auth=${token}`).then(response => {
            setDirections(JSON.parse(response.data.route.routes[0].directions));
        });
    }
    useEffect(() => {
        if (markers.length > 2) setLoading(false);
    }, [markers]);


    return (
        <Container>
            {loading === false ?
                <Map
                    markers={markers}
                    setMarkers={setMarkers}
                    setRoutes={setRoutes}
                    markersRef={markersRef}
                    showModalEdit={showModalEdit}
                    setShowModalEdit={setShowModalEdit}
                    setSelected={setSelected}
                    selected={selected}
                    setTotalDistance={setTotalDistance}
                    totalDistance={totalDistance}
                    directions={directions}
                    setDirections={setDirections}
                    checked={checked}
                    setChecked={setChecked}
                    nome={nome}
                    setNome={setNome}
                    cor={cor}
                    setCor={setCor}
                    cor_hex={cor_hex}
                    setCor_hex={setCor_hex}
                    descricao={descricao}
                    setDescricao={setDescricao}
                    descricao_ponto={descricao_ponto}
                    setDescricao_ponto={setDescricao_ponto}
                />
                : <h1>LOADING</h1>
            }

        </Container>
    );
}