import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    min-height: 87.9vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p{
        font-size: 52px;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
    }
    @media screen and (max-width: 768px){
        width: 100vw;    
    }
`;