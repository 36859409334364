import React, { useState } from 'react';
import { Container } from './styles';
import Heading from '../../components/Heading';
import CardRoutes from '../../components/CardRoutes';
import NewsCard from '../../components/NewsCard';
import { useHistory } from 'react-router-dom'

import jwt from 'jsonwebtoken';
import jwt_decode from "jwt-decode";

export default function Home() {
    const token = localStorage.getItem('token');
    jwt.verify(token, 'acibike@citec');
    var decoded = jwt_decode(token);
    console.log(`AQUI: ${decoded.type === 'GUARDA'}`);

    return (
        <>
            <Heading />
            <Container>
                <CardRoutes />
                <NewsCard />
            </Container>
        </>
    );
}