import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import { Background, ModalContent, CloseModalButton, Form, FormContent, FormGroup, Delete, WapperImages, TitlePhoto, Image, ImageGroup, BodyImage, Options } from './styles';
import UploadImage from '../../../UploadImage';
import api from '../../../../services/api';
import InputMask from 'react-input-mask';
import { IoTrashOutline } from "react-icons/io5";
import swal from 'sweetalert';
import Auth from '../../../../services/auth';
import Img from '../../../../assets/images/defaultImage.jpg'
import { Logout } from '../../../../services/logout';
import { IoArrowRedoSharp } from 'react-icons/io5';
import { useHistory } from 'react-router';

export default function ModalEdit({ showModal, setShowModal, id }) {
    const history = useHistory();
    const modalRef = useRef();
    const [tipo, setTipo] = useState('');
    const [data_recebimento, setData_recebimento] = useState('');
    const [data_atendimento, setData_atendimento] = useState('');
    const [data_encerramento, setData_encerramento] = useState('');
    const [numero_bo, setNumero_bo] = useState('');
    const [status, setStatus] = useState('');
    const [endereco, setEndereco] = useState('');
    const [descricao, setDescricao] = useState('');
    // const [foto,setFoto] = useState('');
    const [link_site, setLink_site] = useState('');
    const [guarda, setGuarda] = useState('');
    const [observacoes, setObservacoes] = useState('');
    const [resultado, setResultado] = useState('');
    const [token, setToken] = useState(Auth.token());
    const [chassi, setChassi] = useState('');
    const [email, setEmail] = useState('');

    const animation = useSpring({
        config: {
            duration: 150
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`
    });

    const keyPress = useCallback(e => {
        if (e.key === 'Escape' && showModal) setShowModal(!showModal);
    }, [setShowModal, showModal]);

    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);

    useEffect(() => {
        api.get(`guard/occurrences/${id}?auth=${token}`).then(response => {
            // console.log(response);
            // clerForm();
            if (response.data.ocorrencias[0].data_recebimento) {
                const fulldate = response.data.ocorrencias[0].data_recebimento.split('T');
                const formattedDate = fulldate[0];
                setData_recebimento(formattedDate);
            }
            // setData_recebimento(response.data.ocorrencias[0].data_recebimento)
            if (response.data.ocorrencias[0].data_atendimento) {
                const fulldate = response.data.ocorrencias[0].data_atendimento.split('T');
                const formattedDate = fulldate[0];
                setData_atendimento(formattedDate);
            }
            else {
                setData_atendimento('');
            }
            if (response.data.ocorrencias[0].data_encerramento) {
                const fulldate = response.data.ocorrencias[0].data_encerramento.split('T');
                const formattedDate = fulldate[0];
                setData_encerramento(formattedDate);
            }
            else {
                setData_encerramento('');
            }
            setEmail(response.data.ocorrencias[0].email ? response.data.ocorrencias[0].email : '');
            setChassi(response.data.ocorrencias[0].chassi);
            setTipo(response.data.ocorrencias[0].tipo);
            setNumero_bo(response.data.ocorrencias[0].numero_bo);
            setStatus(response.data.ocorrencias[0].status);
            setEndereco(response.data.ocorrencias[0].endereco);
            setDescricao(response.data.ocorrencias[0].descricao);
            setGuarda(response.data.ocorrencias[0].guarda ? response.data.ocorrencias[0].guarda : '');
            setObservacoes(response.data.ocorrencias[0].observacoes ? response.data.ocorrencias[0].observacoes : '');
            setResultado(response.data.ocorrencias[0].resultado ? response.data.ocorrencias[0].resultado : '');
        }).catch(error => {
            if (error.response.status === 401) {
                Logout();
            }
        })
    }, [id]);

    async function handleDelete() {
        swal({
            title: "Você tem certeza?",
            text: "O arquivo sera deletado permanentemente!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    api.delete(`guard/occurrences/${id}?auth=${token}`).then(response => {
                        if (response.data.success) {
                            swal(response.data.success, {
                                icon: "success",
                            });
                            window.location.reload();
                        } else {
                            swal("Erro ao deletar o arquivo!", {
                                icon: "error",
                            });
                        }
                    })
                } else {
                    swal("Cancelado!");
                }
            });
    }


    async function handleSubmit(e) {
        e.preventDefault();

        const data = {
            id: id,
            tipo: tipo,
            data_recebimento: data_recebimento,
            data_atendimento: data_atendimento,
            data_encerramento: data_encerramento,
            numero_bo: numero_bo,
            status: 'ENCERRADO',
            endereco: endereco,
            descricao: descricao,
            guarda: guarda,
            observacoes: observacoes,
            resultado: resultado
        }

        try {
            console.log(data);
            api.patch(`guard/occurrences/${id}?auth=${token}`, { data }).then(response => {
                if (response.data.error) swal('Atenção!', response.data.error, 'warning')
                swal('Sucesso!', response.data.success, 'success')
                setTimeout(() => {
                    window.location.reload(false);
                }, 700);
            })

        } catch (error) {
            alert(error);
        }
    }


    function clerForm() {

        setTipo(null);
        setData_recebimento(null);
        setData_atendimento(null);
        setData_encerramento(null);
        setNumero_bo(null);
        setEndereco(null);
        setDescricao(null);
        setGuarda(null);
        setObservacoes(null);
        setResultado(null);

    }

    return (
        <>
            {showModal ? (
                <Background ref={modalRef} onClick={(e) => modalRef.current === e.target ? setShowModal(!showModal) : null}>
                    <animated.div style={animation}>
                        <ModalContent>
                            <Form onSubmit={handleSubmit}>
                                <h1>Ocorrências</h1>
                                <FormContent>
                                    <FormGroup>
                                        <select
                                            type="text"
                                            placeholder="Tipo"
                                            value={tipo}
                                            onChange={e => setTipo(e.target.value)}
                                            disabled
                                        >
                                            <option value='FURTO'>Furto</option>
                                            <option value='ROUBO'>Roubo</option>
                                            <option value='ROUBO'>Fraude</option>
                                        </select>
                                        <label>Tipo</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="date"
                                            placeholder="Data de Recebimento"
                                            value={data_recebimento}
                                            onChange={e => setData_recebimento(e.target.value)}
                                            disabled
                                        />
                                        <label>Data de Recebimento</label>
                                    </FormGroup>
                                    {tipo === "FRAUDE"
                                        ?
                                        null
                                        :
                                        <FormGroup>
                                            <input
                                                type="text"
                                                placeholder="Número do B.O"
                                                value={numero_bo}
                                                onChange={e => setNumero_bo(e.target.value)}
                                                disabled
                                            />
                                            <label>Número do B.O</label>
                                        </FormGroup>
                                    }
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Chassi"
                                            value={chassi}
                                            disabled
                                        />
                                        <label>Chassi</label>
                                    </FormGroup>
                                    {tipo === "FRAUDE"
                                        ?
                                        null
                                        :
                                        <FormGroup>
                                            <input
                                                type="text"
                                                placeholder="Endereço"
                                                value={endereco}
                                                onChange={e => setEndereco(e.target.value)}
                                                disabled
                                            />
                                            <label>Endereço</label>
                                        </FormGroup>
                                    }
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Descrição"
                                            value={descricao}
                                            onChange={e => setDescricao(e.target.value)}
                                            disabled
                                        />
                                        <label>Descrição</label>
                                    </FormGroup>
                                    {tipo === "FRAUDE"
                                        ?
                                        null
                                        :
                                        <FormGroup>
                                            <input
                                                type="date"
                                                placeholder="Data de Atendimento"
                                                value={data_atendimento}
                                                onChange={e => setData_atendimento(e.target.value)}
                                                required
                                            />
                                            <label>Data de Atendimento</label>
                                        </FormGroup>
                                    }
                                    {data_encerramento
                                        ?
                                        <FormGroup>
                                            <input
                                                type="text"
                                                placeholder="Guarda"
                                                value={guarda}
                                                onChange={e => setGuarda(e.target.value)}
                                                disabled
                                            />
                                            <label>Guarda</label>
                                        </FormGroup>
                                        :
                                        <FormGroup>
                                            <input
                                                type="text"
                                                placeholder="Guarda"
                                                value={guarda}
                                                onChange={e => setGuarda(e.target.value)}
                                            />
                                            <label>Guarda</label>
                                        </FormGroup>
                                    }
                                    <FormGroup>
                                        <input
                                            type="date"
                                            placeholder="Data de Encerramento"
                                            value={data_encerramento}
                                            onChange={e => setData_encerramento(e.target.value)}
                                            disabled
                                        />
                                        <label>Data de Encerramento</label>
                                    </FormGroup>
                                    {data_encerramento
                                        ?
                                        <FormGroup>
                                            <input
                                                type="text"
                                                placeholder="Observações"
                                                value={observacoes}
                                                onChange={e => setObservacoes(e.target.value)}
                                                disabled
                                            />
                                            <label>Observações</label>
                                        </FormGroup>
                                        :
                                        <FormGroup>
                                            <input
                                                type="text"
                                                placeholder="Observações"
                                                value={observacoes}
                                                onChange={e => setObservacoes(e.target.value)}
                                            />
                                            <label>Observações</label>
                                        </FormGroup>
                                    }
                                    {data_encerramento
                                        ?
                                        <FormGroup>
                                            <select
                                                type="text"
                                                placeholder="Status"
                                                value={status}
                                                onChange={e => setStatus(e.target.value)}
                                                disabled
                                            >
                                                <option value='PENDENTE'>Pendente</option>
                                                <option value='ESPERA'>Em Andamento</option>
                                                <option value='ENCERRADO'>Encerrado</option>
                                            </select>
                                            <label>Status</label>
                                        </FormGroup>
                                        :
                                        <FormGroup>
                                            <select
                                                type="text"
                                                placeholder="Status"
                                                value={status}
                                                onChange={e => setStatus(e.target.value)}
                                            >
                                                <option value='PENDENTE'>Pendente</option>
                                                <option value='ESPERA'>Em Andamento</option>
                                                <option value='ENCERRADO'>Encerrado</option>
                                            </select>
                                            <label>Status</label>
                                        </FormGroup>
                                    }
                                    {tipo === "FRAUDE"
                                        ?
                                        <FormGroup>
                                            <input
                                                type="text"
                                                placeholder="Email do usuário"
                                                value={email}
                                                disabled
                                            />
                                            <label>Email do usuário</label>
                                            <IoArrowRedoSharp color="#000068" style={{ position: "absolute", right: 0, margin: 5 }}
                                                onClick={() => {
                                                    history.push(`/admin/users?email=${email}`);
                                                }}
                                            />
                                        </FormGroup>
                                        :
                                        null
                                    }
                                    {/* <FormGroup>
                                        <button type='submit'>Visualizar dados do usuário</button>
                                    </FormGroup> */}
                                    {data_encerramento
                                        ?
                                        <FormGroup>
                                            <input
                                                type="text"
                                                placeholder="Resultado"
                                                value={resultado}
                                                onChange={e => setResultado(e.target.value)}
                                                disabled
                                            />
                                            <label>Resultado</label>
                                        </FormGroup>
                                        :
                                        <FormGroup>
                                            <input
                                                type="text"
                                                placeholder="Resultado"
                                                value={resultado}
                                                onChange={e => setResultado(e.target.value)}
                                            />
                                            <label>Resultado</label>
                                        </FormGroup>
                                    }
                                    {/* Foto */}
                                    {tipo === "FRAUDE"
                                        ?
                                        null
                                        :
                                        <FormGroup>
                                            <img onError={e => { e.currentTarget.src = Img; }} src={`https://acibike.fiecdev.com.br:25555/occurrencesphoto/${id}.jpeg?auth=${token}`} />
                                        </FormGroup>
                                    }
                                </FormContent>
                                {!data_encerramento
                                    ?
                                    <button type='submit'>Encerrar</button>
                                    :
                                    <button disabled style={{ cursor: 'not-allowed' }}>Encerrado</button>
                                }
                            </Form>
                            {/* <Delete>
                                <IoTrashOutline onClick={() => { handleDelete(id) }} />
                            </Delete> */}
                            <CloseModalButton onClick={() => setShowModal(!showModal)} />
                        </ModalContent>
                    </animated.div>
                </Background>
            ) : null
            }
        </>
    );
}