import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 50px;
    background: var(--dark-color);
    display: flex;
    justify-content: space-around;
    @media screen and (max-width: 768px){
        width: 100vw;
        /* Algo está impedindo o footer de fica perfeito! */
        /* É aqui que quando se altera de VW para % que não inclui a barra de rolagem */
    }
`;
export const Column = styled.div`
    justify-content: center;
    display: flex;
    flex-direction: column;
    color: var(--white-color);
    font-size: 0.9rem;
    small{
        font-size: 0.7rem;
    }
    a{
        color: var(--blue-color);
        &:hover{
            text-decoration: underline;
        }
    }
`;
export const Developer = styled.div`
    display: flex;
    align-items: center;
`;
export const Client = styled.div`
    display: flex;
    align-items: center;
`;