import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    /* border: 1px solid black; */
    min-height: 100vh;
    /* Ver se vai deixar o padding assim mesmo, na responsividade fica bom, porém na tela normal fica muito grande o form */
    /* E pensar também se vai deixar as redes sociais ali mesmo, porque seria bom colocar em todos de uma vez, já que vai deixar ali, ou não. Caso não for deixar ali, pensar em como encaixar nessa tela */
    /* padding: 4rem calc((100vw - 1000px) / 2) 0 calc((100vw - 1000px) / 2); */
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 768px){
        width: 100vw;    
    }
`;
export const Form = styled.form`
    width: 60%;
    text-align: center;
    background: #fff;
    margin-top: 15%;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    h1{
        padding: 1rem;
        text-transform: uppercase;
    }   
    @media screen and (max-width: 768px){
        width: 90%;
    }
`;

export const FormContent = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const Buttons = styled.div`
    display: flex;
    align-items:center;
    justify-content: space-around;
    margin-top: 5px;
    padding: 10px;
`;

export const FormGroup = styled.div`
    flex: 1 1 20rem;
    margin: 0 0.5rem 1.5rem 0.5rem;
    position: relative;
    svg{
        position: absolute;
        top: 25%;
        right: 1%;
        cursor: pointer;
    }
    img{
        max-width: 3rem;
        height: auto;
    }
`;

export const TextArea = styled.div`
    display:flex;
    align-items:center;
    justify-content: space-around;
    width: 100%;
    textarea{
        min-width:98%;
        max-width:98%;
        min-height: 100px;
        display: block;
        background: #E6E7E9;
        margin: 0;
        color: var(--font-color);
        font-size: var(--font-size);
        border: none;
        transition: box-shadow 300ms;
        &::placeholder{
            color: #999;
        }
        &:focus{
            outline: none;
            box-shadow: var(--box-shadow);
        }
        &:hover{
            box-shadow: var(--box-shadow);
        }
        &:placeholder-shown + label{
            visibility: hidden;
            z-index: -1;
        }
        &:not(:placeholder-shown) + label,
        &:focus:not(:placeholder-shown) + label{
            visibility: visible;
            z-index: 1;
            opacity: 1;
            transform: translate3d(0, -1.5rem, 0) scale(0.8);
            transition: transform 300ms, visibility 0ms, z-index 0ms;
        }
    }
`;

export const SocialMedias = styled.div`
    width: 50%;
    text-align: center;
    background: var(--dark-color);
    margin-top: 15%;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    h1{
        padding: 1rem;
        text-transform: uppercase;
    }   
`;

export const ItemOption = styled.a`
    font-size: 1.2rem;
    color: var(--white-color);
    /* margin estava 0 .6rem */
    margin: 0 -8.5rem;
    transition: .3s linear;
    line-height: 0;
    &:hover{
        color: var(--blue-color);
    }
    @media screen and (max-width: 768px) {
        margin-top: 20px;
    }
`;