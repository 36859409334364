import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import { Background, ModalContent, CloseModalButton, Form, FormContent, FormGroup, Delete, ImageGroup, WapperImages, TitlePhoto, Options, BodyImage, Buttons } from './styles';
import UploadImage from './UploadImage';
import api from '../../../services/api';
import ModalImage from '../../ModalImage';
import { IoTrashOutline } from "react-icons/io5";
import swal from 'sweetalert';
import Auth from "../../../services/auth";
import jwt from 'jsonwebtoken';
import jwt_decode from "jwt-decode";
import { Logout } from '../../../services/logout';

export default function ModalBicycles({ showModal, setShowModal, id }) {
    const modalRef = useRef();
    const [modelo, setModelo] = useState('');
    const [idUsuario, setIdUsuario] = useState();
    const [ano, setAno] = useState('');
    const [cor, setCor] = useState('');
    const [chassi, setChassi] = useState('');
    const [condicao, setCondicao] = useState('');
    const [acessorios, setAcessorios] = useState('');
    const [detalhes, setDetalhes] = useState('');
    const [data_aquisicao, setData_aquisicao] = useState('');
    const [foto_chassi, setFoto_chassi] = useState();
    const [foto_total, setFoto_total] = useState();
    const [foto_perfil, setFoto_perfil] = useState();
    const [foto_detalhe, setFoto_detalhe] = useState();
    const [url_foto_chassi, setUrl_foto_chassi] = useState(null);
    const [url_foto_total, setUrl_foto_total] = useState(null);
    const [url_foto_perfil, setUrl_foto_perfil] = useState(null);
    const [url_foto_detalhe, setUrl_foto_detalhe] = useState(null);
    const [default_foto_chassi, setDefault_foto_chassi] = useState(null);
    const [default_foto_total, setDefault_foto_total] = useState(null);
    const [default_foto_perfil, setDefault_foto_perfil] = useState(null);
    const [default_foto_detalhe, setDefault_foto_detalhe] = useState(null);
    const [isPreview, setIsPreview] = useState(false);
    const [haveImage, setHaveImage] = useState(false);
    const [imageSelected, SetImageSelected] = useState('');
    const [show_modal_image, setShow_modal_image] = useState(false);
    const [nameFileSelected, setNameFilesSelectd] = useState('');
    const [token, setToken] = useState(Auth.token());
    const [chassi_host, setChassi_host] = useState(true);
    const [total_host, setTotal_host] = useState(true);
    const [perfil_host, setPerfil_host] = useState(true);
    const [detalhe_host, setDetalhe_host] = useState(true);
    const animation = useSpring({
        config: {
            duration: 150
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`
    });
    const keyPress = useCallback(e => {
        if (e.key === 'Escape' && showModal) setShowModal(!showModal);
    }, [setShowModal, showModal]);

    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);

    useEffect(() => {
        const tokenLogin = localStorage.getItem('token');
        jwt.verify(tokenLogin, 'acibike@citec');
        var decoded = jwt_decode(tokenLogin);
        setIdUsuario(decoded.id)
        api.get(`bikes/${idUsuario}/${id}?auth=${token}`).then(response => {
            setModelo(response.data.bikes[0].modelo ? response.data.bikes[0].modelo : '');
            setAno(response.data.bikes[0].ano ? response.data.bikes[0].ano : '');
            // Deixar aqui se por acaso precisar, porém, não existe campo para edição de cor
            // setCor(response.data.bikes[0].cor);
            setChassi(response.data.bikes[0].chassi ? response.data.bikes[0].chassi : '');
            setCondicao(response.data.bikes[0].status ? response.data.bikes[0].status : '');
            setAcessorios(response.data.bikes[0].acessorios ? response.data.bikes[0].acessorios : '');
            setDetalhes(response.data.bikes[0].detalhes ? response.data.bikes[0].detalhes : '');
            // setData_aquisicao(response.data.bikes[0].data_aquisicao);
            if (response.data.bikes[0].data_aquisicao) {
                const fulldate = response.data.bikes[0].data_aquisicao.split('T');
                const formattedDate = fulldate[0];
                setData_aquisicao(formattedDate);
            }
            if (response.data.bikes[0].foto_chassi === 'null') { setUrl_foto_chassi(null); setDefault_foto_chassi(null); }
            else setUrl_foto_chassi(response.data.foto_chassi);
            if (response.data.bikes[0].foto_total === 'null') { setUrl_foto_total(null); setDefault_foto_total(null); }
            else setUrl_foto_total(response.data.foto_total);
            if (response.data.bikes[0].foto_perfil === 'null') { setUrl_foto_perfil(null); setDefault_foto_perfil(null); }
            else setUrl_foto_perfil(response.data.foto_perfil);
            if (response.data.bikes[0].foto_detalhe === 'null') { setUrl_foto_detalhe(null); setDefault_foto_detalhe(null); }
            else setUrl_foto_detalhe(response.data.foto_detalhe);
        }).catch(error => {
            if (error.response.status === 401) {
                Logout();
            }
            // alert(error)
        })
    }, [id]);

    useEffect(() => {
        if (foto_chassi || foto_total || foto_perfil || foto_detalhe) setHaveImage(true); else { setHaveImage(false) };
        if (foto_chassi) foto_chassi.map(file => setUrl_foto_chassi(URL.createObjectURL(file)));
        if (foto_total) foto_total.map(file => setUrl_foto_total(URL.createObjectURL(file)));
        if (foto_perfil) foto_perfil.map(file => setUrl_foto_perfil(URL.createObjectURL(file)));
        if (foto_detalhe) foto_detalhe.map(file => setUrl_foto_detalhe(URL.createObjectURL(file)));
    }, [foto_chassi, foto_total, foto_perfil, foto_detalhe]);

    async function handleDeleteImage(url, query_params, closeModalImage, isPreview) {
        if (isPreview) {
            const data = {
                id: id,
                url: url,
                query_params: query_params,
            };
            swal({
                title: "Você tem certeza?",
                text: "O arquivo será deletado permanentemente!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        api.post(`admin/bicycles`, { data }).then(response => {
                            if (response.data.success) {
                                swal(response.data.success, {
                                    icon: "success",
                                });
                                if (closeModalImage) setShow_modal_image(!show_modal_image);
                                if (query_params == 'foto_chassi') setUrl_foto_chassi(null);
                                if (query_params == 'foto_total') setUrl_foto_total(null);
                                if (query_params == 'foto_perfil') setUrl_foto_perfil(null);
                                if (query_params == 'foto_detalhe') setUrl_foto_detalhe(null);
                            } else {
                                swal("Erro ao deletar o arquivo!", {
                                    icon: "error",
                                });
                            }
                        })
                    } else {
                        swal("Cancelado!");
                    }
                });
        } else {
            handleDeletePreviewImage(query_params, closeModalImage);
        }

    }

    function handleDeletePreviewImage(query_params, closeModalImage) {
        swal({
            title: "Você tem certeza?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    if (closeModalImage) setShow_modal_image(!show_modal_image);
                    if (query_params === 'foto_chassi') setUrl_foto_chassi(null); setFoto_chassi(null);
                    if (query_params === 'foto_total') setUrl_foto_total(null); setFoto_total(null);
                    if (query_params === 'foto_perfil') setUrl_foto_perfil(null); setFoto_perfil(null);
                    if (query_params === 'foto_detalhe') setUrl_foto_detalhe(null); setFoto_detalhe(null);
                }
            });

    }

    async function handleSubmit(e) {
        e.preventDefault();
        let date;
        if (data_aquisicao) {
            const aux = data_aquisicao.split("T");
            date = aux[0];
        }

        if (haveImage === true) {
            let formData = new FormData();
            formData.append('id', id);
            formData.append('modelo', modelo);
            formData.append('ano', ano);
            // formData.append('cor', cor);
            formData.append('chassi', chassi);
            formData.append('status', condicao);
            formData.append('acessorios', acessorios);
            formData.append('data_aquisicao', data_aquisicao);
            formData.append('detalhes', detalhes);
            formData.append('foto_chassi', chassi_host);
            formData.append('foto_total', total_host);
            formData.append('foto_perfil', perfil_host);
            formData.append('foto_detalhe', detalhe_host);
            if (foto_chassi) foto_chassi.forEach(file => formData.append(`${chassi}_chassi`, file));
            if (foto_total) foto_total.forEach(file => formData.append(`${chassi}_total`, file));
            if (foto_perfil) foto_perfil.forEach(file => formData.append(`${chassi}_perfil`, file));
            if (foto_detalhe) foto_detalhe.forEach(file => formData.append(`${chassi}_detalhe`, file));

            var data = {};
            formData.forEach((value, key) => {
                // data[key] = value != 'null' || value != 'undefined' ? value : undefined
                // Valor das fotos não está chegando como undefined em si, mas sim como undefined dentro de uma string
                if (value != 'null' || value != 'undefined' || value != null || value != undefined) {
                    data[key] = value
                }
            });

            try {
                const response = await api.patch(`/bikes?auth=${token}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Accept: 'multipart/form-data'
                    }
                });
                if (response.data.error) return alert(response.data.error);
                if (response.data.success) {
                    swal("Editado com sucesso", response.data.success, "success");
                }
                setTimeout(() => {
                    window.location.reload(false);
                }, 500);
                // setShowModal(!showModal);
            } catch (error) {
                swal('Atenção!', `Serviço indisponível no momento!`, 'error');
            }
        } else {
            const data = {
                id: id,
                modelo: modelo,
                ano: ano,
                cor: cor,
                chassi: chassi,
                status: condicao,
                acessorios: acessorios,
                detalhes: detalhes,
                data_aquisicao: date,
                foto_chassi: chassi_host,
                foto_total: total_host,
                foto_perfil: perfil_host,
                foto_detalhe: detalhe_host,
            }
            try {
                const response = await api.patch(`/bikes?auth=${token}`, { data });
                if (response.data.error) return swal('Atenção!', response.data.error, 'warning');
                swal('Sucesso!', response.data.success, 'success');
                setTimeout(() => {
                    window.location.reload(false);
                }, 500);
                // setShowModal(!showModal);
            } catch (error) {
                swal('Atenção!', `Serviço indisponível no momento!`, 'error');
            }
        }

    }

    function handleViewImage(url_image, name, isPreview) {
        setIsPreview(isPreview);
        SetImageSelected(url_image)
        console.log(imageSelected)
        setNameFilesSelectd(name)
        setShow_modal_image(!show_modal_image);
    }


    return (
        <>
            {showModal ? (
                <Background ref={modalRef} onClick={(e) => modalRef.current === e.target ? setShowModal(!showModal) : null}>
                    <animated.div style={animation}>
                        <ModalContent>
                            <Form onSubmit={handleSubmit}>
                                <h1>Editar</h1>
                                <FormContent>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Modelo"
                                            value={modelo}
                                            onChange={e => setModelo(e.target.value)}
                                            maxLength="150"
                                            required
                                        />
                                        <label>Modelo</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Ano de fabricação"
                                            value={ano}
                                            onChange={e => setAno(e.target.value)}
                                            minLength="3"
                                            maxLength="100"
                                            required
                                        />
                                        <label>Ano</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Chassi"
                                            value={chassi}
                                            onChange={e => setChassi(e.target.value)}
                                            disabled
                                        // required
                                        />
                                        <label>Chassi</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <select
                                            value={condicao}
                                            onChange={e => setCondicao(e.target.value)}
                                        >
                                            <option value="ROUBADA">Roubada</option>
                                            <option value="REGULAR">Regular</option>
                                            <option value="PERDIDA">Perdida</option>
                                        </select>
                                        <label>Status</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Acessorios"
                                            value={acessorios}
                                            onChange={e => setAcessorios(e.target.value)}
                                        // required
                                        />
                                        <label>Acessorios</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="date"
                                            placeholder="Data de aquisição"
                                            value={data_aquisicao}
                                            onChange={e => setData_aquisicao(e.target.value)}
                                            style={{ fontSize: '15px' }}
                                        />
                                        <label>Data de aquisição</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Detalhes"
                                            value={detalhes}
                                            onChange={e => setDetalhes(e.target.value)}
                                            maxLength="60"
                                        />
                                        <label>Detalhes</label>
                                    </FormGroup>
                                </FormContent>
                                <WapperImages>
                                    <ImageGroup>
                                        <TitlePhoto>Foto do chassi</TitlePhoto>
                                        {chassi_host ?
                                            <BodyImage>
                                                <img onError={e => { setChassi_host(false); }} src={`https://acibike.fiecdev.com.br:25555/bikesphoto/${chassi}_chassi.jpeg?auth=${token}`} alt={'Erro'} data-tooltip="Foto do chassi" onClick={default_foto_chassi ? () => handleViewImage(url_foto_chassi, 'foto_chassi', true) : (() => handleViewImage(url_foto_chassi, 'foto_chassi', false))} />
                                                <Options>
                                                    <IoTrashOutline color="#F00" onClick={() => { swal({ title: "Você tem certeza?", icon: "warning", buttons: true, dangerMode: true, }).then((willDelete) => { if (willDelete) { setChassi_host(false); } }); }} />
                                                </Options>
                                            </BodyImage>
                                            :
                                            url_foto_chassi ?
                                                <BodyImage>
                                                    <img src={url_foto_chassi} alt={'Erro'} data-tooltip="Foto do chassi" onClick={default_foto_chassi ? () => handleViewImage(url_foto_chassi, 'foto_chassi', true) : (() => handleViewImage(url_foto_chassi, 'foto_chassi', false))} />
                                                    <Options>
                                                        <IoTrashOutline color="#F00" onClick={!default_foto_chassi ? () => handleDeletePreviewImage('foto_chassi', false) : (() => { handleDeleteImage(url_foto_chassi, 'foto_chassi', false) })} />
                                                    </Options>
                                                </BodyImage>
                                                : (
                                                    <UploadImage amount={1} msg={`(Foto do chassi) `} setFile={setFoto_chassi} />
                                                )}
                                    </ImageGroup>
                                    <ImageGroup>
                                        <TitlePhoto>Foto Total</TitlePhoto>
                                        {total_host ?
                                            <BodyImage>
                                                <img onError={e => { setTotal_host(false); }} src={`https://acibike.fiecdev.com.br:25555/bikesphoto/${chassi}_total.jpeg?auth=${token}`} alt={'Erro'} data-tooltip="Foto Total da bicicleta" onClick={default_foto_total ? () => handleViewImage(url_foto_total, 'foto_total', true) : (() => handleViewImage(url_foto_total, 'foto_total', false))} />
                                                <Options>
                                                    <IoTrashOutline color="#F00" onClick={() => { swal({ title: "Você tem certeza?", icon: "warning", buttons: true, dangerMode: true, }).then((willDelete) => { if (willDelete) { setTotal_host(false); } }); }} />
                                                </Options>
                                            </BodyImage>
                                            : url_foto_total ?
                                                <BodyImage>
                                                    <img src={url_foto_total} alt={'Erro'} data-tooltip="Foto Total da bicicleta" onClick={default_foto_total ? () => handleViewImage(url_foto_total, 'foto_total', true) : (() => handleViewImage(url_foto_total, 'foto_total', false))} />
                                                    <Options>
                                                        <IoTrashOutline color="#F00" onClick={!default_foto_total ? () => handleDeletePreviewImage('foto_total', false) : (() => { handleDeleteImage(url_foto_total, 'foto_total', false) })} />
                                                    </Options>
                                                </BodyImage>
                                                : (

                                                    <UploadImage amount={1} msg={`(Foto Total) `} setFile={setFoto_total} />
                                                )}
                                    </ImageGroup>
                                    <ImageGroup>
                                        <TitlePhoto> Foto de perfil</TitlePhoto>
                                        {perfil_host ?
                                            <BodyImage>
                                                <img onError={e => { setPerfil_host(false); }} src={`https://acibike.fiecdev.com.br:25555/bikesphoto/${chassi}_perfil.jpeg?auth=${token}`} alt={'Erro'} data-tooltip="Foto de perfil da bicicleta" onClick={default_foto_perfil ? () => handleViewImage(url_foto_perfil, 'foto_perfil', true) : (() => handleViewImage(url_foto_perfil, 'foto_perfil', false))} />
                                                <Options>
                                                    <IoTrashOutline color="#F00" onClick={() => { swal({ title: "Você tem certeza?", icon: "warning", buttons: true, dangerMode: true, }).then((willDelete) => { if (willDelete) { setPerfil_host(false); } }); }} />
                                                </Options>
                                            </BodyImage>
                                            : url_foto_perfil ?
                                                <BodyImage>
                                                    <img src={url_foto_perfil} alt={'Erro'} data-tooltip="Foto de perfil da bicicleta" onClick={default_foto_perfil ? () => handleViewImage(url_foto_perfil, 'foto_perfil', true) : (() => handleViewImage(url_foto_perfil, 'foto_perfil', false))} />
                                                    <Options>
                                                        <IoTrashOutline color="#F00" onClick={!default_foto_perfil ? () => handleDeletePreviewImage('foto_perfil', false) : (() => { handleDeleteImage(url_foto_perfil, 'foto_perfil', false) })} />
                                                    </Options>
                                                </BodyImage>
                                                : (
                                                    <UploadImage amount={1} msg={`(Foto de perfil) `} setFile={setFoto_perfil} />
                                                )}
                                    </ImageGroup>
                                    <ImageGroup>
                                        <TitlePhoto>Foto dos Detalhes</TitlePhoto>
                                        {detalhe_host ?
                                            <BodyImage>
                                                <img onError={e => { setDetalhe_host(false); }} src={`https://acibike.fiecdev.com.br:25555/bikesphoto/${chassi}_detalhe.jpeg?auth=${token}`} alt={'Erro'} data-tooltip="Foto dos Detalhes da bicicleta" onClick={default_foto_detalhe ? () => handleViewImage(url_foto_detalhe, 'foto_detalhe', true) : (() => handleViewImage(url_foto_detalhe, 'foto_detalhe', false))} />
                                                <Options>
                                                    <IoTrashOutline color="#F00" onClick={() => { swal({ title: "Você tem certeza?", icon: "warning", buttons: true, dangerMode: true, }).then((willDelete) => { if (willDelete) { setDetalhe_host(false); } }); }} />
                                                </Options>
                                            </BodyImage>
                                            : url_foto_detalhe ?
                                                <BodyImage>
                                                    <img src={url_foto_detalhe} alt={'Erro'} data-tooltip="Foto dos Detalhes da bicicleta" onClick={default_foto_detalhe ? () => handleViewImage(url_foto_detalhe, 'foto_detalhe', true) : (() => handleViewImage(url_foto_detalhe, 'foto_detalhe', false))} />
                                                    <Options>
                                                        <IoTrashOutline color="#F00" onClick={!default_foto_detalhe ? () => handleDeletePreviewImage('foto_detalhe', false) : (() => { handleDeleteImage(url_foto_detalhe, 'foto_detalhe', false) })} />
                                                    </Options>
                                                </BodyImage>
                                                : (
                                                    <UploadImage amount={1} msg={`(Foto dos detalhes) `} setFile={setFoto_detalhe} />
                                                )}
                                    </ImageGroup>
                                </WapperImages>
                                <Buttons>
                                    <button>Salvar</button>
                                </Buttons>
                                <ModalImage
                                    showModal={show_modal_image}
                                    setShowModal={setShow_modal_image}
                                    image={imageSelected}
                                    handleDeleteImage={handleDeleteImage}
                                    handleDeletePreviewImage={handleDeletePreviewImage}
                                    isPreview={isPreview}
                                    nameFile={nameFileSelected}
                                />
                            </Form>
                            {/* <Delete>
                                <IoTrashOutline onClick={() => { handleDelete(id); setShowModal(!showModal) }} />
                            </Delete> */}
                            <CloseModalButton onClick={() => {
                                setShowModal(!showModal);
                                setChassi_host(true);
                                setTotal_host(true);
                                setPerfil_host(true);
                                setDetalhe_host(true);
                            }} />

                        </ModalContent>
                    </animated.div>
                </Background>

            ) : null}
        </>
    );
}