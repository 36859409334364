import styled from 'styled-components'

export const Button = styled.button`
    width: 120px;
    height: 100px;
    background-color: transparent;
    border: transparent;
    left: 21px;
    /* margin-bottom: 650px; */
    top: 20px;
    position: absolute;
    p{
        font-size: 0.9rem;
    }
    @media screen and (max-width: 768px){
        /* width estava 100 e height estava 80 */
        /* Única tela que está ficando feio é a de cadastro */
        width: 80px;
        height: 60px;
        border: 1px solid white;
        border-radius: 13px;
        /* background-color: ; */
        p{
            /* font-size: 0.6rem;
            font-family: Verdana, Geneva, Tahoma, sans-serif; */
            font-size: 0;
        }
    }
`;