import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    /* border: 1px solid black; */
    /* min-height: 67.9vh; */
    min-height: 50vh;
    /* padding: 4rem calc((100vw - 1000px) / 2) 0 calc((100vw - 1000px) / 2); */
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 768px){
        width: 100vw;    
    }
`;

export const Faqs =  styled.div`
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
    padding: 15px;
    /* background-color: #0d0d3a; */
    border-radius: 10px;
    /* border: 1px solid black; */
    /* margin-top: 10px; */
    /* justify-content: center; */
    /* align-items: center; */
`;

export const Faq = styled.div`
    height: 100px;
    padding: 15px;
    background-color: white;
    border-radius: 10px;  
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    /* border: solid 2px black;   */
`;

export const FaqQuestion = styled.div`
    position: relative;
    font-size: 20px;
    font-weight: bold;
    padding-right: 80px;

    /* transition: all 0.4s ease; */
    /* &:after{
        content: '';
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;

        background-image: url('./iconmonstr-arrow-65.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        transition: all 0.4s ease;
    } */
`;

export const FaqAnswer = styled.div`
    font-size: 18px;
    /* opacity: 0; */
    /* max-height: 0;
    overflow-y: hidden;
    transition: all 0.4s ease; */
`;