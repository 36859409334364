import React from 'react';
import { Container, Title, Text } from './styles';

export default function About(){
    return(
        <Container>
            <Title>Sobre Nós</Title>
            <Text>Donec rutrum congue leo eget malesuada. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Donec sollicitudin molestie malesuada. Sed porttitor lectus nibh. Donec rutrum congue leo eget malesuada.</Text>
        </Container>
    );
}