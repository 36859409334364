import jwt from 'jsonwebtoken';
import jwt_decode from "jwt-decode";

const Auth = {
    token: function() {
        try{
            const token = localStorage.getItem('token');
            jwt.verify(token, 'acibike@citec');
            var decoded = jwt_decode(token);
            return decoded.auth
        } catch(error){
            return false; 
        }
    },
    id: function(){
        try{
            const token = localStorage.getItem('token');
            jwt.verify(token, 'acibike@citec');
            var decoded = jwt_decode(token);
            return decoded.id
        } catch(error){
            return false; 
        }
    }
}

export default Auth;