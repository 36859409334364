import React, { useEffect, useState } from 'react';
import { Head, Wrapper, Card, CardHeader, CardBody, CardFooter, CardGroup } from './styles';
import routeImg from '../../assets/images/route.png';
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import api from '../../services/api';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom'
import { Logout } from '../../services/logout';
import Auth from "../../services/auth";
import Strava from '../../../src/assets/images/logo_strava.png'

export default function CardRoutes({ handleClicked }) {

    const history = useHistory();
    const [routes, setRoutes] = useState([]);
    const [token, setToken] = useState(Auth.token());
    // const [routeSelected, setRouteSelected] = useState([]);

    useEffect(() => {
        api.get(`/routesdestaque?auth=${token}`).then(response => {
            if (response.error) swal(response.error);
            else {
                setRoutes(response.data.routes);
            }
        }).catch(error => {
            if (error.response.status === 401) {
                Logout();
            }
        })
    }, [])

    function handleClicked(id) {
        history.push({
            pathname: `/routes/${id}`,
            state: id
        })
    }

    return (
        <>
            <Head>
                <h1>Rotas em Destaque</h1>
                {/* <p>Listas de rotas disponiveis para você!</p> */}
            </Head>
            <Wrapper>
                {routes.map(route => (
                    <Card key={route}>
                        <CardHeader>
                            <h1><span>{route.nome}</span></h1>
                        </CardHeader>
                        <CardBody>
                            <img src={routeImg} alt="Rota do dia" />
                            <div>
                                <CardGroup>
                                    {route.waypoints[0]
                                        ?
                                        <>
                                            <strong>Ponto de Partida:</strong>
                                            <span>{route.waypoints[0].endereco}</span>
                                        </>
                                        :
                                        <>
                                            <img style={{ justifySelf: "center" }} src={Strava} />
                                        </>

                                    }
                                </CardGroup>
                                <CardGroup>
                                    {route.waypoints[0]
                                        ?
                                        <>
                                            <strong>Ponto de Chegada:</strong>
                                            <span>{route.waypoints[route.waypoints.length - 1].endereco}</span>
                                        </>
                                        :
                                        <>
                                            <button style={{ backgroundColor: "#FF8C00", border: "none" }} onClick={() => {
                                                Swal.fire({
                                                    title: 'Descrição da Rota',
                                                    text: `${route.descricao}`,
                                                    showCloseButton: true,
                                                    showConfirmButton: false
                                                })
                                            }}>Descrição</button>
                                        </>
                                    }
                                </CardGroup>
                            </div>
                        </CardBody>
                        <CardFooter
                            onClick={() => {
                                if (route.waypoints[0]) {
                                    handleClicked(route.id);
                                } else if (route.link_strava.startsWith("http")) {
                                    window.open(route.link_strava, "_blank");
                                } else {
                                    window.open(`http://${route.link_strava}`, "_blank");
                                }
                            }}
                        >
                            <small></small>
                            <button>
                                Acessar
                                <IoArrowForwardCircleOutline />
                            </button>
                        </CardFooter>
                    </Card>
                ))}
            </Wrapper>
        </>
    );
}