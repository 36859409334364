import React from 'react';
import GlobalStyle from './styles/global';
import Routes from './routes';

export default function App(){
  return(
    <>
      <Routes />
      <GlobalStyle />
    </>
  );
}