import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import { Background, ModalContent, CloseModalButton, Form, FormContent, FormGroup} from './styles';
import api from '../../../../services/api';
import { IoTrashOutline } from "react-icons/io5";
import swal from 'sweetalert';
import Auth from '../../../../services/auth';

export default function ModalEdit({ showModal, setShowModal, id }) {
    
    const modalRef = useRef();
    const [pergunta, setPergunta] = useState('');
    const [resposta, setResposta] = useState('');
    const [token, setToken] = useState(Auth.token())

    const animation = useSpring({
        config: {
            duration: 150
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`
    });

    const keyPress = useCallback(e => {
        if (e.key === 'Escape' && showModal) setShowModal(!showModal);
    }, [setShowModal, showModal]);

    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);

    useEffect(() => {
        api.get(`/admin/faq/${id}?auth=${token}`).then(response => {
            setPergunta(response.data.faq[0].pergunta);
            setResposta(response.data.faq[0].resposta);
        })
    }, [id]);

    async function handleSubmit(e) {
        e.preventDefault();

        const data = {
            pergunta: pergunta,
            resposta: resposta
        }

        try {
            const response = await api.patch(`/admin/faq/${id}?auth=${token}`, { data });
            if(response.data.error) return swal('Atenção', response.data.error, 'warning')
            swal('Sucesso!', response.data.success, 'success')
            setTimeout(()=>{
                window.location.reload(false);
            }, 700);
        } catch (error) { swal('Atenção!', `Serviço indisponível no momento!`, 'error') }
    }

    return (
        <>
            {showModal ? (
                <Background ref={modalRef} onClick={(e) => modalRef.current === e.target ? setShowModal(!showModal) : null}>
                    <animated.div style={animation}>
                        <ModalContent>
                            <Form onSubmit={handleSubmit}>
                                <h1>Editar</h1>
                                <FormContent>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Pergunta"
                                            value={pergunta}
                                            onChange={e => setPergunta(e.target.value)}
                                            maxLength='100'
                                            required
                                        />
                                        <label>Pergunta</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Resposta"
                                            value={resposta}
                                            onChange={e => setResposta(e.target.value)}
                                            maxLength='250'
                                            required
                                        />
                                        <label>Resposta</label>
                                    </FormGroup>
                                </FormContent>
                                <button type="submit">Enviar</button>
                            </Form>
                            {/* <Delete>
                                <IoTrashOutline onClick={() => { handleDelete(id) }} />
                            </Delete> */}
                            <CloseModalButton onClick={() => setShowModal(!showModal)} />
                        </ModalContent>
                    </animated.div>
                </Background>
            ) : null}
        </>
    );
}