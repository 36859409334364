import styled from 'styled-components';
import headerImg from '../../assets/images/header.jpg';

export const Header = styled.header`
    padding-top: 4rem;
    width: 100%;
    height: auto;
    background: url(${headerImg});
    background-size: cover;
    background-attachment: fixed;
    text-align: center;
    overflow: hidden;
    border-radius: 0 0 85% 85% / 30%;
    @media screen and (max-width: 768px){
        border-radius: 0 0 85% 85% / 10%;
        width: 100vw;
    }
`;
export const Overlay = styled.div`
    width: 100%;
    height: 100%;
    padding: 3.125rem;
    color: #fff;
    text-shadow: 1px 1px 1px #333;
    background-image: linear-gradient(135deg, rgba(7, 13, 31, 0.5) 50%, rgba(54, 100, 244, 0.5) 100%);
`;
export const Title = styled.h1`
    font-size: 5rem;
    margin-bottom: 1.875rem;
    @media screen and (max-width: 768px){
        font-size: 3.5rem;
        margin-bottom: 1rem;
    }
`;
export const SubTitle = styled.h3`
    margin-bottom: 1.875rem;
    @media screen and (max-width: 768px){
        margin-bottom: 1rem;
    }
`;
export const Text = styled.p`
    margin-bottom: 1.875rem;
    @media screen and (max-width: 768px){
        margin-bottom: 1rem;
    }
`;