import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import { Background, ModalContent, CloseModalButton, Form, FormContent, FormGroup } from './styles';
import api from '../../services/api'
import swal from 'sweetalert';
import InputMask from 'react-input-mask'
import Auth from "../../services/auth";
import { Logout } from '../../services/logout';

export default function ModalEdit({ showModal, setShowModal, id }) {

    const [email, setEmail] = useState('')
    const [nome, setNome] = useState('')
    const [tipo, setTipo] = useState('')
    const [sexo, setSexo] = useState('')
    const [data_nascimento, setData_nascimento] = useState('')
    const [tipo_sanguineo, setTipo_sanguineo] = useState('')
    const [doenca, setDoenca] = useState('')
    const [medicacao, setMedicacao] = useState('')
    const [alergias, setAlergias] = useState('')
    const [reacoes_medicamentos, setReacoes_medicamentos] = useState('')
    const [convenio, setConvenio] = useState('')
    const [nome_contato_emergencia, setNome_contato_emergencia] = useState('')
    const [parentesco_contato_emergencia, setParentesco_contato_emergencia] = useState('')
    const [telefone_contato_emergencia, setTelefone_contato_emergencia] = useState('')
    const [telefone, setTelefone] = useState('')
    const [token, setToken] = useState(Auth.token())
    const modalRef = useRef();
    const animation = useSpring({
        config: { duration: 150 },
        opacity: showModal.edit ? 1 : 0,
        transform: showModal.edit ? `translateY(0%)` : `translateY(-100%)`
    });

    const keyPress = useCallback(e => {
        if (e.key === 'Escape' && showModal.edit) setShowModal({ ...showModal, edit: !showModal.edit });
    }, [setShowModal, showModal]);

    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);

    // Por algum motivo, os dados do último usuário clicado no botão de editar, passam para o próximo usuário quando se clica em editar, porém só os dados de acréscimo, os dados como NOME, EMAIL e etc, continuam o mesmo
    // Uma solução pode ser essas condições ternárias. O campo muda pra vazio, mas em um milésimo de segundo os dados do usuário anterior ainda aparecem
    useEffect(() => {
        api.get(`admin/users/${id}?auth=${token}`).then(response => {
            setEmail(response.data.users[0].email)
            setNome(response.data.users[0].nome + ' ' + response.data.users[0].sobrenome)
            setTipo(response.data.users[0].tipo)
            setSexo(response.data.users[0].sexo)
            if (response.data.users[0].data_nascimento) {
                const fulldate = response.data.users[0].data_nascimento.split('T');
                const formattedDate = fulldate[0];
                setData_nascimento(formattedDate);
            }
            setTelefone(response.data.users[0].telefone ? response.data.users[0].telefone : '')
            setTipo_sanguineo(response.data.users[0].tipo_sanguineo ? response.data.users[0].tipo_sanguineo : '')
            setDoenca(response.data.users[0].doenca ? response.data.users[0].doenca : '')
            setMedicacao(response.data.users[0].medicacao ? response.data.users[0].medicacao : '')
            setAlergias(response.data.users[0].alergias ? response.data.users[0].alergias : '')
            setReacoes_medicamentos(response.data.users[0].reacoes_medicamentos ? response.data.users[0].reacoes_medicamentos : '')
            setConvenio(response.data.users[0].convenio ? response.data.users[0].convenio : '')
            setNome_contato_emergencia(response.data.users[0].nome_contato_emergencia ? response.data.users[0].nome_contato_emergencia : '')
            setParentesco_contato_emergencia(response.data.users[0].parentesco_contato_emergencia ? response.data.users[0].parentesco_contato_emergencia : '')
            setTelefone_contato_emergencia(response.data.users[0].telefone_contato_emergencia ? response.data.users[0].telefone_contato_emergencia : '')
        }).catch(error => {
            if (error.response.status === 401) {
                Logout();
            }
            // alert(error + "mensagem nele")
        })
    }, [id])

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            const data = {
                email: email,
                nome: nome,
                tipo: tipo,
                sexo: sexo,
                data_nascimento: data_nascimento,
                tipo_sanguineo: tipo_sanguineo,
                doenca: doenca,
                medicacao: medicacao,
                alergias: alergias,
                reacoes_medicamentos: reacoes_medicamentos,
                convenio: convenio,
                nome_contato_emergencia: nome_contato_emergencia,
                parentesco_contato_emergencia: parentesco_contato_emergencia,
                telefone_contato_emergencia: telefone_contato_emergencia,
                telefone: telefone
            }
            const response = await api.patch(`admin/update/${id}?auth=${token}`, { data });
            if (response.data.error) return swal("Atenção", response.data.error, "warning");
            swal("Atenção", response.data.success, "success");
            setTimeout(() => {
                window.location.reload(false);
            }, 700);
        } catch (error) { swal("Atenção", 'Serviço indisponível no momento', "error") }
    }

    return (
        <>
            {showModal.edit ? (
                <Background ref={modalRef} onClick={(e) => modalRef.current === e.target ? setShowModal({ ...showModal, edit: !showModal.edit }) : null}>
                    <animated.div style={animation}>
                        <ModalContent>
                            <Form onSubmit={handleSubmit}>
                                <h1>Editar Dados</h1>
                                <FormContent>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            maxLength="150"
                                            pattern="[^'\x22]+"
                                            title='Não é possível preencher com aspas'
                                            required
                                        />
                                        <label>Email</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Nome"
                                            value={nome}
                                            onChange={e => setNome(e.target.value)}
                                            maxLength="150"
                                            required
                                        />
                                        <label>Nome</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <select
                                            value={tipo}
                                            onChange={e => setTipo(e.target.value)}
                                            required
                                        >
                                            <option value="CICLISTA">Ciclista</option>
                                            <option value="ADMINISTRADOR">Administrador</option>
                                            <option value="GUARDA">Guarda</option>
                                        </select>
                                        <label>Tipo</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <select
                                            value={sexo}
                                            onChange={e => setSexo(e.target.value)}
                                            required
                                        >
                                            <option value='Masculino'>Masculino</option>
                                            <option value='Feminino'>Feminino</option>
                                        </select>
                                        <label>Sexo</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="date"
                                            placeholder="Data Nascimento"
                                            value={data_nascimento}
                                            onChange={e => setData_nascimento(e.target.value)}
                                            style={{ fontSize: '15px' }}
                                            required
                                        />
                                        <label>Data Nascimento</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <select
                                            value={tipo_sanguineo}
                                            onChange={e => setTipo_sanguineo(e.target.value)}
                                            required
                                        >
                                            <option value="N/I">Não informado</option>
                                            <option value='A+'>A+</option>
                                            <option value='B+'>B+</option>
                                            <option value='AB+'>AB+</option>
                                            <option value='O+'>O+</option>
                                            <option value='A-'>A-</option>
                                            <option value='B-'>B-</option>
                                            <option value='AB-'>AB-</option>
                                            <option value='O-'>O-</option>
                                        </select>
                                        <label>Tipo Sanguíneo</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputMask
                                            type="text"
                                            mask='(99) 99999-9999'
                                            placeholder="Telefone"
                                            value={telefone}
                                            onChange={e => setTelefone(e.target.value)}
                                            maxLength="150"
                                            required
                                        />
                                        <label>Telefone</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Doença"
                                            value={doenca}
                                            onChange={e => setDoenca(e.target.value)}
                                            maxLength="150"
                                        />
                                        <label>Doença</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Medicação"
                                            value={medicacao}
                                            onChange={e => setMedicacao(e.target.value)}
                                            maxLength="150"
                                        />
                                        <label>Medicação</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Alergias"
                                            value={alergias}
                                            onChange={e => setAlergias(e.target.value)}
                                            maxLength="150"
                                        />
                                        <label>Alergias</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Reações à Medicamentos"
                                            value={reacoes_medicamentos}
                                            onChange={e => setReacoes_medicamentos(e.target.value)}
                                            maxLength="150"
                                        />
                                        <label>Reações à Medicamentos</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Convênio"
                                            value={convenio}
                                            onChange={e => setConvenio(e.target.value)}
                                            maxLength="150"
                                        />
                                        <label>Convênio</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Nome para Contato/Emergência"
                                            value={nome_contato_emergencia}
                                            onChange={e => setNome_contato_emergencia(e.target.value)}
                                            maxLength="150"
                                        />
                                        <label>Nome para Contato/Emergência</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Parentesco para Contato/Emergência"
                                            value={parentesco_contato_emergencia}
                                            onChange={e => setParentesco_contato_emergencia(e.target.value)}
                                            maxLength="150"
                                        />
                                        <label>Parentesco para Contato/Emergência</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputMask
                                            type="text"
                                            mask='(99) 99999-9999'
                                            placeholder="Telefone para Contato/Emergência"
                                            value={telefone_contato_emergencia}
                                            onChange={e => setTelefone_contato_emergencia(e.target.value)}
                                            maxLength="150"
                                        />
                                        <label>Telefone para Contato/Emergência</label>
                                    </FormGroup>
                                </FormContent>
                                <button>Editar</button>
                            </Form>
                            <CloseModalButton
                                onClick={() => {
                                    setShowModal({
                                        ...showModal, edit: !showModal.edit
                                        //  Se não achar solução, faça um set de todos os campos para '' aqui
                                    })
                                }}
                            />
                        </ModalContent>
                    </animated.div>
                </Background>
            ) : null}
        </>
    );
}