import styled from 'styled-components';

export const Container = styled.div`
    width: 80%;
    margin-left: 10%;
    min-height: 88vh;
    /* padding: 4rem calc((100vw - 1000px) / 2) 0 calc((100vw - 1000px) / 2); */
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 768px){
        width: 100vw;    
    }
`;
export const Title = styled.h1`
    text-transform: uppercase;
    font-size: 2.5rem;
    color: var(--dark-color);
    margin: 1.5rem;
    margin-top: 10%;
    border-bottom: 0.25rem solid var(--dark-color);
    border-radius: var(--border-radius);
`;
export const Options = styled.div`
    width: 95%;
    display: flex;
    margin-bottom: 1rem;
    select{
        margin-right: 0.5rem;
    }
`;
export const SerchArea = styled.div`
    width: 100%;
    position: relative;
    input{
       margin-left: 0.5rem; 
    }
    svg{
        position: absolute;
        top: 30%;
        left: 1%;
    }
`;
export const Pagination = styled.div`
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    small{
        opacity: 50%;
    }
`;


//table
export const TableContainer = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-radius: var(--border-radius);
    overflow: hidden;
    background: #fff;
    box-shadow: var(--box-shadow);
    @media screen and (max-width: 1050px){
        display: block;
        width: 90%;
    }
`;
export const TableHead = styled.thead`
    background: var(--dark-color);
    color: var(--white-color);
    text-transform: uppercase;
    th{
        padding: 1rem 0;
    }
    @media screen and (max-width: 1050px){
        display: none;
    }
`;
export const TableBody = styled.tbody`
    text-align: center;
    tr{
        border-bottom: 1px solid #f4f4f4;
        cursor: pointer;
        will-change: transform;
        transition: all .3s linear;
        @media screen and (max-width: 1050px){
            border-bottom: 1px solid #d1d1d1;
        }
        td{
            padding: 1rem 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @media screen and (max-width: 1050px){
                display: block;
                width: 100%;
                text-align: right;
                padding-right: 3%;
                text-align: right;
                position: relative;
                &:before{
                    content: attr(data-title);
                    position: absolute;
                    left: 0;
                    width: 6%;
                    padding-left: 15px;
                    font-size: 15px;
                    font-weight: bold;
                    text-transform: uppercase;
                }
            }
        }
        &:hover{
            filter: brightness(90%);
            box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
            transform: translateY(-1px);
            background: var(--white-color);
        }
        @media screen and (max-width: 1050px){
            display: block;
            width: 100%;
            margin-bottom: 15px;
        }
    }
    @media screen and (max-width: 1050px){
        display: block;
        width: 100%;
    }
`;