import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
export const Title = styled.h1`
    font-size: 2.5rem;
    color: var(--dark-color);
    margin-bottom: 1.5rem;
    border-bottom: 0.25rem solid var(--dark-color);
    border-radius: var(--border-radius);
`;
export const Text = styled.p`
    width: 75%;
    text-align: justify;
    @media screen and (max-width: 768px){
        width: 90%; 
    }
`;