import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Title, Options, SerchArea, Pagination, ButtonAdd } from './styles';
import api from '../../services/api';
import Table from '../../components/Tables/Faq';
import ModalRegister from '../../components/Modal/Faq/Register';
import ModalEdit from '../../components/Modal/Faq/Edit';
import { IoSearch, IoAddOutline } from "react-icons/io5";
import swal from 'sweetalert'
import Auth from '../../services/auth';

export default function Partners() {
    const [questions, setQuestions] = useState([]);
    const [totalPages, setTotalPages] = useState('');
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [segmento, setSegmento] = useState({ show: 'TODOS', search: '' });
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [showModalRegister, setShowModalRegister] = useState(false);
    const [partnerSelected, setPartnerSelected] = useState('');
    const [token, setToken] = useState(Auth.token())
    const history = useHistory();

    function handlePage(action) {
        setPage(action === 'back' ? page - 1 : page + 1);
    }

    useEffect(() => {
        api.get(`/faq?_search=${search}&_page=${page}&auth=${token}`).then(response => {
            setQuestions(response.data.faq);
            setTotalPages(response.data.totalPages);
        }).catch(error => {
            console.log(error);
        });
    }, [search, page]);

    function handleClickedEdit(id) {
        setShowModalEdit(!showModalEdit);
        setPartnerSelected(id);
    }

    async function handleDelete(id) {
        try {
            swal({
                title: "Você tem certeza?",
                text: "O FAQ selecionado será deletado permanentemente",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {
                        await api.delete(`admin/faq/${id}?auth=${token}`)
                        /**
                         * Mantém na lista os IDS que não foram excluídos, e exclui somente o que foi clicado e enviado via json
                         */
                        setQuestions(questions.filter(question => question.id !== id));
                        swal("FAQ deletado com sucesso!", {
                            icon: "success",
                        })
                    } else {
                        swal("FAQ não deletado!")
                    }
                })
        } catch (error) { swal('Atenção!', 'Serviço indisponível no momento', 'error'); }
    }

    return (
        <Container>
            <Title>FAQ</Title>
            <Options>
                <ButtonAdd
                    onClick={() => {
                        setShowModalRegister(!showModalRegister)
                    }}
                >
                    <IoAddOutline
                        id="iconAdd"
                        size={23}
                    />
                </ButtonAdd>
                {/* <button type="button" >Adicionar</button> */}
                <SerchArea>
                    <input
                        type="search"
                        placeholder="Pesquise pela mensagem"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        minLength="3"
                    />
                    <IoSearch style={{ position: "absolute", left: 6 }} />
                </SerchArea>
            </Options>
            {questions == ''
                ?
                <h1>Aperte no botão + para adicionar faqs</h1>
                :
                <>
                    <Table
                        data={questions}
                        list={['pergunta', 'resposta']}
                        handleClicked={handleClickedEdit}
                        handleDelete={handleDelete}
                    />

                    <Pagination>
                        {page <= 1 ? (
                            <button disabled>Anterior</button>
                        ) : (
                            <button onClick={() => handlePage('back')}>Anterior</button>
                        )}
                        <small color="secondary">Página {page} / {totalPages}</small>
                        {page < totalPages ? (
                            <button onClick={() => handlePage('next')}>Próximo</button>
                        ) : (
                            <button disabled>Próximo</button>
                        )}
                    </Pagination>
                </>
            }
            <ModalEdit
                showModal={showModalEdit}
                setShowModal={setShowModalEdit}
                id={partnerSelected}
            />
            <ModalRegister
                showModal={showModalRegister}
                setShowModal={setShowModalRegister}
                id={partnerSelected}
            />
        </Container>
    );
}