import styled from 'styled-components'

{/* Acrescentar uma div para ficar o Grid Css, que não seja o Container, para facilitar */ }
export const Container = styled.div`
    /* border: 1px solid red; */
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'i d';
    width: 100%;
    min-height: 100vh;
    padding: 5rem calc((100vw - 1000px) / 2) 0 calc((100vw - 1000px) / 2);
    padding-bottom: 10px;
    /* display: flex; */
    /* flex-direction: column;
    align-items: center; */
    @media screen and (max-width: 1024px){
        grid-template-rows: 1fr 1fr;
        grid-template-areas: 'i i'
                             'd d';
        justify-items: center;
    }
    @media screen and (max-width: 768px){
        padding: 5rem calc((100vw - 1000px) / 2) 0 calc((100vw - 1000px) / 2);
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        width: 100vw;   
    }
`;

export const ImageContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 880px;
    /* border: 1px solid #070D1F; */
    border-top-left-radius: 10px;
    box-shadow: 10px 5px 5px #888;
    background-color: #3A4052;
    border-bottom-left-radius: 10px;
    /* border-radius: 10px; */
    width: 335px;
    grid-area: i;
    @media screen and (max-width: 1024px){
        width: 87%;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0;
    }
`;

export const Image = styled.div`
    display: grid;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: 30%;
    border-bottom: 1px solid white;
`;

export const ImagePhoto = styled.div`
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    img{
        width: 200px;
        height: 200px;
        border-radius: 50%;
    }
    @media screen and (max-width: 1024px){
        width: 29%;
        margin-top: 0px;
        height: 90%;
    }
    @media screen and (max-width: 768px){
        width: 190px;
        margin-top: 10px;
        height: 190px;
    }
`;

export const ImageData = styled.div`
    display: grid;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70%;
    padding-top: 120px;
    label{
        color: white;
    }
    h3{
        font-weight: bold;
        color: white;
        padding-bottom: 20px;
    }
`;

export const DataContent = styled.div`
    /* display: grid; */
    /* justify-items: center; */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 650px;
    box-shadow: 10px 5px 5px #888;
    background-color: white;
    height: 880px;
    /* border: 1px solid #070D1F; */
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    /* border-radius: 10px; */
    grid-area: d;
    padding-top: 70px;
    h3{
        font-weight: bold;
        padding-bottom: 30px;
    }
    @media screen and (max-width: 1024px){
        width: 87%;
        border-top-right-radius: 0;
        border-bottom-left-radius: 10px;
    }
`;

export const FormGroup = styled.div`
    position: relative;
    margin-bottom: 1.5rem;
    svg{
        position: absolute;
        top: 25%;
        right: 1%;
        cursor: pointer;
    }
    input{
        width: 300px;
    }
    select{
        width: 300px;
    }
    @media screen and (max-width: 417px){
        input{
            width: 100%;
        }   
        select{
            width: 100%;
        }   
    }
`;

export const Column = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
`;

export const BodyImage = styled.div`
    height:100%;
    width:100%;
    /* border: solid #f4f5f7 1px; */
    /* border: 1px solid red; */
    border-radius: 50%;
`;

export const Input = styled.input`
    @media screen and (max-width: 768px){
        width: 90%;
    }
`;