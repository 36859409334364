import React, { useState, useEffect } from 'react';
import { Container, ButtonAdd, Title } from './styles'
import { IoAddOutline } from 'react-icons/io5';
import ModalPdf from '../../components/ModalPdf';

export default function TermsOfUse() {
    const [showModalImage, setShowModalImage] = useState(false);

    return (
        <Container>
            <Title>Termos de Uso</Title>
            <ButtonAdd style={{ marginBottom: 25 }} onClick={() => {
                setShowModalImage(!showModalImage);
            }}>
                <IoAddOutline id="iconAdd" size={23} />
            </ButtonAdd>
            {/* <div style={{maxHeight: 100}}>
                <embed src="https://acibike.fiecdev.com.br:25555/termos" type="application/pdf"></embed>
            </div> */}
            <object data="https://acibike.fiecdev.com.br:25555/termos" type="application/pdf">
                <p>PDF não suportado!<a href="https://acibike.fiecdev.com.br:25555/termos">Clique aqui para baixar.</a></p>
            </object>
            <ModalPdf
                // showModal={showModal}
                // setShowModal={setShowModal}
                showModal={showModalImage}
                setShowModal={setShowModalImage}
                type={"termos_uso"}
            // list={['foto']}
            // data={userSelected}
            // id={userSelected}
            />
        </Container>
    )
}