import React, { useEffect, useState } from 'react';
import { Container, New, WapperNews, Header, AuthorDate, TitleGroup, TitleNew, ProfileGroup, ProfileImage, HeaderGroup, ProfileName, Data, BodyNew, NewContent, NewImg } from './styles';
import Heading from '../../components/Heading';
import profileImg from '../../assets/images/profile.png'
import api from '../../services/api';
import swal from 'sweetalert'
import Auth from '../../services/auth';
import { useHistory } from 'react-router-dom';
import { Logout } from '../../services/logout';

export default function Index() {

    const history = useHistory();
    const [news, setNews] = useState([]);
    const [token, setToken] = useState(Auth.token())

    useEffect(() => {
        api.get(`/news?auth=${token}`).then(response => {
            setNews(response.data.artigos)
        }).catch(error => {
            // swal('Atenção!', error, 'error')
            if (error.response.status === 401) {
                Logout();
            }
        })
    }, [])

    function handleClicked(id) {
        history.push({
            pathname: `/news/${id}`,
            state: id
        })
    }

    return (
        <>
            <Heading />
            <Container>
                {news.map((n, i) => (
                    <>
                        <WapperNews>
                            <New key={i}>
                                <Header>
                                    <AuthorDate>
                                        <ProfileGroup>
                                            <ProfileImage>
                                                {/* <img src={profileImg}/> */}
                                                <img onError={e => { e.currentTarget.src = profileImg; }} src={`https://acibike.fiecdev.com.br:25555/newsphoto/${n.id}_logo.jpeg` || profileImg} alt={'Erro'} />
                                            </ProfileImage>
                                            <HeaderGroup>
                                                <ProfileName>
                                                    {n.autor}
                                                </ProfileName>
                                                {/* <Data>
                                                <p>14 de Jan. 2021</p>      
                                            </Data> */}
                                            </HeaderGroup>
                                        </ProfileGroup>
                                    </AuthorDate>
                                    <TitleGroup>
                                        <TitleNew>
                                            {n.titulo}
                                        </TitleNew>
                                    </TitleGroup>
                                </Header>
                                <BodyNew>
                                    {/* <NewImg /> Ainda não será usado. Quando arrumarmos a adição, edição e exclusão de imagens, aí sim poderá ser usado*/}
                                    {/* Está perfeito, o único problema é que não fica com os parágrafos do jeito que seria pra ficar. Portanto, arrumar isso */}
                                    <p>{n.conteudo}</p>
                                    <button
                                        style={{ position: "absolute", right: 10, top: 10 }}
                                        onClick={() => {
                                            handleClicked(n.id)
                                        }}
                                    >
                                        acessar
                                    </button>
                                </BodyNew>
                            </New>
                        </WapperNews>
                    </>
                ))}
            </Container>
        </>
    );
}