import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    min-height: 65vh;
    padding: 4rem calc((100vw - 1000px) / 2) 0 calc((100vw - 1000px) / 2);
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 768px){
        width: 100vw;    
    }
`;

export const Head = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    h1{
        font-size: 2.5rem;
        color: var(--dark-color);
        margin-bottom: 0.5rem;
        border-bottom: 0.25rem solid var(--dark-color);
        border-radius: var(--border-radius);
    }
    p{
        color: #737380;
    }
`;