import React from 'react';
import { Container } from './styles'

export default function TermsOfUse(){
    return(
        <Container>
            <object data="https://acibike.fiecdev.com.br:25555/politica" type="application/pdf">
                <p>PDF não suportado!<a href="https://acibike.fiecdev.com.br:25555/politica">Clique aqui para baixar.</a></p>
            </object>
        </Container>
    )
}