import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import { Background, ModalContent, CloseModalButton, Form, FormContent, FormGroup, Delete, WapperImages, TitlePhoto, Image, ImageGroup, BodyImage, Options } from './styles';
import UploadImage from '../../../UploadImage';
import InputMask from 'react-input-mask';
import api from '../../../../services/api';
import swal from 'sweetalert';
import { IoTrashOutline } from "react-icons/io5";
import Auth from '../../../../services/auth';

export default function ModalRegister({ showModal, setShowModal, handleDelete, id, setUpdeted }) {
    const modalRef = useRef();
    const [nome, setNome] = useState('');
    const [cpf_cnpj, setCpf_cnpj] = useState('');
    const [banner, setBanner] = useState('');
    const [logo, setLogo] = useState('');
    const [segmento, setSegmento] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [link_site, setLink_site] = useState('');
    const [haveImage, setHaveImage] = useState(false);
    const [url_banner, setUrl_banner] = useState(null);
    const [url_logo, setUrl_logo] = useState(null);
    const [token, setToken] = useState(Auth.token())
    const animation = useSpring({
        config: {
            duration: 150
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`
    });
    const keyPress = useCallback(e => {
        if (e.key === 'Escape' && showModal) setShowModal(!showModal);
    }, [setShowModal, showModal]);
    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);

    useEffect(() => {
        if (banner) {
            banner.map(file => setUrl_banner(URL.createObjectURL(file)));
            setHaveImage(true);
        }
        if (logo) {
            logo.map(file => (setUrl_logo(URL.createObjectURL(file))));
            setHaveImage(true);
        }
    }, [banner, logo]);

    async function handleSubmit(e) {
        e.preventDefault();

        if (cpf_cnpj.length < 11) {
            swal({
                text: "Cpf ou cnpj invalido!",
                icon: "warning",
            })

        }
        else {
            var cpf_cnpj_formatado = cpf_cnpj.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
            let aux = cpf_cnpj.split('.').join('');
            aux = aux.split('/').join('');
            const formatted_cpf_cnpj = aux.split('-').join('');
            if (haveImage === true) {
                let formData = new FormData();
                formData.append('nome', nome);
                formData.append('cpf_cnpj', formatted_cpf_cnpj);
                formData.append('segmento', segmento);
                formData.append('telefone', telefone);
                formData.append('email', email);
                formData.append('link_site', link_site);
                if (banner) banner.forEach(file => formData.append(`${cpf_cnpj_formatado}_banner`, file));
                if (logo) logo.forEach(file => formData.append(`${cpf_cnpj_formatado}_logo`, file));

                var data = {};
                var int = 0
                formData.forEach((value, key) => {
                    // data[key] = value != 'null' || value != 'undefined' ? value : undefined
                    // Valor das fotos não está chegando como undefined em si, mas sim como undefined dentro de uma string
                    if (value != 'null' || value != 'undefined' || value != null || value != undefined) {
                        data[key] = value
                        int++
                    }
                });

                try {
                    await api.post(`/admin/partners?auth=${token}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Accept: 'multipart/form-data'
                        }
                    }).then(response => {
                        if (response.data.error) return swal('Atenção!', response.data.error, 'warning')
                        swal('Sucesso!', 'Parceiro cadastrado', 'success')
                        setTimeout(() => {
                            window.location.reload(false);
                        }, 700);
                    })
                } catch (error) { swal('Atenção!', `Serviço indisponível no momento!`, 'error') }
            }
            else {
                const data = {
                    id: id,
                    nome: nome,
                    cpf_cnpj: formatted_cpf_cnpj,
                    segmento: segmento,
                    telefone: telefone,
                    email: email,
                    link_site: link_site,
                    banner: banner,
                }
                try {
                    const response = await api.post(`/admin/partners?auth=${token}`, { data });
                    if (response.data.error) return swal('Atenção', response.data.error, 'warning')
                    swal('Sucesso', 'Parceiro cadastrado', 'success')
                    // setUpdeted(true);
                    setTimeout(() => {
                        window.location.reload(false);
                    }, 700);
                } catch (error) {
                    // alert(error)
                    swal('Atenção', `Serviço indisponível no momento!`, 'error')
                }
            }
        }

    }

    async function handlemask(value) {
        if (value) {
            let valid = false;
            let aux = value.split('.').join('');
            aux = aux.split('-').join('');
            const clearValue = aux.split('/').join('');
            if (clearValue.length === 11) {
                let fistPart = `${clearValue.slice(0, 3)}.`
                let secunfPart = `${clearValue.slice(3, 6)}.`
                let thirdPart = `${clearValue.slice(6, 9)}`
                let fourthPart = `-${clearValue.slice(9, 12)}`
                const formatted_cpf_cnpj = `${fistPart}${secunfPart}${thirdPart}${fourthPart}`;
                setCpf_cnpj(formatted_cpf_cnpj);
                valid = true
            }
            if ((clearValue.length === 14)) {
                let fistPart = `${clearValue.slice(0, 2)}.`
                let secunfPart = `${clearValue.slice(2, 5)}.`
                let thirdPart = `${clearValue.slice(5, 8)}/`
                let fourthPart = `${clearValue.slice(8, 12)}`
                let fifthPart = `-${clearValue.slice(12, 14)}`
                const formatted_cpf_cnpj = `${fistPart}${secunfPart}${thirdPart}${fourthPart}${fifthPart}`;
                setCpf_cnpj(formatted_cpf_cnpj);
                valid = true
            }
            if (valid == false) {
                swal({
                    text: "Cpf ou cnpj invalido!",
                    icon: "warning",
                })
            }

            //     else if(clearValue.length <= 10) setMask('999.999.999-99');
            //     if(clearValue.length === 14) setMask('999.999.999-99');
            //     else if(clearValue.length >= 12 && formatted_cpf_cnpj.length < 14) setMask('999.999.999-9');
            // }else{
            //     setMask('');
            // }
        }
    }

    function handleDeleteImage(element) {
        swal({
            text: "Tem certeza?",
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                if (element === 'banner') {
                    setUrl_banner(null);
                    swal({
                        text: "Deletado com sucesso",
                        icon: 'success',
                    });
                } else {
                    if (element === 'logo') {
                        setUrl_logo(null);
                        swal({
                            text: "Deletado com sucesso",
                            icon: 'success',
                        });
                    }
                }


            }
        })
    }
    function handleLength(value) {
        let aux = value.split('.').join('');
        aux = aux.split('-').join('');
        const clearValue = aux.split('/').join('');
        if (clearValue.length <= 14) {
            return true
        } else {
            return false
        }
    }
    return (
        <>
            {showModal ? (
                <Background ref={modalRef} onClick={(e) => modalRef.current === e.target ? setShowModal(!showModal) : null}>
                    <animated.div style={animation}>
                        <ModalContent>
                            <Form onSubmit={handleSubmit}>
                                <h1>Parceiros</h1>
                                <FormContent>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Nome"
                                            value={nome}
                                            onChange={e => setNome(e.target.value)}
                                            required
                                        />
                                        <label>Nome</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="CPF ou CNPJ"
                                            value={cpf_cnpj}
                                            onChange={e => {
                                                try {
                                                    const response = handleLength(e.target.value)
                                                    if (response === true) {
                                                        setCpf_cnpj(e.target.value);
                                                    } else {
                                                        alert(cpf_cnpj);
                                                    }
                                                } catch (error) {
                                                    alert(error)
                                                }

                                            }}
                                            onFocus={e => {
                                                let aux = e.target.value.split('.').join('');
                                                aux = aux.split('-').join('');
                                                const clearValue = aux.split('/').join('');
                                                setCpf_cnpj(clearValue);
                                            }}
                                            onBlur={e => handlemask(e.target.value)}
                                            minLength="11"
                                            maxLength='14'
                                            required
                                        />
                                        <label>cpf ou cnpj</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Segmento"
                                            value={segmento}
                                            onChange={e => setSegmento(e.target.value)}
                                            min="1"
                                            max="99"
                                            required
                                        />
                                        <label>Seguimento</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputMask
                                            type="text"
                                            placeholder="Telefone"
                                            value={telefone}
                                            onChange={e => setTelefone(e.target.value)}
                                            min="1"
                                            max="99"
                                            mask="(99) 99999-9999"
                                            required
                                        />
                                        <label>Telefone</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            min="1"
                                            max="99"
                                            required
                                        />
                                        <label>Email</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="link do Site"
                                            value={link_site}
                                            onChange={e => setLink_site(e.target.value)}
                                            min="1"
                                            max="99"
                                        />
                                        <label>Link do site</label>
                                    </FormGroup>
                                </FormContent>
                                <WapperImages>
                                    <ImageGroup>
                                        <TitlePhoto>Banner</TitlePhoto>
                                        {!url_banner ? <Image data-tooltip={banner.length === 0 ? 'Nenhuma imagem carregada!' : 'Imagem carregada com sucesso!'}>
                                            <UploadImage amount={1} msg={`(Banner) `} setFile={setBanner} />
                                        </Image> : (
                                            <BodyImage>
                                                <img src={url_banner} data-tooltip="banner" />
                                                <Options>
                                                    <IoTrashOutline color="#F00" onClick={() => { handleDeleteImage('banner') }} />
                                                </Options>
                                            </BodyImage>
                                        )}
                                    </ImageGroup>
                                    <ImageGroup>
                                        <TitlePhoto>Logo</TitlePhoto>
                                        {!url_logo ? <Image data-tooltip={logo.length === 0 ? 'Nenhuma imagem carregada!' : 'Imagem carregada com sucesso!'}>
                                            <UploadImage amount={1} msg={`(Logo) `} setFile={setLogo} />
                                        </Image> : (
                                            <BodyImage>
                                                <img src={url_logo} data-tooltip="Logo" />
                                                <Options>
                                                    <IoTrashOutline color="#F00" onClick={() => { handleDeleteImage('logo') }} />
                                                </Options>
                                            </BodyImage>
                                        )}
                                    </ImageGroup>
                                </WapperImages>
                                <button type="submit">Enviar</button>
                            </Form>
                            {/* <Delete>
                                <IoTrashOutline onClick={() => { handleDelete(id); setShowModal(!showModal) }} />
                            </Delete> */}
                            <CloseModalButton onClick={() => setShowModal(!showModal)} />
                        </ModalContent>
                    </animated.div>
                </Background>
            ) : null}
        </>
    );
}