import styled from 'styled-components';

// export const Container = styled.div`
//     width: 100%;
//     min-height: 100vh;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     @media screen and (max-width: 768px){
//         width: 100vw;    
//     }
// `;

export const Container = styled.div`
    width: 100%;
    /* min-height: 100vh; */
    height: 100vh;
    display: flex;
    padding: 3rem calc((100vw - 1000px) / 2);
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 768px){
        width: 100vw;    
    }
`;

export const Wapper = styled.div`
    
`;

export const Form = styled.form`
    width: 35%;
    margin-left: 1%;
    margin-top:10%;
    background: #fff;
    text-align: center;
    position: absolute;
    padding: 20px;
    z-index:1000;
    h1{
        padding: 10px;
        margin-bottom: 1rem;
        text-transform: uppercase;
    }   
`;


export const Options = styled.div`
    width: 95%;
    display: flex;
    margin-bottom: 1rem;
    select{
        margin-right: 0.5rem;
    }
`;
export const SerchArea = styled.div`
    width: 100%;
    position: relative;
    input{
       margin-left: 0.5rem; 
    }
    svg{
        position: absolute;
        top: 30%;
        left: 3%;
    }
`;
export const Pagination = styled.div`
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    small{
        opacity: 50%;
    }
`;

export const WapperForm = styled.div`
    width: 35%;
    margin-left: 1%;
    margin-top:10%;
    background: #fff;
    text-align: center;
    position: absolute;
    padding: 20px;
    z-index:1000;
`;

export const MoreInfos = styled.div`
    display: flex;
    width: 300px;
    justify-content: space-around;
    margin-top: -20%;
`;

export const LatLngs = styled.div`
    display:flex;
    flex-direction: column;
`;
export const WapperDatesWaypoint = styled.div`
    display: flex;
    justify-content: space-around;
    width:100%;
    align-items: center;
`;
export const FormGruop = styled.div`
    width: 400px;
    input[type=text]{
        margin-top: 25px;
    }
`;


export const Image = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    height:50%;
    width:100%;
`;

export const WapperInfoWindow = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 7%;
`;

export const WapperImages = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    @media screen and (max-width: 882px){
        max-height: 150px;
    }
    @media screen and (max-width: 768px){
       overflow-y:auto;
    }
`;

export const ImageGroup = styled.div`
    display: flex;
    flex-direction: column;
    width: 22%;
    padding: 10px;
    img{
        width:100%;
        height:100%;
        -webkit-box-shadow:0px 0px 18px 7px #c5c5c5 ;
        -moz-box-shadow:0px 0px 18px 7px #c5c5c5 ;
        box-shadow:0px 0px 18px 7px #c5c5c5 ;
    }
    @media screen and (max-width: 1024px){
        min-width: 120px;
    }
`;

export const TitlePhoto = styled.div`
    display: flex;
    justify-content: center;
    width: 300px;
    margin-left: 15px;
    text-transform: uppercase;
    font-size: 1.5rem;
    color: var(--dark-color);
`;

export const BodyImage = styled.div`
    height:100%;
    width:100%;
`;