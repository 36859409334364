import React from 'react';
import { Container, Card, CardGroup, CardHeader, CardBody, CardFooter, Options } from './styles';
import { IoArrowRedoSharp } from 'react-icons/io5';
import { GiFactory } from 'react-icons/gi';
import { Link, useHistory } from 'react-router-dom';
import routeImg from '../../../assets/images/route.png'

export default function CardComponent({ loading, error, repeatLoading, data, list, handleClicked }){
    return(
        <>
            {loading || error ? (
                <Container>
                    {repeatLoading.map(rl => (
                        <Card key={rl}>
                            {list.map(l => (
                                <CardGroup key={l}>
                                    <div></div>
                                    <div></div>
                                </CardGroup>
                            ))}
                            <Options>
                                <div></div>
                                <div></div>
                                <div></div>
                            </Options>
                        </Card>
                    ))}
                </Container>
            ) : (
                <Container>
                    {data.map((d, i) => (
                        <Card key={i}>
                            <CardHeader>
                                {d.nome}
                            </CardHeader>
                            <CardBody>
                            {/* Futuramente, substituir pela logo adicionada no cadastro */}
                            <GiFactory size={60}/>
                            {/* {list.map((l, i) => ( */}
                                {/* <CardGroup key={i}> */}
                                <CardGroup>
                                    {/* <img src={routeImg} alt="Rota do dia" /> */}
                                    {/* <strong>{l.replace(/_/g, ' ')}:</strong>
                                    <span>{d[l]}</span> */}
                                    <strong>Email:</strong>
                                    <span>{d.email}</span>
                                </CardGroup>
                                <CardGroup>
                                    <strong>Segmento:</strong>
                                    <span>{d.segmento}</span>
                                </CardGroup>
                                <CardGroup>
                                    <strong>Link site:</strong>
                                    {/* O usuário é redirecionado para o link, porém com 'localhost:3000/ antes do link do parceiro' */}
                                    <span><a href={'http://' + d.link_site} target='_blank'>{d.link_site}</a></span>
                                </CardGroup>
                            {/* ))} */}
                            </CardBody>
                            {/* <CardFooter></CardFooter> */}
                            {/* <Options>
                                <IoArrowRedoSharp color="#000068" style={{position: "absolute", right: 0}} 
                                    onClick={() => {
                                        handleClicked(d.id)
                                    }} 
                                />
                            </Options> */}
                        </Card>
                    ))}
                </Container>
            )}
        </>
    );
}