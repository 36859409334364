import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { isAuthenticated, isAuthenticatedAdmin, isAuthenticatedGuard } from './auth';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

// Public Routes
import Index from './pages/Index';
import Login from './pages/Login';
import Register from './pages/Register';
import RegisterComplement from './pages/RegisterComplement';
import Recover from './pages/Recover';
import Home from './pages/Home';
import Messages from './pages/Messages';
import Warning from './pages/Warning'
import Verify from './pages/Verify'
import ViewRoutes from './pages/Routes'
import ViewPartners from './pages/Partners'
import Terms from './pages/Terms'
import Privacy from './pages/PrivacyPolicy'
import PrivacyPolicy from './pages/PrivacyPolicy'

// Private Routes
import Profile from './pages/Profile'
import RoutesView from './pages/RoutesView'
import NewsView from './pages/NewsView'
import PartnersView from './pages/PartnersView'
import KnowMore from './pages/KnowMore'
import Bikes from './pages/Bikes'
import Occurrences from './pages/Occurrences'

// Admin Routes
import Admin from './pages/Admin';
import AdminUsers from './pages/AdminUsers';
import AdminBicycles from './pages/AdminBicycles';
import AdminFaq from './pages/AdminFaq'
import AdminPartners from './pages/AdminPartners';
import AdminMessages from './pages/AdminMessages';
import AdminNews from './pages/AdminNews';
import AdminRoutes from './pages/AdminRoutes';
import AdminAddRoute from './pages/AdminAddRoute';
import AdminRouteEdit from './pages/AdminRouteEdit';
import AdminOccurrences from './pages/AdminOccurrences'
import AdminPrivacyPolicy from './pages/AdminPrivacyPolicy'
import Faq from './pages/Faq'
import News from './pages/News';
import TermsOfUse from './pages/TermsOfUse'
import Testes from './pages/Testes';
import PageNotFound from './pages/PageNotFound';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        isAuthenticated() ? (
            <Component {...props} />
        ) : (
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
    )} />
);

const GuardRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        isAuthenticatedGuard() || isAuthenticatedAdmin() ? (
            <Component {...props} />
        ) : (
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
    )} />
);

const AdminRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        isAuthenticatedAdmin() ? (
            <Component {...props} />
        ) : (
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
    )} />
);

const PublicRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        // !isAuthenticated() ? (
        !isAuthenticated() || isAuthenticated() ? (
            <Component {...props} />
        ) : (
            <Redirect to={{ pathname: '/home', state: { from: props.location } }} />
        )
    )} />
);

export default function Routes() {
    return (
        <BrowserRouter>
            <Navbar />
            <Switch>
                {/* ROTAS PUBLICAS, PODEM SER ACESSADAS POR QUALQUER UMA */}
                <PublicRoute exact path="/" component={Index} />
                <PublicRoute exact path="/login" component={Login} />
                <PublicRoute exact path="/register" component={Register} />
                <PublicRoute exact path="/registercomplement/:id" component={RegisterComplement} />
                <PublicRoute exact path="/recover" component={Recover} />
                <PublicRoute exact path="/verify" component={Verify} />
                <PublicRoute exact path="/faq" component={Faq} />
                <PublicRoute exact path="/messages" component={Messages} />
                <PublicRoute exact path="/knowmore" component={KnowMore} />
                <PublicRoute exact path="/terms" component={Terms} />
                <PublicRoute exact path="/privacypolicy" component={Privacy} />

                {/* ROTAS PRIVADAS, SÓ PODEM SER ACESSADAS POR CICLISTAS LOGADOS */}
                <PrivateRoute exact path="/news" component={News} />
                <PrivateRoute exact path="/news/:id" component={NewsView} />
                <PrivateRoute exact path="/home" component={Home} />
                <PrivateRoute exact path="/profile" component={Profile} />
                {/* <PrivateRoute exact path="/messages" component={Messages} /> */}
                <PrivateRoute exact path="/warning" component={Warning} />
                <PrivateRoute exact path="/routes" component={ViewRoutes} />
                <PrivateRoute exact path="/routes/:id" component={RoutesView} />
                <PrivateRoute exact path="/partners" component={ViewPartners} />
                <PrivateRoute exact path="/bikes" component={Bikes} />
                <PrivateRoute exact path="/occurrences" component={Occurrences} />

                {/* ROTAS DE GUARDA, SÓ PODEM SER ACESSADAS POR GUARDAS LOGADOS */}
                <GuardRoute exact path="/guard/occurrences" component={AdminOccurrences} />

                {/* ROTAS DE ADMINISTRADOR, SÓ PODEM SER ACESSADAS POR ADMINISTRADORES LOGADOS */}
                <AdminRoute exact path="/admin" component={Admin} />
                <AdminRoute exact path="/admin/users" component={AdminUsers} />
                <AdminRoute exact path="/admin/bicycles" component={AdminBicycles} />
                <AdminRoute exact path="/admin/faq" component={AdminFaq} />
                <AdminRoute exact path="/admin/partnerstores" component={AdminPartners} />
                <AdminRoute exact path="/admin/messages" component={AdminMessages} />
                <AdminRoute exact path="/admin/occurrences" component={AdminOccurrences} />
                <AdminRoute exact path="/admin/termsofuse" component={TermsOfUse} />
                <AdminRoute exact path="/admin/privacypolicy" component={AdminPrivacyPolicy} />
                <AdminRoute exact path="/admin/news" component={AdminNews} />
                <AdminRoute exact path="/admin/routes" component={AdminRoutes} />
                <AdminRoute exact path="/admin/routes/add" component={AdminAddRoute} />
                <AdminRoute exact path="/admin/routes/edit/:id" component={AdminRouteEdit} />

                <Route path="*" component={PageNotFound} />
            </Switch>
            {/* <Footer /> */}
        </BrowserRouter>
    );
}