import styled from 'styled-components';

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 768px){
        width: 100vw;    
        /* padding-top: 1rem; */
    }
`;

export const Wapper = styled.div`
    display: flex;
    justify-content: space-around;
    width: 90%;
    height: 81%;
    margin-top: 5vh;
    @media screen and (max-width: 1024px){
        /* overflow-y: auto; */
        /* border: 1px solid red; */
        flex-direction: column;
        /* justify-items: center; */
        align-items: center;
        height: 150%;
        padding-top: 180px;
    }
    @media screen and (max-width: 827px){
        padding-top: 270px;
    }
    /* @media screen and (max-width: 825px){
        padding-top: 250px;
    } */
    /* @media screen and (max-width: 768px){ */
        /* overflow-y: auto; */
        /* height: 150%; */
        /* padding-top: 300px; */
    /* } */
`;

export const WapperForm = styled.div`
    width: 30%;
    display:flex;
    justify-content: space-around;
    -webkit-box-shadow:0px 0px 18px 7px #c5c5c5 ;
    -moz-box-shadow:0px 0px 18px 7px #c5c5c5 ;
    box-shadow:0px 0px 18px 7px #c5c5c5 ;
    @media screen and (max-width: 1024px){
        /* margin-left: 37%; */
        width: 90%;
    }
`;

export const Form = styled.form`
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    background: #fff;
`;

export const FormContent = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const Title = styled.h1`
    text-transform: uppercase;
    color: var(--dark-color);
    margin-bottom: 5%;
`;

export const FormGroup = styled.div`
    flex: 1 1 20rem;
    margin: 0 0.5rem 1.5rem 0.5rem;
    position: relative;
    svg{
        position: absolute;
        top: 25%;
        right: 1%;
        cursor: pointer;
    }
    img{
        max-width: 3rem;
        height: auto;
    }
`;

export const WapperMap = styled.div`
    margin-left: 2%;
    width: 25%;
    border: 1px solid #c5c5c5;
    -webkit-box-shadow:0px 0px 18px 7px #c5c5c5 ;
    -moz-box-shadow:0px 0px 18px 7px #c5c5c5 ;
    box-shadow:0px 0px 18px 7px #c5c5c5 ;
    @media screen and (max-width: 1024px){
        width: 30%;
        min-height: 50%;
        margin-top: 20px;
        /* Talvez tirar esse margin-right */
        margin-right: 15px;
        /* margin-left: 37%; */
        width: 90%;
    }
    /* @media screen and (max-width: 768px){
        height: 1000px;
    } */
`;


export const Options = styled.div`
    width: 30%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    margin-top: 2%;
    button{
        height: 50%;
        margin-left: 5px;
        border: none;
    }
    @media screen and (max-width: 1024px){
        width: 80%;
        /* flex-direction: column; */
        margin-top: 230px;  
        background: transparent;
        button{
            margin-bottom: 10px;
        }
    }
    @media screen and (max-width: 768px){
        width: 80%;
        flex-direction: column;
        margin-top: 360px;  
        background: transparent;
        button{
            margin-bottom: 10px;
        }
    }
`;


export const WapperImages = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    background: #fff;
    width: 25%;
    -webkit-box-shadow:0px 0px 18px 7px #c5c5c5 ;
    -moz-box-shadow:0px 0px 18px 7px #c5c5c5 ;
    box-shadow: 0px 0px 18px 7px #c5c5c5 ;
    @media screen and (max-width: 1024px){
        width: 30%;
        /* margin-top: 20px;
        margin-left: 37%; */
        margin-top: 20px;
        width: 90%;
        max-height: 200px;
    }
    /* @media screen and (max-width: 882px){
        max-height: 150px;
    }
    @media screen and (max-width: 768px){
       overflow-y:auto;
    } */
`;

export const ImageGroup = styled.div`
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    width: 83%;
    height: 70%;
    padding: 10px;
    margin-left: 1%;
    img{
        max-width: 100%;
        max-height: 100%;
        -webkit-box-shadow: 0px 0px 18px 7px #c5c5c5 ;
        -moz-box-shadow: 0px 0px 18px 7px #c5c5c5 ;
        box-shadow: 0px 0px 18px 7px #c5c5c5 ;
    }
    @media screen and (max-width: 1024px){
        /* min-width: 120px; */
    }
`;

export const TitlePhoto = styled.div`
    display: flex;
    justify-content: center;
    width: 300px;
    margin-left: 15px;
    text-transform: uppercase;
    font-size: 1.5rem;
    color: var(--dark-color);
`;

export const BodyImage = styled.div`
    height:75%;
    width:100%;
    border: solid #f4f5f7 1px;
`;

export const Msg = styled.div`
    font-size: 20px;
    align-items: center;
    margin-left: 5px;
`;