import styled from 'styled-components';

export const Head = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    h1{
        font-size: 2.5rem;
        color: var(--dark-color);
        margin-bottom: 0.5rem;
        border-bottom: 0.25rem solid var(--dark-color);
        border-radius: var(--border-radius);
    }
    p{
        color: #737380;
    }
`;
export const Wrapper = styled.ul`
    max-width: 100%;
    display: flex;
    /* overflow-x: auto; */
    list-style: none;
    margin: 1.5rem 0;
    padding: 0.5rem 0;
    /* &::-webkit-scrollbar-track{
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: var(--background-color);
    }
    &::-webkit-scrollbar{
        height: 0.4rem;
        background-color: var(--background-color);
    }
    &::-webkit-scrollbar-thumb{
        box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: var(--dark-color);
    } */
    @media screen and (max-width: 768px){
        display: grid;
        grid-gap: 15px;
        padding: 15px;
    }
`;
export const Card = styled.li`
    min-width: 20.41rem;
    height: auto;
    padding: 0.5rem;
    margin: 0 0.5rem 0 0;
    background: #fff;
    border-radius: var(--border-radius);
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    will-change: transform;
    transition: all .3s linear;
    &:hover{
        filter: brightness(99%);
        box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
        transform: translateY(-1px);
    }
    @media screen and (max-width: 768px){
        display: grid;
    }
`;
export const CardHeader = styled.div`
    text-align: center;
    padding: 0 0 0.5rem 0;
    h1{
        font-size: 1.2rem;
    }
    span{
        color: #F00;
    }
`;
export const CardBody = styled.div`
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-gap: 0.3rem;
    align-items: center;
    img{
        width: 100%;
        height: auto;
    }
`;
export const CardGroup = styled.div`
    display: grid;
    padding: 0.5rem;
    position: relative;
    overflow: hidden;
    strong{
        font-size: 0.9rem;
        text-transform: uppercase;
        color: #41414d;
    }
    img{
        width: 40%;
        height: auto;
        margin-bottom: -15px;
        margin-top: -15px;
    }
    span{
        font-size: 0.85rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #737380;
    }
`;
export const CardFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    small{
        color: #737380;
    }
    button{
        padding: .1rem 0.5rem;
        background: transparent;
        border: transparent;
        box-shadow: none;
        color: #737380;
    }
`;