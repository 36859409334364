import React, { useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Content, Form, FormGroup, Column, LinkGroup, AcceptCheckBoxes, Label } from './styles';
import GoBack from '../../components/BackToMenu/index'
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import swal from 'sweetalert';
import api from '../../services/api';
import InputMask from 'react-input-mask'
import ReCAPTCHA from "react-google-recaptcha";
// import Checkbox from '@material-ui/core/Checkbox';
// import FormGroups from "@material-ui/core/FormGroup";
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormControl from "@material-ui/core/FormControl";
// import FormLabel from "@material-ui/core/FormLabel";

export default function Register() {
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [confirmSenha, setConfirmSenha] = useState('');
    const [nome, setNome] = useState('');
    const [data_nascimento, setData_nascimento] = useState('');
    const [sexo, setSexo] = useState('');
    const [telefone, setTelefone] = useState('');
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [acceptPolicy, setAcceptPolicy] = useState(false);
    const recaptchaRef = useRef();

    const [visibleSenha, setVisibleSenha] = useState(false);
    const [visibleConfirmSenha, setVisibleConfirmSenha] = useState(false);
    const history = useHistory();
    async function handleSubmit(e) {
        e.preventDefault();
        var data_nasc = new Date(data_nascimento);
        var dataAtual = new Date();
        var diff = (dataAtual.getTime() - data_nasc.getTime()) / 1000;
        diff /= (60 * 60 * 24);
        var anos = Math.floor(diff / 365.25);
        if (email !== confirmEmail) return swal("Atenção!", "Os emails não estão iguais!", "warning");
        if (senha !== confirmSenha) return swal("Atenção!", "As senhas não estão iguais!", "warning");
        if (anos < 18) return swal("Atenção!", "Você deve ter pelo menos 18 anos!", "warning");
        try {
            const token = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset();
            const data = {
                email: email,
                senha: senha,
                nome: nome,
                data_nascimento: data_nascimento,
                sexo: sexo,
                telefone: telefone,
                retoken: token
            }
            const response = await api.post('/register', { data });
            if (response.data.error) return swal("Atenção", response.data.error, "warning");
            // Talvez avisar ao usuário que foi cadastrado com sucesso
            // swal("Sucesso!", response.data.success, "warning");
            history.push(`/registercomplement/${btoa(response.data.id)}`);
        } catch (error) { alert(`Serviço indisponível no momento!`); }
    }
    return (
        <Container>
            <GoBack />
            <Content>
                <Form onSubmit={handleSubmit}>
                    <h1>Registre-se</h1>
                    <Column>
                        <FormGroup>
                            <input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                maxLength="150"
                                required
                            />
                            <label>Email</label>
                        </FormGroup>
                        <FormGroup>
                            <input
                                type="text"
                                placeholder="Confirmar Email"
                                value={confirmEmail}
                                onChange={e => setConfirmEmail(e.target.value)}
                                maxLength="150"
                                required
                            />
                            <label>Confirmar Email</label>
                        </FormGroup>
                    </Column>
                    <Column>
                        <FormGroup>
                            <input
                                type={visibleSenha ? 'text' : 'password'}
                                autoComplete="new-password"
                                placeholder="Senha"
                                value={senha}
                                onChange={e => setSenha(e.target.value)}
                                minLength="8"
                                maxLength="255"
                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                title='Deve conter pelo menos 1 número, 1 letra em maiúsculo e 1 letra em minúsculo, e pelo menos 8 caracteres!'
                                required
                            />
                            <label>Senha</label>
                            {visibleSenha ? (
                                <IoEyeOutline size={22} color="#000" onClick={() => setVisibleSenha(!visibleSenha)} />
                            ) : (
                                <IoEyeOffOutline size={22} color="#000" onClick={() => setVisibleSenha(!visibleSenha)} />
                            )}
                        </FormGroup>
                        <FormGroup>
                            <input
                                type={visibleConfirmSenha ? 'text' : 'password'}
                                autoComplete="new-password"
                                placeholder="Confirmar Senha"
                                value={confirmSenha}
                                onChange={e => setConfirmSenha(e.target.value)}
                                minLength="8"
                                maxLength="255"
                                required
                            />
                            <label>Confirmar Senha</label>
                            {visibleConfirmSenha ? (
                                <IoEyeOutline size={22} color="#000" onClick={() => setVisibleConfirmSenha(!visibleConfirmSenha)} />
                            ) : (
                                <IoEyeOffOutline size={22} color="#000" onClick={() => setVisibleConfirmSenha(!visibleConfirmSenha)} />
                            )}
                        </FormGroup>
                    </Column>
                    <FormGroup>
                        <input
                            type="text"
                            placeholder="Nome"
                            value={nome}
                            onChange={e => setNome(e.target.value)}
                            minLength="3"
                            maxLength="100"
                            required
                        />
                        <label>Nome</label>
                    </FormGroup>
                    <FormGroup>
                        <InputMask
                            type="text"
                            mask='(99) 99999-9999'
                            placeholder="Telefone"
                            value={telefone}
                            onChange={e => setTelefone(e.target.value)}
                            maxLength="150"
                            required
                        />
                        <label>Telefone</label>
                    </FormGroup>
                    <Column>
                        <FormGroup>
                            <input
                                type="date"
                                placeholder="Data de nascimento"
                                value={data_nascimento}
                                onChange={e => setData_nascimento(e.target.value)}
                                required
                            />
                            <label>Data de nascimento</label>
                        </FormGroup>
                        <FormGroup>
                            <select
                                value={sexo}
                                onChange={e => setSexo(e.target.value)}
                            >
                                <option value="Masculino">Masculino</option>
                                <option value="Feminino">Feminino</option>
                            </select>
                            <label>Sexo</label>
                        </FormGroup>
                    </Column>

                    {/* <AcceptCheckBoxes>
                        <input
                            type='checkbox'
                            value={acceptTerms}
                            onChange={e => setAcceptTerms(e.target.value)}
                            // style={{float: 'left', marginTop: 4}}
                            name='terms'
                            required
                        />
                        Tirar o import desse label caso não queira mais tentar com ele, e tirar também a exportação do styles.js
                        <p htmlFor='terms'>Eu aceito os <a href='#'>Termos</a> e <a href='#'>Política de Privacidade</a> do site</p>
                        <label style={{marginLeft: 330, position: "absolute"}}>Eu aceito os <a href='#'>Termos</a> e <a href='#'>Política de Privacidade</a> do site</label>
                    </AcceptCheckBoxes> */}

                    <input
                        type='checkbox'
                        value={acceptTerms}
                        onChange={e => setAcceptTerms(e.target.value)}
                        style={{ float: 'left', marginTop: 4, zoom: 1.4 }}
                        required
                    />
                    <h4 style={{ marginLeft: 30, position: "absolute", marginTop: 5 }}>Eu aceito os <a href='/terms'>Termos</a> e <a href='privacypolicy'>Política de Privacidade</a> do site.</h4>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        size="invisible"
                    />
                    <button style={{ marginTop: '4rem' }}>Criar</button>
                    <LinkGroup>
                        <Link to="/login">Já é um de nós?</Link>
                    </LinkGroup>
                </Form>
            </Content>
        </Container >
    );
}