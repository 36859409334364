import React, { useCallback, useState, useRef, useEffect } from 'react';
import { GoogleMap, Marker, useLoadScript, DirectionsRenderer, InfoWindow } from "@react-google-maps/api";
import uniqid from 'uniqid';
import { Container, Wapper, WapperForm, Form, FormGroup, FormContent, WapperMap, WapperImages, TitlePhoto, Title, ImageGroup, BodyImage, Msg, Options } from './styles';
import swal from 'sweetalert';
import api from '../../../services/api';
import UploadImage from '../../UploadImage/imageRoutes';
import stylesButtons from '../../../styles/buttons';
import Search from '../Search';
import mapStyles from '../../../styles/mapStyles';
import { useHistory } from 'react-router-dom'
import Switch from "react-switch";
import Logo from '../../../assets/images/logo.ico'
import Auth from '../../../services/auth'
import defaultImage from '../../../assets/images/photo.png';

const mapContainerStyle = {
    width: "100%",
    height: "100%",
}
const options = {
    disableDefaultUI: false,
    zoomControl: true,
    styles: mapStyles
}

const center = {
    lat: -23.09744828257539,
    lng: -47.228578108868085
}
const libraries = ["places"];

export default function Map({ markers, setMarkers, directions, handleFindWaypoints }) {

    const history = useHistory();
    const [id, setId] = useState('');
    const [nome, setNome] = useState('');
    const [descricao, setDescricao] = useState('');
    const [descricao_ponto, setDescricao_ponto] = useState('');
    const [cor, setCor] = useState('');
    const [cor_hex, setCor_hex] = useState('');
    const [endereco, setEndereco] = useState('');
    const [distancia_total, setDistancia_total] = useState('');
    const [sequencia_itinerario, setSequencia_itinerario] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [foto_ponto, setFoto_ponto] = useState([]);
    const [default_foto_ponto, setDefault_Foto_ponto] = useState(true);
    const [url_foto_ponto, setUrl_foto_ponto] = useState(null);
    const [selected, setSelected] = useState(null);
    const [indexSelected, setIndexSelected] = useState(null);
    const [backupWaypoint, setBackupWaypoint] = useState('')
    const [isEdded, setIsEdded] = useState(false);
    const [haveImage, setHaveImage] = useState(false);
    const [checked, setChecked] = useState(true);
    const [auth, setAuth] = useState(Auth.token());

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
        libraries,
    });
    const mapRef = useRef();
    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    const panTo = React.useCallback(({ lat, lng, formatted_address }) => {
        // setLatitude(lat);
        // setLongitude(lng);
        // let newList = [];
        // newList = markers;
        // newList[indexSelected].lat = latitude;
        // newList[indexSelected].lng = longitude;
        // newList[indexSelected].endereco = formatted_address;
        // setMarkers(newList);

    }, []);

    useEffect(() => {
        if (foto_ponto) setHaveImage(true);
        foto_ponto.map(file => setUrl_foto_ponto(URL.createObjectURL(file)));
    }, [foto_ponto])

    useEffect(() => {
        const id = history.location.state
        api.get(`/routes/${id}?auth=${auth}`).then(response => {
            setDistancia_total(response.data.route.routes[0].percurso_total);
            setNome(response.data.route.routes[0].nome);
            setDescricao(response.data.route.routes[0].descricao);
            setCor(response.data.route.routes[0].cor);
            setCor_hex(response.data.route.routes[0].cor_hex);
        });
    }, []);

    if (loadError) return "Error Loading Maps";
    if (!isLoaded) return "Loaging Maps";

    //Atualiza no array os novos dados do ponto que o usuario edito
    const handleSaveChange = (e) => {
        e.preventDefault();
        try {
            swal({
                title: "Você tem certeza?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willconfirm) => {
                    if (willconfirm) {
                        if (haveImage === true) {
                            let formData = new FormData();
                            formData.append('id', id);
                            formData.append('nome', nome);
                            formData.append('distancia_total', distancia_total);
                            formData.append('endereco', endereco);
                            formData.append('latitude', latitude);
                            formData.append('longitude', longitude);
                            formData.append('descricao', descricao);
                            // formData.append('cor_hex', cor);
                            // formData.append('percurso_total', 0);
                            formData.append('sequencia_itinerario', sequencia_itinerario);
                            formData.append('destaque_home', checked)
                            foto_ponto.forEach(file => formData.append('imagem', file));

                            var data = {};
                            var int = 0
                            formData.forEach((value, key) => {
                                // data[key] = value != 'null' || value != 'undefined' ? value : undefined
                                // Valor das fotos não está chegando como undefined em si, mas sim como undefined dentro de uma string
                                if (value != 'null' || value != 'undefined' || value != null || value != undefined) {
                                    data[key] = value
                                    int++
                                }
                            });

                            try {
                                const response = await api.patch(`admin/routes/${id}`, { data });
                                if (response.data.error) swal('Atenção!', response.data.error, 'warning')
                                swal('Sucesso!', 'Rota editada!', 'success')

                            } catch (error) {
                                // swal('Atenção!', `Serviço indisponível no momento!`, 'error');
                                alert(error + ' 1')
                            }
                        } else {

                            const data = {
                                id: id,
                                nome: nome,
                                distancia_total: distancia_total,
                                endereco: endereco,
                                latitude: latitude,
                                longitude: longitude,
                                descricao: descricao,
                                // cor_hex: cor,
                                // percurso_total: 0,
                                sequencia_itinerario: sequencia_itinerario,
                                url_foto_ponto: url_foto_ponto,
                                latitude: latitude,
                                longitude: longitude,
                                destaque_home: checked
                            }

                            try {
                                const response = await api.patch(`admin/routes/${id}`, { data });
                                if (response.data.error) swal('Atenção', response.data.error, 'warning')
                                swal('Sucesso', 'Rota editada!', 'success')
                            } catch (error) {
                                console.log(error)
                                // swal({
                                //     title: `Serviço indisponível no momento!`,
                                //     icon: "error",
                                // });
                                alert(error + ' 2')
                            }
                        }

                    } else {
                        swal("Cancelado!");
                    }
                });

        } catch (error) {
            swal({
                icon: 'error',
                text: 'Erro ao salvar as alterções'
            })
            console.log(error)
        }

    }

    //Limpa o formulario 
    const clearForm = () => {
        setNome('');
        setDescricao('');
        setEndereco('');
        setDistancia_total('');
        setSequencia_itinerario('');
        setLatitude('');
        setLongitude('');
        setUrl_foto_ponto(null);
        setDefault_Foto_ponto(true);
        setHaveImage(false);
        setFoto_ponto([]);
        setUrl_foto_ponto(null);
    }

    //faz um backup das informações do ponto selecionado
    const makeBackup = (marker) => {
        setBackupWaypoint(marker);
    }

    //descarta todas as altereções que o usuario fez nos dados do ponto selecionado
    const descardChanges = (e) => {
        e.preventDefault();
        setNome(backupWaypoint.nome);
        setDescricao(backupWaypoint.descricao);
        setEndereco(backupWaypoint.endereco);
        setDistancia_total(backupWaypoint.distancia_total);
        setSequencia_itinerario(backupWaypoint.sequencia_itinerario);
        setLatitude(backupWaypoint.latitude);
        setLongitude(backupWaypoint.longitude);
        // setChecked(backupWaypoint.destaque_home)
    }

    const handleCancel = (e) => {
        e.preventDefault()
        history.push('/admin/routes')
    }

    //Recebe os dados do ponto selecionado pelo usuario e preenche o formulario com os mesmos
    const handleShowWindow = (marker, i) => {
        makeBackup(marker);
        // clearForm();
        if (marker) {
            setIndexSelected(i);
            setId(marker.id);
            setDescricao_ponto(marker.descricao);
            setEndereco(marker.endereco);
            setSequencia_itinerario(marker.sequencia_itinerario);
            setLatitude(marker.latitude);
            setLongitude(marker.longitude);
            // setChecked(marker.destaque_home)
            if (marker.imagem) {
                setUrl_foto_ponto(marker.imagem);
                setDefault_Foto_ponto(false);
            } else {
                setDefault_Foto_ponto(true);
            }
            setSelected(marker)
            setIsEdded(true)
            return true
        }
    }

    return (
        <>
            <Container>
                <Wapper>
                    <WapperForm>
                        <Form onSubmit={e => handleSaveChange(e)}>
                            <Title>Dados do ponto</Title>
                            {selected
                                ?
                                <div style={{ marginBottom: "5%" }}></div>
                                :
                                <div style={{ marginBottom: "15%" }}></div>
                            }
                            <FormContent style={{ overflow: 'auto' }}>
                                <FormGroup>
                                    <input type="text" placeholder="Nome da rota" value={nome} disabled />
                                    <label>Nome do ponto</label>
                                </FormGroup>
                                <FormGroup>
                                    <input type="text" placeholder="Descrição" value={descricao} disabled />
                                    <label>Descrição</label>
                                </FormGroup>
                                <FormGroup>
                                    <input type="text" placeholder="Distância Total" value={distancia_total} disabled />
                                    <label>Distância Total</label>
                                </FormGroup>
                                {selected
                                    ?
                                    <>
                                        <FormGroup>
                                            <input type="text" placeholder="Sequência itinerário" value={sequencia_itinerario} disabled />
                                            <label>Sequência itinerário</label>
                                        </FormGroup>
                                        <FormGroup>
                                            <input type="text" placeholder="Endereço" value={endereco} disabled />
                                            {/* O campo comentado abaixo tem algum propósito de estar ali, e ele está usando uma função comentada lá em cima também, portanto, conversar com o Giba sobre */}
                                            {/* <Search panTo={panTo} /> */}
                                            <label>Endereço</label>
                                        </FormGroup>
                                        <FormGroup>
                                            <input type="text" placeholder="Descrição do Ponto" value={descricao_ponto} disabled />
                                            <label>Descrição do Ponto</label>
                                        </FormGroup>
                                    </>
                                    :
                                    <FormGroup>
                                        <p>Selecione um waypoint</p>
                                    </FormGroup>
                                }

                                {/* Com relação a imagem, está por fazer, precisa descobrir como aumentar de tamanho(deve ter alguma propriedade impedindo), e precisa fazer o sistema que vai receber as imagens, e decidir se vai ter realmente um carrossel que mostra todas as imagens adicionadas, com uma condição que se caso não exista dizendo: "Não há imagens dessa rota" */}
                                {/* <FormGroup>
                                    </FormGroup>
                                    <FormGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <img src={Logo} alt='Não há imagens dessa rota'/>
                                    </FormGroup> */}
                            </FormContent>
                            {selected
                                ?
                                <>
                                    <BodyImage>
                                        <img onError={e => { e.currentTarget.src = defaultImage }} src={`https://acibike.fiecdev.com.br:25555/routesphoto/${selected ? selected.id_rota : ''}_${selected ? selected.sequencia_itinerario : ''}.jpeg?auth=${auth}`} alt={'Erro'} />
                                    </BodyImage>
                                </>
                                :
                                null
                            }
                        </Form>
                    </WapperForm>
                    {/* <WapperImages>
                        <TitlePhoto>Foto do local</TitlePhoto>
                        <ImageGroup>
                            {url_foto_ponto ?
                                <BodyImage>
                                    <img src={url_foto_ponto} data-tooltip="Foto do local" />
                                </BodyImage>
                                : (
                                    <UploadImage amount={1} msg={`(Foto do local) `} setFile={setFoto_ponto} />
                                )}
                        </ImageGroup>
                    </WapperImages> */}
                    <WapperMap>
                        <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            zoom={14}
                            center={center}
                            options={options}
                            onLoad={onMapLoad}
                            ref={mapRef}
                            formatted
                        >
                            {markers.map((marker, i) =>
                                <Marker style={{ visualViewport }} key={marker.id}
                                    options={{
                                        draggable: false,
                                        preserveViewport: true,
                                        markerOptions: {
                                            crossOnDrag: true,
                                            clickable: true,
                                        }
                                    }}
                                    label={
                                        { text: marker.sequencia_itinerario + "", color: "white" }
                                    }
                                    position={{ lat: marker.latitude, lng: marker.longitude }}
                                    onClick={() => handleShowWindow(marker, i)}
                                />
                            )}
                            {directions ?
                                <DirectionsRenderer
                                    key={uniqid()}
                                    directions={directions}
                                    options={{
                                        draggable: false,
                                        suppressMarkers: true,
                                        suppressInfoWindows: true,
                                        preserveViewport: false,
                                        markerOptions: {
                                            crossOnDrag: true,
                                            clickable: true,
                                        },
                                        polylineOptions: {
                                            strokeColor: cor_hex,
                                            strokeWeight: 4
                                        },
                                    }}
                                />
                                : null}
                        </GoogleMap>
                    </WapperMap>

                </Wapper>
                {/* <Options>
                    {isEdded ?
                        <>
                            <button type="submit" onClick={e => handleSaveChange(e)} style={stylesButtons.save}>Salvar</button>
                            <button type="submit" onClick={e => handleCancel(e)} style={stylesButtons.cancel}>Cancelar</button>
                            <button type="submit" onClick={e => descardChanges(e)} style={stylesButtons.undo}>Descartar Alterações</button>
                        </>
                        : <Msg><p>Selecione um ponto no mapa para editar</p></Msg>
                    }
                </Options> */}
            </Container>
        </>
    );
}