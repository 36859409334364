import React from 'react'
import { Button } from './styles'
import { Link } from 'react-router-dom';
import { IoHome } from 'react-icons/io5'

/**
 * Botão de voltar ao menu, que está em todas as telas, menos na de menu
 */
export default function(){
    return(
        <Link to='/'>
            <Button>
                <IoHome size={26} color='#fff'/>  <p>Voltar ao menu</p>
            </Button>
        </Link>
    )
}