import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import { Background, ModalContent, CloseModalButton, Form, FormContent, FormGroup, Delete, WapperImages, TitlePhoto, Image, ImageGroup, BodyImage, Options, TextArea } from './styles';
import UploadImage from '../../../UploadImage';
import api from '../../../../services/api';
import InputMask from 'react-input-mask';
import { IoTrashOutline } from "react-icons/io5";
import swal from 'sweetalert';
import Auth from '../../../../services/auth';
import { Logout } from '../../../../services/logout';

export default function ModalEdit({ showModal, setShowModal, id }) {

    const modalRef = useRef();
    const [titulo, setTitulo] = useState('');
    const [conteudo, setConteudo] = useState('');
    const [autor, setAutor] = useState('');
    const [segmento, setSegmento] = useState('');
    const [linkFonte, setLinkFonte] = useState('');
    const [banner, setBanner] = useState([]);
    const [logo, setLogo] = useState([]);
    const [url_banner, setUrl_banner] = useState(null);
    const [url_logo, setUrl_logo] = useState(null);
    const [haveImage, setHaveImage] = useState(false);
    const [banner_undefined, setBanner_undefined] = useState(false);
    const [logo_undefined, setLogo_undefined] = useState(false);
    const [token, setToken] = useState(Auth.token());
    const [logo_host, setLogo_host] = useState(true);
    const [banner_host, setBanner_host] = useState(true);

    const animation = useSpring({
        config: {
            duration: 150
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`
    });

    const keyPress = useCallback(e => {
        if (e.key === 'Escape' && showModal) setShowModal(!showModal);
    }, [setShowModal, showModal]);

    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);

    useEffect(() => {
        api.get(`/news/${id}?auth=${token}`).then(response => {
            // console.log(response.data.artigos[0].titulo);
            // clerForm();

            setTitulo(response.data.artigos[0].titulo);
            setConteudo(response.data.artigos[0].conteudo)
            setSegmento(response.data.artigos[0].segmento);
            setAutor(response.data.artigos[0].autor);
            setLinkFonte(response.data.artigos[0].link_fonte);

            // if (response.data.banner == 'null') { setBanner_undefined(true); setUrl_banner(null); }
            // else { setUrl_banner(response.data.banner); }
            // if (response.data.logo == 'null') { setLogo_undefined(true); setUrl_logo(null); }
            // else setUrl_logo(response.data.logo);
        }).catch(error => {
            if (error.response.status === 401) {
                Logout();
            }
        });
    }, [id]);

    useEffect(() => {
        if (banner || logo) {
            setHaveImage(true);
            console.log(haveImage)
        }
        if (banner) {
            banner.map(file => setUrl_banner(URL.createObjectURL(file)));
            console.log(url_banner);
        }
        if (logo) {
            logo.map(file => (setUrl_logo(URL.createObjectURL(file))));
            console.log(logo);
        }
    }, [banner, logo]);

    async function handleDeleteImage(url, query_params) {
        const data = {
            id: id,
            url: url,
            query_params: query_params,
        };
        swal({
            title: "Você tem certeza?",
            text: "O arquivo sera deletado permanentemente!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    api.post(`admin/partner/deleteimage`, { data }).then(response => {
                        if (response.data.success) {
                            swal(response.data.success, {
                                icon: "success",
                            });
                            if (query_params == 'banner') setUrl_banner(null); setBanner([]);
                            if (query_params == 'logo') setUrl_logo(null); setLogo([]);
                        } else {
                            swal("Erro ao deletar o arquivo!", {
                                icon: "error",
                            });
                        }
                    })
                } else {
                    swal("Cancelado!");
                }
            });
    }


    async function handleDeletePreviewImage(query_params) {

        swal({
            title: "Você tem certeza?",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    if (query_params === 'banner') { setUrl_banner(null); setBanner(null) }
                    if (query_params === 'logo') { setUrl_logo(null); setLogo(null) }
                } else {
                    swal("Cancelado!");
                }
            });

    }


    async function handleDelete() {
        swal({
            title: "Você tem certeza?",
            text: "O arquivo sera deletado permanentemente!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    api.delete(`admin/partners/${id}`).then(response => {
                        if (response.data.success) {
                            swal(response.data.success, {
                                icon: "success",
                            });
                            window.location.reload();
                        } else {
                            swal("Erro ao deletar o arquivo!", {
                                icon: "error",
                            });
                        }
                    })
                } else {
                    swal("Cancelado!");
                }
            });
    }


    async function handleSubmit(e) {
        e.preventDefault();

        if (haveImage === true) {

            let formData = new FormData();

            formData.append('id', id);
            formData.append('titulo', titulo);
            formData.append('conteudo', conteudo);
            formData.append('segmento', segmento);
            formData.append('autor', autor);
            formData.append('link_fonte', linkFonte);
            formData.append('banner', banner_host);
            formData.append('logo', logo_host);
            if (banner) banner.forEach(file => formData.append(`${id}_banner`, file));
            else formData.append('url_banner', url_banner);
            if (logo) logo.forEach(file => formData.append(`${id}_logo`, file));
            else formData.append('url_logo', url_logo);

            try {
                const response = await api.patch(`/admin/news/${id}?auth=${token}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Accept: 'multipart/form-data'
                    }
                });
                if (response.data.error) swal('Atenção', response.data.error, 'warning')
                swal('Sucesso!', response.data.success, 'success')
                setTimeout(() => {
                    window.location.reload(false);
                }, 700);
            } catch (error) { swal('Atenção!', `Serviço indisponível no momento!`, 'error') }
        } else {
            const data = {
                id: id,
                titulo: titulo,
                conteudo: conteudo,
                segmento: segmento,
                autor: autor,
                link_fibte: linkFonte,
                banner: banner_host,
                logo: logo_host
            }
            try {
                const response = await api.patch(`/admin/news/${id}?auth=${token}`, { data });
                if (response.data.success) {
                    swal(response.data.success, {
                        icon: "success",
                    }).then(() => {
                        window.location.reload();
                    })

                } else {
                    swal("Erro ao atualizar os dados!", {
                        icon: "error",
                    });
                }
            } catch (error) {
                alert(`Serviço indisponível no momento!`);
            }
        }
    }

    // function clerForm() {

    //     setNome(null);
    //     setCpf_cnpj(null);
    //     setBanner(null);
    //     setLogo(null);
    //     setUrl_banner(null);
    //     setUrl_logo(null);
    //     setSegmento(null);
    //     setTelefone(null);
    //     setEmail(null);
    //     setLink_site(null);
    //     setHaveImage(null);
    //     setBanner_undefined(null);
    //     setLogo_undefined(null);

    // }


    return (
        <>
            {showModal ? (
                <Background ref={modalRef} onClick={(e) => modalRef.current === e.target ? setShowModal(!showModal) : null}>
                    <animated.div style={animation}>
                        <ModalContent>
                            <Form onSubmit={handleSubmit}>
                                <h1>Edição de notícias</h1>
                                <FormContent>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Título"
                                            value={titulo}
                                            onChange={e => setTitulo(e.target.value)}
                                            required
                                        />
                                        <label>Título</label>
                                    </FormGroup>
                                    <TextArea>
                                        <textarea
                                            type="text"
                                            placeholder="Conteúdo"
                                            value={conteudo}
                                            onChange={e => setConteudo(e.target.value)}
                                            style={{ padding: 15, resize: 'none', marginBottom: 27, borderRadius: 6 }}
                                            required
                                        />
                                    </TextArea>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Segmento"
                                            value={segmento}
                                            onChange={e => setSegmento(e.target.value)}
                                            // min="1"
                                            // max="99"
                                            required
                                        />
                                        <label>Segmento</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Autor"
                                            value={autor}
                                            onChange={e => setAutor(e.target.value)}
                                        // min="1"
                                        // max="99"
                                        />
                                        <label>Autor</label>
                                    </FormGroup>
                                    <FormGroup>
                                        <input
                                            type="text"
                                            placeholder="Link de fonte"
                                            value={linkFonte}
                                            onChange={e => setLinkFonte(e.target.value)}
                                            required
                                        />
                                        <label>Link de fonte</label>
                                    </FormGroup>
                                </FormContent>
                                <WapperImages>
                                    <ImageGroup>
                                        <TitlePhoto>Banner</TitlePhoto>
                                        {banner_host ?
                                            <BodyImage>
                                                <img onError={e => { setBanner_host(false); }} src={`https://acibike.fiecdev.com.br:25555/newsphoto/${id}_banner.jpeg`} alt={'Erro'} data-tooltip="Banner" />
                                                <Options>
                                                    <IoTrashOutline color="#F00" onClick={() => { swal({ title: "Você tem certeza?", icon: "warning", buttons: true, dangerMode: true, }).then((willDelete) => { if (willDelete) { setBanner_host(false); } }); }} />
                                                </Options>
                                            </BodyImage>
                                            :
                                            url_banner ?
                                                <BodyImage>
                                                    <img src={url_banner} data-tooltip="Banner" />
                                                    {/* <img onError={e => { e.currentTarget.src = url_foto_chassi; }} src={`https://acibike.fiecdev.com.br:25555/bikes/${chassi}_chassi.jpeg?auth=${token}`} alt={'Erro'} /> */}
                                                    <Options>
                                                        <IoTrashOutline color="#F00" onClick={!banner_undefined ? () => { handleDeletePreviewImage('banner') } : (() => { handleDeleteImage(url_banner, 'banner') })} />
                                                    </Options>
                                                </BodyImage>

                                                : (
                                                    <UploadImage amount={1} msg={`(Banner) `} setFile={setBanner} />
                                                )}
                                    </ImageGroup>
                                    <ImageGroup>
                                        <TitlePhoto>Logo</TitlePhoto>
                                        {/* <img src={url_foto_total ? url_foto_total  : defaultImage} data-tooltip="Foto Total da bicicleta" /> */}
                                        {logo_host ?
                                            <BodyImage>
                                                <img onError={e => { setLogo_host(false); }} src={`https://acibike.fiecdev.com.br:25555/newsphoto/${id}_logo.jpeg`} alt={'Erro'} data-tooltip="Logo" />
                                                <Options>
                                                    <IoTrashOutline color="#F00" onClick={() => { swal({ title: "Você tem certeza?", icon: "warning", buttons: true, dangerMode: true, }).then((willDelete) => { if (willDelete) { setLogo_host(false); } }); }} />
                                                </Options>
                                            </BodyImage>
                                            :
                                            url_logo ?
                                                <BodyImage>
                                                    <img src={url_logo} data-tooltip="Logo" />
                                                    {/* <img onError={e => { e.currentTarget.src = url_foto_chassi; }} src={`https://acibike.fiecdev.com.br:25555/bikes/${chassi}_chassi.jpeg?auth=${token}`} alt={'Erro'} /> */}
                                                    <Options>
                                                        <IoTrashOutline color="#F00" onClick={!logo_undefined ? () => { handleDeletePreviewImage('logo') } : (() => { handleDeleteImage(url_logo, 'logo') })} />
                                                    </Options>
                                                </BodyImage>
                                                : (
                                                    <UploadImage amount={1} msg={`(Logo) `} setFile={setLogo} />
                                                )}
                                    </ImageGroup>
                                </WapperImages>
                                <button type="submit">Enviar</button>
                            </Form>
                            {/* <Delete>
                                <IoTrashOutline onClick={() => { handleDelete(id) }} />
                            </Delete> */}
                            <CloseModalButton onClick={() => {
                                setUrl_banner(null);
                                setUrl_logo(null);
                                setLogo_host(true);
                                setBanner_host(true);
                                setShowModal(!showModal);
                            }} />
                        </ModalContent>
                    </animated.div>
                </Background>
            ) : null}
        </>
    );
}