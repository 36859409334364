import React, { useCallback, useState, useRef, useEffect } from 'react';
import { GoogleMap, Marker, useLoadScript, DirectionsRenderer, InfoWindow } from "@react-google-maps/api";
import { IoTrashOutline } from 'react-icons/io5'
import uniqid from 'uniqid';
import { Container, Wapper, WapperForm, Form, FormGroup, FormContent, WapperMap, WapperImages, TitlePhoto, Title, ImageGroup, BodyImage, Msg, Options } from './styles';
import swal from 'sweetalert';
import api from '../../../services/api';
import UploadImage from '../../UploadImage/imageRoutes';
import stylesButtons from '../../../styles/buttons';
import Search from '../Search';
import mapStyles from '../../../styles/mapStyles';
import { useHistory } from 'react-router-dom'
import Switch from "react-switch";
import Auth from '../../../services/auth'
import { MarkerOptions } from './styles';
import defaultImage from '../../../assets/images/defaultImage.jpg';
const mapContainerStyle = {
    width: "100%",
    height: "100%",
}
const options = {
    disableDefaultUI: false,
    zoomControl: true,
    styles: mapStyles
}

const center = {
    lat: -23.09744828257539,
    lng: -47.228578108868085
}
const libraries = ["places"];

export default function Map({ setRoutes, setShowModalEdit, markers, setMarkers, setTotalDistance, totalDistance, markersRef, directions, setDirections, handleFindWaypoints, checked, setChecked, nome, setNome, cor, setCor, cor_hex, setCor_hex, descricao, setDescricao, descricao_ponto, setDescricao_ponto }) {

    const history = useHistory();
    const [token, setToken] = useState(Auth.token());
    const [id, setId] = useState('');
    const [endereco, setEndereco] = useState('');
    const [distancia_relativa, setDistancia_relativa] = useState('');
    const [sequencia_itinerario, setSequencia_itinerario] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [foto_ponto, setFoto_ponto] = useState([]);
    const [default_foto_ponto, setDefault_Foto_ponto] = useState(true);
    const [url_foto_ponto, setUrl_foto_ponto] = useState([]);
    const [selected, setSelected] = useState(null);
    const [indexSelected, setIndexSelected] = useState(null);
    const [backupWaypoint, setBackupWaypoint] = useState('')
    const [isEdded, setIsEdded] = useState(false);
    const [haveImage, setHaveImage] = useState(false);
    const [waypoints, setWaypoints] = useState([]);
    const [loading, setLoading] = useState(true);
    const [markerMap, setMarkerMap] = useState({});
    const [id_waypoint, setId_waypoint] = useState('');
    const [fotos, setFotos] = useState([]);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
        libraries,
    });
    const mapRef = useRef();
    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    const markerLoadHandler = (marker, place) => {
        return setMarkerMap(prevState => {
            return { ...prevState, [place.id]: marker };
        });
    };

    const panTo = React.useCallback(({ lat, lng, formatted_address }) => {
        // setLatitude(lat);
        // setLongitude(lng);
        // let newList = [];
        // newList = markers;
        // newList[indexSelected].lat = latitude;
        // newList[indexSelected].lng = longitude;
        // newList[indexSelected].endereco = formatted_address;
        // setMarkers(newList);

    }, []);

    useEffect(() => {
        if (foto_ponto.length) {
            setHaveImage(true);
            foto_ponto.map(file => {
                fotos[selected.index] = file;
                markersRef.current[selected.index].foto = `https://acibike.fiecdev.com.br:25555/routesphoto/${id}_${selected.index + 1}.jpeg`
                url_foto_ponto[selected.index] = URL.createObjectURL(file);
            });
        } else {
            setHaveImage(false);
        }
    }, [foto_ponto])

    var handleCalcTravel = useCallback((marker) => {
        if (marker) {
            markersRef.current.push({
                id: marker.id,
                nome: `ponto - ${markersRef.current.length + 1}`,
                descricao: marker.descricao,
                endereco: marker.endereco,
                sequencia_itinerario: markersRef.current.length + 1,
                distancia_relativa: marker.distancia_relativa ? marker.distancia_relativa : 0,
                lat: marker.lat,
                lng: marker.lng,
                foto: marker.foto,
                start_address: marker.endereco,
                end_address: '',
            });
        }
    }, [])

    const handleDelete = useCallback((selected) => {
        swal({
            title: "Você tem certeza?",
            text: "Esta ação irá deletar o último ponto no mapa!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                if (markers.length > 3) {
                    markers.pop();
                    markersRef.current.pop();
                    handleRoute();
                    swal("Ponto deletado com sucesso!", {
                        icon: "success",
                    });
                } else {
                    swal('Atenção!', "Rota deve possuir pelo menos 3 pontos!", 'warning');
                }
            } else {
                swal("Rota não deletada!")
            }
        });
    }, []);

    const onMapClick = useCallback((event) => {
        api.post(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${event.latLng.lat()},${event.latLng.lng()}&key=AIzaSyBeKgr6W_5u-F7TwNUFg32eFZLmdlNUzBI`).then((response) => {
            markers.push({
                sequencia_itinerario: markers.length + 1,
                id: uniqid(),
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
                formatted_address: response.data.results[0].formatted_address,
                endereco: response.data.results[0].formatted_address
            });
            setIsEdded(true);
            handleCalcTravel(markers[markers.length - 1]);
            handleRoute();
        });
    }, []);

    useEffect(() => {
        setId(markers[0].id_rota)
        markers.forEach((marker, index) => {
            if (index == 0) {
                markersRef.current.push({
                    id: marker.id,
                    nome: `ponto - ${markersRef.current.length + 1}`,
                    descricao: marker.descricao,
                    endereco: marker.endereco,
                    sequencia_itinerario: markersRef.current.length + 1,
                    distancia_relativa: marker.distancia_relativa,
                    lat: marker.lat,
                    lng: marker.lng,
                    foto: marker.foto,
                    start_address: marker.endereco,
                    end_address: '',
                });
            } else {
                handleCalcTravel(markers[index]);
            }
        });
    }, []);


    if (loadError) return "Error Loading Maps";
    if (!isLoaded) return "Loaging Maps";


    //Atualiza no array os novos dados do ponto que o usuario edito
    const handleSaveChange = (e) => {
        e.preventDefault();
        try {
            swal({
                title: "Você tem certeza?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willconfirm) => {
                    if (willconfirm) {
                        if (haveImage === true) {
                            let formData = new FormData();
                            formData.append('waypoints', JSON.stringify(markersRef.current));
                            formData.append('nome', nome);
                            formData.append('descricao', descricao);
                            formData.append('cor_hex', cor_hex);
                            formData.append('cor', cor);
                            formData.append('percurso_total', totalDistance);
                            formData.append('destaque_home', checked);
                            formData.append('directions', JSON.stringify(directions));
                            fotos.forEach((file, index) => {
                                formData.append(`${id}_${index + 1}`, file);
                            });
                            // formData.append('id', id);
                            // formData.append('distancia_relativa', distancia_relativa);
                            // formData.append('endereco', endereco);
                            // formData.append('latitude', latitude);
                            // formData.append('longitude', longitude);
                            // formData.append('descricao', descricao);
                            // formData.append('percurso_total', 0);
                            // formData.append('destaque_home', checked)

                            // var data = {};
                            // var int = 0
                            // formData.forEach((value, key) => {
                            //     // data[key] = value != 'null' || value != 'undefined' ? value : undefined
                            //     // Valor das fotos não está chegando como undefined em si, mas sim como undefined dentro de uma string
                            //     if (value != 'null' || value != 'undefined' || value != null || value != undefined) {
                            //         data[key] = value
                            //         int++
                            //     }
                            // });

                            try {
                                const response = await api.patch(`admin/routes/${id}?auth=${token}`, formData, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data',
                                        Accept: 'multipart/form-data'
                                    }
                                });
                                if (response.data.error) return swal('Atenção!', response.data.error, 'warning')
                                swal('Sucesso!', 'Rota editada!', 'success').then(async () => {
                                    history.goBack();
                                });
                            } catch (error) {
                                // swal('Atenção!', `Serviço indisponível no momento!`, 'error');
                                alert(error + ' 1')
                            }
                        } else {
                            const data = {
                                waypoints: markersRef.current,
                                nome: nome,
                                descricao: descricao,
                                cor: cor,
                                cor_hex: cor_hex,
                                percurso_total: totalDistance,
                                destaque_home: checked,
                                directions: JSON.stringify(directions)
                            }

                            try {
                                const response = await api.patch(`admin/routes/${id}?auth=${token}`, { data });
                                if (response.data.error) return swal('Atenção', response.data.error, 'warning')
                                swal('Sucesso', 'Rota editada!', 'success').then(async () => {
                                    history.goBack();
                                });
                            } catch (error) {
                                console.log(error)
                                // swal({
                                //     title: `Serviço indisponível no momento!`,
                                //     icon: "error",
                                // });
                                alert(error + ' 2')
                            }
                        }

                    } else {
                        swal("Cancelado!");
                    }
                });

        } catch (error) {
            swal({
                icon: 'error',
                text: 'Erro ao salvar as alterções'
            })
            console.log(error)
        }

    }

    /**
     *  Limpa o formulario 
     */
    const clearForm = () => {
        setEndereco('');
        setDistancia_relativa('');
        setSequencia_itinerario('');
        setLatitude('');
        setLongitude('');
        // setUrl_foto_ponto(null);
        setDefault_Foto_ponto(true);
        setHaveImage(false);
        setFoto_ponto([]);
        // setUrl_foto_ponto(null);
    }

    //faz um backup das informações do ponto selecionado
    const makeBackup = (marker) => {
        setBackupWaypoint(marker);
    }

    //descarta todas as altereções que o usuario fez nos dados do ponto selecionado
    const descardChanges = (e) => {
        e.preventDefault();
        setNome(backupWaypoint.nome);
        setDescricao(backupWaypoint.descricao);
        setEndereco(backupWaypoint.endereco);
        setDistancia_relativa(backupWaypoint.distancia_relativa);
        setSequencia_itinerario(backupWaypoint.sequencia_itinerario);
        setLatitude(backupWaypoint.latitude);
        setLongitude(backupWaypoint.longitude);
        // setChecked(backupWaypoint.destaque_home)
    }

    const handleCancel = (e) => {
        e.preventDefault()
        history.push('/admin/routes')
    }

    function handleDeleteImage(element) {
        swal({
            text: "Tem certeza?",
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                markers[selected.index].foto = null;
                markersRef.current[selected.index].foto = null;
                fotos[selected.index] = null;
                url_foto_ponto[selected.index] = null;
                setFoto_ponto([]);
            }
        })
    }

    //Recebe os dados do ponto selecionado pelo usuario e preenche o formulario com os mesmos
    const handleShowWindow = (marker, i) => {
        makeBackup(marker);
        clearForm();
        if (marker) {
            setId_waypoint(marker.id)
            setIndexSelected(i);
            setId(marker.id_rota);
            setDescricao_ponto(marker.descricao);
            setEndereco(marker.endereco);
            setDistancia_relativa(marker.distancia_relativa);
            setSequencia_itinerario(marker.sequencia_itinerario);
            setLatitude(marker.latitude);
            setLongitude(marker.longitude);
            if (marker.imagem) {
                // setUrl_foto_ponto(marker.imagem);
                setDefault_Foto_ponto(false);
            } else {
                setDefault_Foto_ponto(true);
            }
            marker.index = i;
            setSelected(marker)
            setIsEdded(true)
            return true
        }

    }

    // ---------------------------- A PARTIR DAQUI, ADIÇÕES FORAM FEITAS NO CÓDIGO -----------------------------------------------

    function handleRoute() {
        setWaypoints([]);
        const directionsService = new window.google.maps.DirectionsService();

        // const origin = markers[0];
        // const destination = markers[markers.length - 1];
        // markers.map(mark => {
        //     setWaypoints(current => [...current, { location: new window.google.maps.LatLng(mark.lat, mark.lng), stopover: true }])
        // })
        // waypoints.shift();

        const waypoints = markers.map(p => ({
            location: { lat: p.lat, lng: p.lng },
            stopover: true
        }))
        const origin = waypoints.shift().location;
        const destination = waypoints.pop().location;

        if (waypoints.length >= 1) {
            directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    travelMode: window.google.maps.TravelMode.BICYCLING,
                    unitSystem: window.google.maps.UnitSystem.METRIC,
                    optimizeWaypoints: true,
                    waypoints: waypoints
                }, (response, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        const legs = response.routes[0].legs;
                        markersRef.current[markersRef.current.length - 2].end_address = legs[legs.length - 1].end_address;
                        markersRef.current[markersRef.current.length - 2].distancia_relativa = legs[legs.length - 1].distance.value;
                        var total = 0;
                        for (var i = 0; i < legs.length; i++) {
                            total += legs[i].distance.value;
                        }
                        setTimeout(function () {
                            setLoading(true);
                            setTotalDistance(total);
                            setDirections(response);
                            setRoutes(legs);
                            setLoading(false);
                            return true
                        }, 200);
                    } else {
                        console.log(status);
                    }
                }
            )
        }
        else {
            directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    travelMode: window.google.maps.TravelMode.BICYCLING,
                    unitSystem: window.google.maps.UnitSystem.METRIC,
                    optimizeWaypoints: true,
                }, (response, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        const legs = response.routes[0].legs
                        markersRef.current[markersRef.current.length - 2].end_address = legs[legs.length - 1].end_address;
                        markersRef.current[markersRef.current.length - 2].distancia_relativa = legs[legs.length - 1].distance.value;
                        var total = 0;
                        for (var i = 0; i < legs.length; i++) {
                            total += legs[i].distance.value;
                        }
                        setTimeout(function () {
                            setLoading(true);
                            setTotalDistance(total);
                            setDirections(response);
                            setRoutes(legs);
                            setLoading(false);
                            return true
                        }, 200);
                    } else {
                        console.log(status);
                    }
                }
            )
        }
    }

    const handleDrag = (event, index) => {
        setIsEdded(true);
        let newList = [];
        newList = markers;
        newList[index].lat = event.latLng.lat();
        newList[index].lng = event.latLng.lng();
        newList[index].latitude = event.latLng.lng();
        newList[index].longitude = event.latLng.lng();
        setMarkers(newList);
        markersRef.current = newList;
        handleRoute();
    }

    return (
        <>
            <Container>
                <Wapper>
                    <WapperForm>
                        <Form onSubmit={e => handleSaveChange(e)}>
                            <Title>Dados do ponto</Title>
                            <FormContent>
                                <FormGroup>
                                    <input type="text" placeholder="Nome da rota" onChange={e => { setNome(e.target.value); setIsEdded(true); }} value={nome} />
                                    <label>Nome da rota</label>
                                </FormGroup>
                                <FormGroup>
                                    <input type="text" placeholder="Descrição da rota" onChange={e => { setDescricao(e.target.value); setIsEdded(true); }} value={descricao} />
                                    <label>Descrição da rota</label>
                                </FormGroup>
                                <FormGroup>
                                    <input type="text" placeholder="Sequência itinerário" onChange={isEdded ? e => setSequencia_itinerario(e.target.value) : null} value={sequencia_itinerario} disabled />
                                    <label>Sequência itinerário</label>
                                </FormGroup>
                                <FormGroup>
                                    <input type="text" placeholder="Endereço" onChange={isEdded ? e => setEndereco(e.target.value) : null} value={endereco} disabled />
                                    {/* O campo comentado abaixo tem algum propósito de estar ali, e ele está usando uma função comentada lá em cima também, portanto, conversar com o Giba sobre */}
                                    {/* <Search panTo={panTo} /> */}
                                    <label>Endereço</label>
                                </FormGroup>
                                <FormGroup>
                                    <input type="text" placeholder="Descrição do ponto" onChange={isEdded ? e => { setDescricao_ponto(e.target.value); markers[selected.index].descricao = descricao_ponto; markersRef.current[selected.index].descricao = descricao_ponto; } : null} value={descricao_ponto} disabled={selected ? false : true} />
                                    <label>Descrição do ponto</label>
                                </FormGroup>
                                <FormGroup>
                                    <select
                                        onChange={e => { setCor_hex(e.target.value); setIsEdded(true); }}
                                        value={cor_hex}
                                    >
                                        <option value="#ff0000">Vermelho</option>
                                        <option value="#00ffff">Ciano</option>
                                        <option value="#ff1493">Rosa</option>
                                        <option value="#ffa500">Laranja</option>
                                        <option value="#7CFC00">Verde</option>
                                        <option value="#7B68EE">Roxo</option>
                                        <option value="#F0E68C">Khaki</option>
                                        <option value="#F08080">Coral</option>
                                        <option value="#3CB371">Oceano</option>
                                        <option value="#009aff">Azul</option>
                                        <option value="#D2691E">Chocolate</option>
                                        <option value="#ffff40">Amarelo</option>
                                    </select>
                                    <label>Cor</label>
                                </FormGroup>
                                <FormGroup>
                                    <input type="text" placeholder="Distância Relativa" value={totalDistance + ' m'} disabled />
                                    <label>Distância Total</label>
                                </FormGroup>
                                {/* <FormGroup>
                                    <input type="text" placeholder="Latitude" value={latitude} disabled />
                                    <label>Latitude</label>
                                </FormGroup>
                                <FormGroup>
                                    <input type="text" placeholder="Longitude" value={longitude} disabled />
                                    <label>Longitude</label>
                                </FormGroup> */}
                                <FormGroup>
                                    <p style={{ marginBottom: 10 }}>Deseja que essa rota seja destaque na tela home?</p>
                                    <Switch
                                        onChange={() => { setChecked(!checked); setIsEdded(true); }}
                                        checked={checked}
                                    />
                                    <label>Destaque</label>
                                </FormGroup>
                            </FormContent>
                        </Form>
                    </WapperForm>
                    <WapperImages>
                        <TitlePhoto>Foto do local</TitlePhoto>
                        <ImageGroup>
                            {selected && markers[selected.index].foto ?
                                <BodyImage>
                                    {/* <img onError={e => { e.currentTarget.src = default_foto_ponto; }} src={markers[selected.index].foto} data-tooltip="Foto do local" /> */}
                                    <img onError={e => { e.currentTarget.src = defaultImage }} src={markers[selected.index].foto} alt={'Erro'} />
                                    <IoTrashOutline color="#F00" size={26} style={{ cursor: 'pointer' }} onClick={() => { handleDeleteImage('Foto do local') }} />
                                </BodyImage>
                                :
                                selected && url_foto_ponto[selected.index] ?
                                    <BodyImage>
                                        {/* <img onError={e => { e.currentTarget.src = default_foto_ponto; }} src={markers[selected.index].foto} data-tooltip="Foto do local" /> */}
                                        <img onError={e => { e.currentTarget.src = defaultImage }} src={url_foto_ponto[selected.index]} />
                                        <IoTrashOutline color="#F00" size={26} style={{ cursor: 'pointer' }} onClick={() => { handleDeleteImage('Foto do local') }} />
                                    </BodyImage>
                                    :
                                    selected ?
                                        (<UploadImage amount={1} msg={`(Foto do local) `} setFile={setFoto_ponto} />)
                                        :
                                        <img src={defaultImage} />
                            }
                        </ImageGroup>
                    </WapperImages>
                    <WapperMap>
                        <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            zoom={14}
                            center={center}
                            options={options}
                            onClick={onMapClick}
                            onLoad={onMapLoad}
                            ref={mapRef}
                            formatted
                        >
                            {markers ? markers.map((marker, index) =>
                                <Marker style={{ visualViewport }} key={marker.id}
                                    options={{
                                        draggable: true,
                                        preserveViewport: false,
                                        markerOptions: {
                                            crossOnDrag: true,
                                            clickable: true,
                                        },
                                    }}
                                    // onLoad={marker2 => {
                                    //     const customIcon = (opts) => Object.assign({
                                    //       path: 'M 128.00,0.00 C 86.49,0.00 38.05,25.37 38.05,89.95 38.05,133.77 107.24,230.63 128.00,256.00 146.45,230.63 217.95,136.07 217.95,89.95 217.95,25.37 169.51,0.00 128.00,0.00 Z',
                                    //       fillColor: '#34495e',
                                    //       fillOpacity: 1,
                                    //       strokeColor: '#000',
                                    //       strokeWeight: 1,
                                    //       scale: 0.14,
                                    //     }, opts);

                                    //     marker2.setIcon(customIcon({
                                    //       fillColor: 'blue',
                                    //       strokeColor: 'white'
                                    //     }));
                                    //     return markerLoadHandler(marker2, marker)
                                    //   }}
                                    label={
                                        { text: marker.sequencia_itinerario + "", color: "white" }
                                    }
                                    position={{ lat: marker.lat, lng: marker.lng }}
                                    onDragEnd={(e) => { handleDrag(e, index); }}
                                    onClick={() => { handleShowWindow(marker, index) }}
                                />
                            ) : null}
                            {directions ?
                                <DirectionsRenderer
                                    key={uniqid()}
                                    directions={directions}
                                    options={{
                                        polylineOptions: {
                                            strokeColor: cor_hex,
                                            strokeWeight: 4
                                        },
                                        draggable: false,
                                        suppressMarkers: true,
                                        suppressInfoWindows: true,
                                        preserveViewport: false,
                                        markerOptions: {
                                            crossOnDrag: true,
                                            clickable: false,
                                        }
                                    }}
                                />
                                : null}
                        </GoogleMap>
                    </WapperMap>

                </Wapper>
                <Options>
                    {isEdded ?
                        <>
                            <button type="submit" onClick={e => handleSaveChange(e)} style={stylesButtons.save}>Salvar</button>
                            <button type="submit" onClick={e => handleCancel(e)} style={stylesButtons.cancel}>Cancelar</button>
                            <button type="submit" onClick={e => descardChanges(e)} style={{ background: '#882d17' }}>Descartar</button>
                            <button type="submit" onClick={e => handleDelete(e)} style={stylesButtons.cancel}>Deletar Ponto</button>
                        </>
                        : <Msg><p>Selecione um ponto no mapa para editar</p></Msg>
                    }
                </Options>
            </Container>
        </>
    );
}