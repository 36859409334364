import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import { Background, ModalContent, CloseModalButton, Options, Image, WapperImages, TitlePhoto, BodyImage, ImageGroup } from './styles';
import { IoTrashOutline } from "react-icons/io5";
import UploadPdf from '../UploadPdf';
import swal from 'sweetalert';
import Auth from '../../services/auth'
import api from '../../services/api'
import pdfIcon from '../../assets/images/pdf.png';

export default function ModalPdf({ showModal, setShowModal, type, image, handleDeletePdf, nameFile, isPreview, id }) {
    const [pdf, setPdf] = useState([]);
    const [url_pdf, setUrl_pdf] = useState(null);
    const [havePdf, setHavePdf] = useState(false);
    const [token, setToken] = useState(Auth.token());

    const modalRef = useRef();
    const animation = useSpring({
        config: {
            duration: 150
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`
    });

    const keyPress = useCallback(e => {
        if (e.key === 'Escape' && showModal) setShowModal(!showModal);
    }, [setShowModal, showModal]);

    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);

    useEffect(() => {
        if (pdf.length) {
            setHavePdf(true);
        } else {
            setHavePdf(false);
        }
        if (pdf.length) pdf.map(file => setUrl_pdf(URL.createObjectURL(file)));
    }, [pdf]);

    function handleDeletePdf() {
        swal({
            text: "Tem certeza?",
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                setUrl_pdf(null);
                setPdf([]);
            }
        });
    }

    async function handleSubmit(e) {
        try {
            if (havePdf) {
                let formData = new FormData();
                if (pdf.length) {
                    pdf.forEach(file => formData.append(`${type}`, file));
                }
                const url = type === "termos_uso" ? "admin/terms" : "admin/privacy";
                const response = await api.post(`${url}?auth=${token}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Accept: 'multipart/form-data'
                    }
                });
                if (response.data.error) return swal("Atenção", response.data.error, "warning");
                swal("Atenção", response.data.success, "success");
                setTimeout(() => {
                    window.location.reload(false);
                }, 700);
            } else {
                swal("Atenção", "Imagem não alterada!", "warning");
            }
        } catch (error) { swal("Atenção", 'Serviço indisponível no momento', "error") }
    }

    return (
        <>
            {showModal ? (
                <Background ref={modalRef} ref={modalRef} onClick={(e) => { if (modalRef.current === e.target) { setShowModal(!showModal) } setUrl_pdf(null) }}>
                    <animated.div style={animation}>
                        <WapperImages>
                            <TitlePhoto>PDF</TitlePhoto>
                            <ImageGroup>
                                {havePdf
                                    ?
                                    <BodyImage>
                                        <img src={pdfIcon} data-tooltip="Pdf" />
                                        <IoTrashOutline color="#F00" size={26} style={{ cursor: 'pointer' }} onClick={() => { handleDeletePdf() }} />
                                    </BodyImage>
                                    : (
                                        <UploadPdf amount={1} msg={`(Pdf) `} setFile={setPdf} />
                                    )}
                            </ImageGroup>
                            <button onClick={() => { handleSubmit() }}>Salvar</button>
                        </WapperImages>
                        {/* <Options>
                                <IoTrashOutline color="#F00" style={{ top: '-20px', fontSize: '1.5rem', color: 'red', cursor: 'pointer' }} onClick={isPreview == true ? () => handleDeletePdf(image, nameFile, true, true) : (() => { handleDeletePdf(image, nameFile, true) })} />
                            </Options> */}
                        <CloseModalButton onClick={() => { setShowModal(!showModal); handleDeletePdf(); }} />
                    </animated.div>
                </Background>
            ) : null}
        </>
    );
}