import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Content, Form, FormGroup, LinkGroup } from './styles';
import GoBack from '../../components/BackToMenu/index'
import api from '../../services/api';

export default function Recover(){
    const [data, setData] = useState({});
    const history = useHistory();
    async function handleSubmit(e){
        e.preventDefault();
        try{
            const response = await api.post('recover', data);
            if(response.data.error) return alert(response.data.error);
            alert(response.data.success);
            history.push('/login');
        } catch(error){
            alert(`Serviço indisponível no momento!`);
        }
    }
    return(
        <Container>
            <GoBack/>
            <Content>
                <Form onSubmit={handleSubmit}>
                    <h1>Recuperar Senha</h1>
                    <FormGroup>
                        <input
                            type="email"
                            placeholder="Email"
                            value={data.email}
                            onChange={e => setData({ ...data, email: e.target.value })}
                            maxLength="150"
                            required
                        />
                        <label>Email</label>
                    </FormGroup>
                    <FormGroup>
                        <input
                            type="text"
                            placeholder="Nome"
                            value={data.nome}
                            onChange={e => setData({ ...data, nome: e.target.value })}
                            minLength="3"
                            maxLength="100"
                            required
                        />
                        <label>Nome</label>
                    </FormGroup>
                    <FormGroup>
                        <input
                            type="date"
                            placeholder="Data de nascimento"
                            value={data.data_nascimento}
                            onChange={e => setData({ ...data, data_nascimento: e.target.value })}
                            required
                        />
                        <label>Data de nascimento</label>
                    </FormGroup>
                    <button>Enviar</button>
                    <LinkGroup>
                        <Link to="/login">Lembrou a senha?</Link>
                    </LinkGroup>
                </Form>
            </Content>
        </Container>
    );
}