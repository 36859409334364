import React from 'react';
import { TableContainer, TableHead, TableBody } from './styles';
export default function TableRoutes({ markers }) {

    return (
        <TableContainer>
            <TableHead>
                <tr>
                    <th>Ponto n°</th>
                    <th>De</th>
                    <th>Até</th>
                    <th>Distância</th>
                </tr>
            </TableHead>
            <TableBody>
                {markers ? markers.map((mark) => (
                    <tr key={mark.id} >
                        <td data-title='Ponto n°'>{`${mark.sequencia_itinerario}°`}</td>
                        <td data-title='De'>{mark.endereco != null ? `${mark.endereco.substring(0, 20)}...` : (`---`)}</td>
                        <td data-title='Até'>{mark.end_address != null ? `${mark.end_address.substring(0, 20)}...` : (`fim`)}</td>
                        <td data-title='Distância'>{mark.distancia_relativa ? `${mark.distancia_relativa}m` : (`0`)}</td>
                    </tr>
                )) : (
                    <tr >
                        <td data-title='Ponto n°'>-</td>
                        <td data-title='De'>-</td>
                        <td data-title='Até'>-</td>
                        <td data-title='Distância'>-</td>
                    </tr>
                )}
            </TableBody>
        </TableContainer>
    );
}