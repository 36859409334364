import React from 'react';
import { Container } from './styles'
import Logo from '../../assets/images/logo.ico'

export default function TermsOfUse(){
    return(
        <Container>
            <img src={Logo} style={{marginTop: 10}}/>
            <h1 style={{marginTop: 20}}>ACI Bike - Associação Dos Ciclistas De Indaiatuba</h1>
            <p style={{marginTop: 20, fontSize: 20, textAlign: "justify", whiteSpace: "pre-line"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sodales lectus eget odio aliquam scelerisque vel non purus. Vestibulum justo ex, ullamcorper hendrerit nibh sed, mattis malesuada mi. Duis et consectetur velit, non sollicitudin leo. Duis luctus eu orci id tincidunt. Sed sed ipsum volutpat, eleifend nisl nec, volutpat orci. Ut sodales, libero ultricies volutpat vestibulum, nisi purus viverra ligula, at suscipit quam est placerat dolor. Nulla vulputate est vel felis posuere, tempus commodo metus convallis. Aenean efficitur condimentum purus, vel tempor lectus hendrerit a. Etiam non fringilla ex. Curabitur libero ex, interdum ultricies sapien et, dictum scelerisque quam. Fusce facilisis, orci vel dapibus aliquet, diam lectus congue mi, nec vestibulum nunc ipsum et nisl.

Integer sollicitudin, erat ut tempor tincidunt, urna mauris luctus elit, in pulvinar felis dolor eget dolor. Integer pellentesque metus in augue pretium dapibus. Phasellus commodo massa a augue euismod faucibus. Vivamus ac sem mauris. Integer pharetra ipsum non vulputate molestie. Nullam non magna lectus. Fusce quis eros dapibus, blandit libero ac, pretium tortor. Sed sit amet bibendum libero. Nunc id arcu ut turpis consequat porttitor. Integer accumsan volutpat tempus. Curabitur semper, nulla in efficitur vestibulum, felis lorem tincidunt nibh, eget iaculis purus turpis sit amet sapien. Proin interdum neque non placerat laoreet. Praesent maximus ut nisi eu congue.

Nulla a nulla sed mi fringilla egestas. Pellentesque rutrum egestas velit sed mollis. Proin egestas posuere odio. Donec commodo commodo imperdiet. Etiam felis tortor, finibus vel facilisis id, porttitor at arcu. Integer eu lectus neque. Sed euismod ullamcorper eleifend. Quisque a pellentesque tortor, in lobortis nulla. Mauris euismod consequat elementum. Integer nunc ipsum, dictum in arcu quis, varius tincidunt ante. Fusce blandit, ante eu egestas rutrum, ex ex gravida ligula, ac fringilla felis diam vel nisl. Proin hendrerit odio vitae eros eleifend tempor. Aenean viverra et mi ac congue. Nullam at placerat nisi. Sed nec accumsan metus.

Nulla sem dolor, rhoncus eget urna non, iaculis luctus quam. Vestibulum eu pulvinar ex. Phasellus id facilisis purus. Pellentesque id risus sollicitudin erat pharetra pulvinar ac vel ligula. Proin ut quam metus. Maecenas scelerisque turpis eu augue euismod pretium nec vel elit. Aenean lobortis elit ante, quis dictum ipsum varius ut. Phasellus euismod, dui sed varius ultricies, erat quam commodo neque, in iaculis neque justo ac mi. Sed malesuada arcu non tempor interdum. Suspendisse eleifend erat sit amet faucibus euismod. Aenean a luctus nibh, eu tempus sapien.

Aliquam commodo nulla vel velit auctor porttitor. Sed ullamcorper egestas ante, non hendrerit elit laoreet eget. Curabitur non urna vitae nunc sollicitudin cursus a quis purus. Curabitur velit lorem, tincidunt eu erat in, rhoncus egestas orci. Donec scelerisque purus in dignissim sagittis. Morbi porttitor ipsum non hendrerit blandit. Suspendisse id aliquam nisi. Vestibulum lacinia condimentum nisl ac mollis. Duis volutpat scelerisque dolor sit amet fringilla. Aenean justo lacus, elementum ut bibendum eget, vulputate et odio. Integer pretium hendrerit massa ut aliquam. Sed tempor viverra ante eu laoreet. Suspendisse elit purus, malesuada lobortis auctor sit amet, volutpat sit amet urna. Sed blandit diam quam, at finibus felis porttitor a. Maecenas eget rhoncus nibh, sed lacinia arcu. Pellentesque semper commodo ligula, eu pharetra nunc fringilla vitae.</p>
        </Container>
    )
}