import React, { useState, useEffect } from 'react';
import Heading from '../../components/Heading';
import Card from '../../components/Card/Bikes';
import Pagination from '../../components/Pagination';
import api from '../../services/api'
import Filters from '../../components/Filters/Routes';
import { Container } from './styles';
import swal from 'sweetalert'
import { Link, useHistory, useLocation } from 'react-router-dom';
import { routes } from '../../assets/json/routes.json';
import jwt from 'jsonwebtoken';
import jwt_decode from "jwt-decode";
import ModalEdit from '../../components/Modal/Bicycles/'
import ModalRegister from '../../components/Modal/Bicycles/Register/'
import Auth from '../../services/auth'
import { Logout } from '../../services/logout';

export default function Bikes() {


    const location = useLocation()
    const history = useHistory()
    const [id, setId] = useState();
    const [bikes, setBikes] = useState([]);
    const [bikeSelected, setBikeSelected] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [showModalRegister, setShowModalRegister] = useState(false);
    const [alreadyLoaded, setAlreadyLoaded] = useState(false);
    const [filters, setFilters] = useState({ show: 'TODOS', search: '' });
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [reload, setReload] = useState(false);
    const [token, setToken] = useState(Auth.token())

    function handleClicked(chassi) {
        setShowModalEdit(true);
        setBikeSelected(chassi);
        // history.push({
        //     pathname: `/bikes/${id}`,
        //     state: id
        // })
    }

    async function handleDelete(id, chassi) {
        try {
            swal({
                title: "Você tem certeza?",
                text: "Uma vez deletado a bike terá que ser cadastrada novamente",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {
                        await api.delete(`/bikes/${chassi}?auth=${token}`)
                        setBikes(bikes.filter(bike => bike.id !== id));
                        swal("Bike deletada com sucesso!", {
                            icon: "success",
                        })
                    } else {
                        swal("Bike não deletada!")
                    }
                })
        } catch (error) { alert('Erro ao deletar bike!'); }
    }

    // useEffect(() => {
    //     !alreadyLoaded && setLoading(true);
    //     api.get(`bikes/?_tipo=${filters.show}&_search=${filters.search}&_page=${page}`).then(response => {
    //         setBikes(response.data.users);
    //         setTotalPages(response.data.totalPages);
    //         !alreadyLoaded && setLoading(false); !alreadyLoaded && setAlreadyLoaded(true);
    //     }).catch(error => {alert(error); setLoading(false); setError(true);});
    // }, [filters, page, reload, alreadyLoaded]);

    useEffect(() => {
        const tokenLogin = localStorage.getItem('token');
        jwt.verify(tokenLogin, 'acibike@citec');
        var decoded = jwt_decode(tokenLogin);
        setId(decoded.id)
        api.get(`bikes/${id}?_page=${page}&auth=${token}`).then(response => {
            setBikes(response.data.bikes)
            setTotalPages(response.data.totalPages);
        }).catch(error => {
            // swal('Atenção!', error, 'error')
            if (error.response.status === 401) {
                Logout();
            }
        })
    }, [id, page])

    return (
        <>
            <Heading />
            <Container>
                <button
                    style={{ marginBottom: 15 }}
                    onClick={() => {
                        setShowModalRegister(true);
                    }}
                >Cadastrar bike</button>
                {
                    bikes.length
                        ?
                        <>
                            <Card
                                loading={loading}
                                error={error}
                                repeatLoading={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
                                data={bikes}
                                list={['modelo', 'chassi', 'status']}
                                handleClicked={handleClicked}
                                handleDelete={handleDelete}
                            />
                            <Pagination
                                page={page}
                                setPage={setPage}
                                totalPages={totalPages}
                            />
                            {/* <Filters
                                    filters={filters}
                                    setFilters={setFilters}
                                    data={['TODOS', 'ADMINISTRADOR', 'CICLISTA', 'GUARDA' ]}
                                /> */}
                        </>
                        :
                        <>
                            <h1>Não há bikes cadastradas</h1>
                        </>
                }
                <ModalEdit
                    showModal={showModalEdit}
                    setShowModal={setShowModalEdit}
                    id={bikeSelected}
                />
                <ModalRegister
                    showModal={showModalRegister}
                    setShowModal={setShowModalRegister}
                // id={bikeSelected}
                />
            </Container>
        </>
    )
}