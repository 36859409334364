import React from 'react';
import { TableContainer, TableHead, TableBody } from './styles';
import { IoSearch, IoAddOutline, IoPencilOutline, IoTrashOutline } from "react-icons/io5";
import { useHistory } from 'react-router';

/*
    Esse component recebe os dados e rendireza uma tabela com os compos pedidos
        parametros:{
            data: Um array com os dados que deseja renderizar.
            list: Os campos do array que dejesa mostrar na tabela.
            handleClicked: função que recebe o ID do item da linha da tabela que o usuario clicar.
        } 
*/
export default function Table({ data, list, handleClicked, handleDelete }) {
    const history = useHistory();

    return (
        <TableContainer>
            <TableHead>
                <tr>
                    {/* {list.map((l, i) => (
                        <th key={i}>{l}</th>
                    ))} */}
                    <th>STATUS</th>
                    <th>TIPO</th>
                    <th>RECEBIMENTO</th>
                    <th>ENCERRAMENTO</th>
                    <th>RESULTADO</th>
                    <th style={{ width: 77 }}>Editar</th>
                    <th style={{ width: 77 }}>Excluir</th>
                </tr>
            </TableHead>
            <TableBody>
                {data.map((d, i) => (
                    <tr key={i}>
                        {/* {list.map((l, i) => (
                            <td key={i} data-title={l}>{d[l]}</td>
                        ))} */}
                        <td data-title='Status'>{d.status}</td>
                        <td data-title='Tipo ocorrência'>{d.tipo}</td>
                        <td data-title='Data recebimento'>{d.data_recebimento}</td>
                        {d.data_encerramento
                            ?
                            <td data-title='Data encerramento'>{d.data_encerramento}</td>
                            :
                            <td>-</td>
                        }
                        {d.resultado
                            ?
                            <td data-title='Resultado'>{d.resultado}</td>
                            :
                            <td>-</td>
                        }
                        {/* {d.tipo === 'FRAUDE'
                            ?
                            <td data-title='Editar'><IoPencilOutline
                                color='red'
                                onClick={() => {
                                    history.push(`/admin/users?email=${d.email}`)
                                }
                                }
                            /></td>
                            : */}
                            <td data-title='Editar'><IoPencilOutline
                                color='blue'
                                onClick={() => {
                                    handleClicked(d.id)
                                }
                                }
                            /></td>
                        <td data-title='Excluir'><IoTrashOutline
                            color='red'
                            onClick={async () => {
                                await handleDelete(d.id)
                            }}
                        /></td>
                    </tr>
                ))}
            </TableBody>
        </TableContainer>
    );
}