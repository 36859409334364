import styled from 'styled-components';

// export const Container = styled.div`
//     width: 100%;
//     min-height: 87.9vh;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     margin-bottom: 70px;
//     /* h1{
//         font-size: 52px;
//         font-weight: bold;
//         font-family: Arial, Helvetica, sans-serif;
//         top: -15px;
//         position: relative;
//     } */
//     @media screen and (max-width: 768px){
//         width: 100vw;    
//     }
// `;

export const Container = styled.div`
    width: 100%;
    min-height: 87.9vh;
    padding: 4rem calc((100vw - 1000px) / 2) 0 calc((100vw - 1000px) / 2);
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 768px){
        width: 100vw;    
    }
`;

export const Title = styled.h1`
    /* text-transform: uppercase; */
    font-size: 2.5rem;
    color: var(--dark-color);
    margin-top: 1.5rem;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    border-bottom: 0.25rem solid var(--dark-color);
    border-radius: var(--border-radius);
`;