import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { routes } from '../../assets/json/routes.json';
import { Nav, NavName, Bars, NavResponsive, NavProfile, NavMenu, NavLink, NavOptions, ItemOption, ItemOptionLink } from './styles';
import { IoLogoFacebook, IoChatboxEllipsesOutline, IoWarningOutline, IoSettingsOutline, IoLogOutOutline, IoLogoInstagram, IoAlertCircleSharp, IoReaderOutline } from "react-icons/io5";
import { MdForum } from 'react-icons/md';
import profileImg from '../../assets/images/profile.png';
import jwt_decode from "jwt-decode";
import jwt from 'jsonwebtoken';
import { isAuthenticated, isAuthenticatedAdmin } from '../../auth';
import Logo from '../../assets/images/logo.ico'
import Auth from '../../services/auth'
import api from '../../services/api'

export default function Navbar() {
    const [auth, setAuth] = useState(Auth.token());
    const [open, setOpen] = useState(false);
    const [hidden, setHidden] = useState(false);
    const [user, setUser] = useState({});
    const [idUsuario, setIdUsuario] = useState();
    let location = useLocation();

    useEffect(() => {
        if(location.pathname.startsWith("/routes/") || location.pathname.startsWith("/admin/routes/edit") || location.pathname.startsWith("/news/") || routes.includes(location.pathname)) {
            setHidden(false);
        } else {
            setHidden(true);
        }
        // setHidden(false);
        localStorage.getItem('token') && (location.pathname.startsWith("/routes/") || location.pathname.startsWith("/admin/routes/edit") || location.pathname.startsWith("/news/") || routes.includes(location.pathname)) ? (
            setUser(jwt_decode(localStorage.getItem('token'))) // Aqui que deve ser chamado só o primeiro nome
        ) : setUser({ nome: 'Visitante' })
    }, [location.pathname]);

    useEffect(() => {
        setAuth(Auth.token())
    }, [localStorage.getItem("token")])

    function logout() {
        try {
            var auth2 = auth;
            api.patch(`/logout?auth=${auth2}`).then(response => {
                if (response.error) console.log(response.error);
                else {
                    // setTimeout(() => {
                    localStorage.removeItem('token')
                    // }, 3000);
                }
            }).catch(error => console.log(error))
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Nav hidden={hidden} open={open}>
            <NavName to={user.email ? '/profile' : '/login'} open={open}>{user.nome}</NavName>
            <Bars open={open} onClick={() => setOpen(!open)}>
                <div />
                <div />
                <div />
            </Bars>
            <NavResponsive open={open}>
                <NavProfile>
                    <div>
                        {/* <img src={user.foto ? user.foto : profileImg} alt={user.nome} /> */}
                        {/* <img src={`https://acibike.fiecdev.com.br:25555/usersphoto/id.png?auth=${auth}`} alt={'erro'} /> */}
                        <img onError={e => { e.currentTarget.src = profileImg; }} src={user.id ? `https://acibike.fiecdev.com.br:25555/usersphoto/${user.id}.jpeg?auth=${auth}` : profileImg} alt={'Erro'} />
                    </div>
                    {/* Esse link já está estilizado com o cursor: pointer */}
                    {/* <Link to={user.email ? '/profile' : '/login'}>{user.nome}</Link> */}
                    <Link to={user.email ? '/profile' : '/login'} /*style={{ cursor: 'default' }}*/>{user.nome}</Link>
                    <span>{user.tipo ? user.tipo : 'VISITANTE'}</span>
                </NavProfile>
                {isAuthenticated() ? (
                    <NavMenu>
                        {/* <NavLink exact to="/" onClick={() => setOpen(!open)}>Home</NavLink> */}
                        <NavLink exact to="/home" onClick={() => setOpen(!open)}>Home</NavLink>
                        <NavLink exact to="/routes" onClick={() => setOpen(!open)}>Rotas</NavLink>
                        <NavLink exact to="/news" onClick={() => setOpen(!open)}>Notícias</NavLink>
                        <NavLink exact to="/partners" onClick={() => setOpen(!open)}>Parceiros</NavLink>
                        <NavLink exact to="/bikes" onClick={() => setOpen(!open)}>Bicicletas</NavLink>
                    </NavMenu>
                ) : (
                    <NavMenu>
                        <NavLink exact to="/" onClick={() => setOpen(!open)}>Início</NavLink>
                        <NavLink exact to="/login" onClick={() => setOpen(!open)}>Logar</NavLink>
                        <NavLink exact to="/register" onClick={() => setOpen(!open)}>Registrar</NavLink>
                    </NavMenu>
                )}
                {isAuthenticatedAdmin() ? (
                    <NavOptions>
                        <ItemOptionLink to="/occurrences" title='Registrar ocorrência' onClick={() => setOpen(!open)}>
                            <IoAlertCircleSharp color='red' />
                        </ItemOptionLink>
                        <ItemOptionLink to="/admin" title='Admin' onClick={() => setOpen(!open)}>
                            <IoSettingsOutline />
                        </ItemOptionLink>
                        <ItemOptionLink to="/messages" title='Fale conosco' onClick={() => setOpen(!open)}>
                            <MdForum />
                        </ItemOptionLink>
                        <ItemOptionLink to="/login" title='Logout' onClick={() => logout()} /*onMouseOver={() => }*/>
                            <IoLogOutOutline />
                        </ItemOptionLink>
                    </NavOptions>
                ) : isAuthenticated() ? (
                    <NavOptions>
                        <ItemOptionLink to="/occurrences" title='Registrar ocorrência' onClick={() => setOpen(!open)}>
                            <IoAlertCircleSharp color='red' />
                        </ItemOptionLink>
                        <ItemOptionLink to="/messages" title='Fale conosco' onClick={() => setOpen(!open)}>
                            <MdForum />
                        </ItemOptionLink>
                        <ItemOption href="https://www.facebook.com/acindaiatuba/" target="_blank" rel="noreferrer" onClick={() => setOpen(!open)}>
                            <IoLogoFacebook />
                        </ItemOption>
                        <ItemOption href="https://www.instagram.com/aci.bike/" target="_blank" rel="noreferrer" onClick={() => setOpen(!open)}>
                            <IoLogoInstagram />
                        </ItemOption>
                        <ItemOptionLink to="/login" title='Logout' onClick={() => logout()}>
                            <IoLogOutOutline />
                        </ItemOptionLink>
                    </NavOptions>
                ) : (
                    <NavOptions>
                        {/* Talvez tirar o ícone do insta para poder ficar com menos ícones */}
                        <ItemOptionLink to="/messages" title='Fale conosco' onClick={() => setOpen(!open)}>
                            <MdForum />
                        </ItemOptionLink>
                        <ItemOptionLink to="/faq" title='FAQ' onClick={() => setOpen(!open)}>
                            <IoChatboxEllipsesOutline />
                        </ItemOptionLink>
                        <ItemOption href="https://www.facebook.com/acindaiatuba/" target="_blank" rel="noreferrer" title='Facebook' onClick={() => setOpen(!open)}>
                            <IoLogoFacebook />
                        </ItemOption>
                        <ItemOption href="https://www.instagram.com/aci.bike/" target="_blank" rel="noreferrer" title='Instagram' onClick={() => setOpen(!open)}>
                            <IoLogoInstagram />
                        </ItemOption>
                    </NavOptions>
                )}
            </NavResponsive>
        </Nav>
    );
}