import React from 'react';
import notFound from '../../assets/gifs/404.gif'

export default function PageNotFound(){
    return(
        <div>
            {/* Posicionamento e imagem provisórios */}
            <img src={notFound} alt='pageNotFound' style={{width: '50%', height: '50%', marginLeft: '330px', marginTop: '130px'}}/>
        </div>
    );
}

