import React from 'react';
import { Container } from './styles';
import Heading from '../../components/Heading';
import About from '../../components/About';
import Footer from '../../components/Footer/index'

export default function Index(){
    return(
        <>
            <Heading />
            <Container>
                <About />
            </Container>
            {/* Sem o footer provisoriamente */}
            {/* <Footer/> */}
        </>
    );
}