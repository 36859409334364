import React, { useState, useEffect } from 'react'
import { IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
import { Container } from './styles'
import Logo from '../../assets/images/logo.ico'
import api from '../../services/api'
import swal from 'sweetalert'
import { useLocation } from 'react-router-dom'

export default function Verify(){

    const [codeCorrect,setCodeCorrect] = useState(window.location.pathname);
    const [verified,setVerified] = useState(false)

    const code = codeCorrect.split('&')[1]

    const search = useLocation().search;
    const codigo = new URLSearchParams(search).get('code');

    useEffect(() => {
        api.post(`verify/${codigo}`).then(response => {
            if(response.data.success) {
                setVerified(true)
                setTimeout(()=>{
                    window.close();
                }, 7000);
            }
            if(response.data.error) {
                setVerified(false)
                setTimeout(()=>{
                    window.close();
                }, 7000);
            }
        })
        .catch(error => alert(error))
    }, [code])

    return(
        <>
            {verified 
                ?
                    <Container>
                        <img src={Logo}/>
                        <IoCheckmarkCircle 
                        size={130}
                        color='green'
                        />
                        <p>Usuário verificado com sucesso!</p>
                        <p style={{fontSize: 25}}>Essa tela se fechará em alguns segundos!</p>
                    </Container>
                :
                    <Container>
                        <img src={Logo}/>
                        <IoCloseCircle 
                        size={130}
                        color='red'
                        />
                        <p>Link expirado!</p>
                        <p style={{fontSize: 25}}>Essa tela se fechará em alguns segundos!</p>
                    </Container>
            }
        </>
    )
}