import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import { Tooltip } from '../../../../styles/global';

export const Background = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    right: 0;
`;

export const ModalContent = styled.div`
    max-width: 85%;
    max-height: 90%;
    margin: 0 auto;
    background: #fff;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    padding: 1rem;
    position: relative;
    overflow-y: auto;
    @media screen and (max-width: 828px){
        height: 90vh;
    }
`;

export const CloseModalButton = styled(MdClose)`
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
`;

export const Form = styled.form`
    width: 100%;
    text-align: center;
    h1{
        margin-bottom: 2rem;
        text-transform: uppercase;
    }
`;

export const FormContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    /* img{
        border: 2px solid red;
        height: 20vh;
        max-width: fit-content;
    } */
`;

export const FormGroup = styled.div`
    flex: 1 1 20rem;
    margin: 0 0.5rem 1.5rem 0.5rem;
    position: relative;
    svg{
        position: absolute;
        top: 25%;
        right: 1%;
        cursor: pointer;
    }
    img{
        max-width: fit-content;
        height: 20vh;
        max-height: fit-content;
    }
`;

export const Image = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    height:50%;
    width:100%;
    ${Tooltip}
`;

export const WapperImages = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    @media screen and (max-width: 882px){
        max-height: 150px;
    }
    @media screen and (max-width: 768px){
       overflow-y:auto;
    }
`;

export const ImageGroup = styled.div`
    display: flex;
    flex-direction: column;
    width: 22%;
    padding: 10px;
    img{
        width:100%;
        height:100%;
        /* border:6px solid #dddddd; */
        -webkit-box-shadow:0px 0px 18px 7px #c5c5c5 ;
        -moz-box-shadow:0px 0px 18px 7px #c5c5c5 ;
        box-shadow:0px 0px 18px 7px #c5c5c5 ;
    }
    @media screen and (max-width: 1024px){
        min-width: 120px;
    }
    /* @media screen and (max-width: 768px){
       
    } */

`;

export const Options = styled.div`
    width: 20%;
    bottom: 2%;
    display:flex;
    margin-left: 88%;
    margin-top: 5px;
    
    svg{
        font-size: 1.5rem;
        color: red;
        cursor: pointer;
    }
`;

export const BodyImage = styled.div`
    height:100%;
    width:100%;
`;

export const TitlePhoto = styled.div`
    width:100%;
    display:flex;
    justify-content: space-around;
    padding-bottom: 5px;
`


export const Delete = styled.div`
    position: absolute;
    bottom: 5%;
    right: 2%;
    svg{
        font-size: 2rem;
        color: red;
        cursor: pointer;
    }
`;