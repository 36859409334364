import React from 'react';
import { Container, Card, CardGroup, Options } from './styles';
import { IoCreateOutline, IoTrashOutline, IoImageOutline } from 'react-icons/io5';

export default function CardComponent({ loading, error, repeatLoading, data, list, handleClickedEdit, handleClickedImage, handleDelete }){
    return(
        <>
            {loading || error ? (
                <Container>
                    {repeatLoading.map(rl => (
                        <Card key={rl}>
                            {list.map(l => (
                                <CardGroup key={l}>
                                    <div></div>
                                    <div></div>
                                </CardGroup>
                            ))}
                            <Options>
                                <div></div>
                                <div></div>
                                <div></div>
                            </Options>
                        </Card>
                    ))}
                </Container>
            ) : (
                <Container>
                    {data.map((d, i) => (
                        <Card key={i}>
                            {list.map((l, i) => (
                                <CardGroup key={i}>
                                    <strong>{l.replace(/_/g, ' ')}:</strong>
                                    <span>{d[l]}</span>
                                </CardGroup>
                            ))}
                            <Options>
                                <IoImageOutline color="purple" onClick={() => handleClickedImage(d.id)} />
                                <IoCreateOutline color="#00F" onClick={() => handleClickedEdit(d.id)} />
                                <IoTrashOutline color="#F00" onClick={() => handleDelete(d.id)} />
                            </Options>
                        </Card>
                    ))}
                </Container>
            )}
        </>
    );
}