import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { TableContainer, TableHead, TableBody } from './styles';
import { IoPencilOutline, IoTrashOutline } from 'react-icons/io5';
import routeIcon from '../../../assets/images/iconRoute-removebg-preview.png'
import api from '../../../services/api'
import ModalEditStravaLink from '../../Modal/Routes/EditStravaLink';

export default function Table({ data, handleClicked, handleDelete }) {
    const history = useHistory();
    const [id, setId] = useState();
    const [rota, setRota] = useState();
    const [modalStrava, setModalStrava] = useState(false);

    return (
        <TableContainer>
            <ModalEditStravaLink
                    showModal={modalStrava}
                    setShowModal={setModalStrava}
                    id={id}
                    rota={rota}
                    setRota={setRota}
                />
            <TableHead>
                <tr>
                    <th>Cor</th>
                    <th>Nome da Rota</th>
                    <th>Distancia total</th>
                    <th>Rota</th>
                    <th>Editar</th>
                    <th>Excluir</th>
                </tr>
            </TableHead>
            <TableBody>
                {data.map((d, i) => (
                    <tr key={i}>
                        <td style={{ background: `${d.cor_hex}`, color: 'black' }} data-title='Cor'>{d.cor}</td>
                        <td data-title='Nome'>{d.nome}</td>
                        <td data-title='Percurso total'>{`${d.percurso_total}m`}</td>
                        <td data-title='Rota' style={{ padding: '5px' }} ><img src={routeIcon} style={{ width: '40px', height: '40px' }}
                            onClick={async () => {
                                if (d.link_strava) {
                                    if (d.link_strava.startsWith("http")) {
                                        window.open(d.link_strava, "_blank");
                                    } else {
                                        window.open(`http://${d.link_strava}`, "_blank");
                                    }
                                } else {
                                    history.push({
                                        pathname: `/routes/${d.id}`,
                                        state: d.id
                                    });
                                }
                            }}
                        />
                        </td>
                        <td data-title='Editar'>
                            <IoPencilOutline
                                color='blue'
                                onClick={async () => {
                                    if (d.link_strava) {
                                        setRota(d);
                                        setId(d.id);
                                        setModalStrava(!modalStrava);
                                    }
                                    else {
                                        history.push({
                                            pathname: `/admin/routes/edit/${d.id}`,
                                            state: d.id
                                        });
                                    }
                                }}
                            /></td>
                        <td data-title='Excluir'>
                            <IoTrashOutline
                                color='red'
                                onClick={async () => {
                                    await handleDelete(d.id)
                                }}
                            /></td>
                    </tr>
                ))}
            </TableBody>
        </TableContainer>
    );
}