import React, { useState, useRef, useEffect, useCallback } from 'react';
import Map from '../../components/Map/Add';
import { Form, FormGroup, Buttons, TableWapper, WapperDatesTravel, DatesTravel, GroupDatasTravel, ContainerColors, WapperColors, RowColors, NameColor, CloseModalButton, OpenModalButton } from './styles';
import TableRoutes from './TableRoutes';
import api from '../../services/api';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import Auth from "../../services/auth";
import { useSpring, animated } from 'react-spring';
import Pagination from '../../components/Pagination';

export default function AdminRoutes() {
    const history = useHistory();
    const [nome, setNome] = useState('');
    const [descricao, setDescricao] = useState('');
    const [cor, setCor] = useState('Vermelho');
    const [cor_hexa, setCor_hexa] = useState('#ff0000');
    const [directions, setDirections] = useState(null);
    const [addingRoute, setAddingRoute] = useState(false);
    const [routes, setRoutes] = useState('');
    const [totalDistance, setTotalDistance] = useState(0);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [selected, setSelected] = useState('');
    const [markers, setMarkers] = useState([]);
    const [id_waypoint, setId_waypoint] = useState(null);
    const [token, setToken] = useState(Auth.token())
    const markersRef = useRef([]);
    const [selectedCor, setSelectedCor] = useState(1);
    const [showModal, setShowModal] = useState(true);
    const [esconder, setEsconder] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    // Aprender a como usar
    const animation = useSpring({
        config: {
            duration: 150
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateX(0%)` : `translateX(-100%)`,
        // transition: 'all ease 2s'
    });

    const keyPress = useCallback(e => {
        if (e.key === 'Escape' && showModal) setShowModal(!showModal);
    }, [setShowModal, showModal]);

    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);

    useEffect(() => {
        console.log(page);
        setTotalPages(Math.ceil(markers.length / 5));
    }, [markers])

    async function handleSubmit(e) {
        e.preventDefault();

        if (nome == '' || descricao == '') return swal('Atenção!', 'Preencha todos os campos', 'warning');

        const data = {
            waypoints: markersRef.current,
            nome: nome,
            descricao: descricao,
            cor: cor,
            cor_hex: cor_hexa,
            percurso_total: totalDistance,
            directions: JSON.stringify(directions)
        }

        api.post(`admin/routes?auth=${token}`, { data }).then((response) => {
            if (response.data.error) return swal('Atenção!', response.data.error, 'warning')
            swal('Sucesso!', response.data.success, 'success').then(async () => {
                history.push("/admin/routes");
            })
            if (nome == null || descricao == null || cor == null || directions == null || cor_hexa == null) {
                swal('Atenção!', 'Preencha tudo para criar uma rota!', 'warning')
            }
            // history.push("/admin/routes");
        }).catch(error => alert(error));
    }

    return (
        <div>
            <Form onSubmit={handleSubmit} style={esconder ? { transform: showModal ? `translateX(0%)` : `translateX(-95%)`, transition: 'ease' } : { opacity: 1 }}>
                <animated.div style={animation}>
                    {addingRoute ?
                        <>
                            <TableWapper style={{ display: !showModal ? 'none' : '' }}>
                                <h1>Trechos da Rota</h1>
                                <WapperDatesTravel>
                                    <DatesTravel>
                                        <GroupDatasTravel>
                                            <input type="text" disabled placeholder="Nome da Rota" value={nome ? nome : "Não Informado"} />
                                            <label>Nome da rota</label>
                                        </GroupDatasTravel>
                                    </DatesTravel>
                                    <DatesTravel>
                                        <GroupDatasTravel>
                                            <input type="text" disabled placeholder="Distancia total" value={totalDistance ? `${totalDistance}m` : "0m"} />
                                            <label>Distancia total</label>
                                        </GroupDatasTravel>
                                    </DatesTravel>
                                </WapperDatesTravel>
                                <TableRoutes
                                    markers={markersRef.current.slice((page - 1) * 5, page * 5)}
                                />
                            </TableWapper>
                            <CloseModalButton onClick={() => {
                                setTimeout(() => {
                                    setEsconder(true);
                                }, 40);
                                setShowModal(!showModal);
                            }} />
                            <Pagination
                                page={page}
                                setPage={setPage}
                                totalPages={totalPages}
                            />
                        </>
                        : (
                            <>
                                <h1>Criar Rotas</h1>
                                <FormGroup>
                                    <input
                                        type="text"
                                        placeholder="Nome"
                                        value={nome}
                                        onChange={e => setNome(e.target.value)}
                                        maxlength='60'
                                        required
                                    />
                                    <label>Nome</label>
                                </FormGroup>
                                <FormGroup>
                                    <input
                                        type="text"
                                        placeholder="Descricao"
                                        value={descricao}
                                        onChange={e => setDescricao(e.target.value)}
                                        maxlength='60'
                                        required
                                    />
                                    <label>Descricao</label>
                                </FormGroup>
                                <WapperColors>
                                    <RowColors>
                                        <ContainerColors onClick={() => { setCor_hexa('#ff0000'); setCor('Vermelho'); setSelectedCor(1) }} style={{ background: '#ff0000', opacity: selectedCor == 1 ? 1 : 0.5 }} ><NameColor>Vermelho</NameColor></ContainerColors>
                                        <ContainerColors onClick={() => { setCor_hexa('#00ffff'); setCor('Ciano'); setSelectedCor(2) }} style={{ background: '#00ffff', opacity: selectedCor == 2 ? 1 : 0.5 }} ><NameColor>Ciano</NameColor></ContainerColors>
                                        <ContainerColors onClick={() => { setCor_hexa('#ff1493'); setCor('Rosa'); setSelectedCor(3) }} style={{ background: '#ff1493', opacity: selectedCor == 3 ? 1 : 0.5 }} ><NameColor>Rosa</NameColor></ContainerColors>
                                        <ContainerColors onClick={() => { setCor_hexa('#ffa500'); setCor('Laranja'); setSelectedCor(4) }} style={{ background: '#ffa500', opacity: selectedCor == 4 ? 1 : 0.5 }} ><NameColor>Laranja</NameColor></ContainerColors>
                                        <ContainerColors onClick={() => { setCor_hexa('#7CFC00'); setCor('Verde'); setSelectedCor(5) }} style={{ background: '#7CFC00', opacity: selectedCor == 5 ? 1 : 0.5 }} ><NameColor>Verde</NameColor></ContainerColors>
                                        <ContainerColors onClick={() => { setCor_hexa('#7B68EE'); setCor('Roxo'); setSelectedCor(6) }} style={{ background: '#7B68EE', opacity: selectedCor == 6 ? 1 : 0.5 }} ><NameColor>Roxo</NameColor></ContainerColors>
                                    </RowColors>
                                    <RowColors>
                                        <ContainerColors onClick={() => { setCor_hexa('#F0E68C'); setCor('Khaki'); setSelectedCor(7) }} style={{ background: '#F0E68C', opacity: selectedCor == 7 ? 1 : 0.5 }} ><NameColor>Khaki</NameColor></ContainerColors>
                                        <ContainerColors onClick={() => { setCor_hexa('#F08080'); setCor('Coral'); setSelectedCor(8) }} style={{ background: '#F08080', opacity: selectedCor == 8 ? 1 : 0.6 }} ><NameColor>Coral</NameColor></ContainerColors>
                                        <ContainerColors onClick={() => { setCor_hexa('#3CB371'); setCor('Oceano'); setSelectedCor(9) }} style={{ background: '#3CB371', opacity: selectedCor == 9 ? 1 : 0.5 }} ><NameColor>Oceano</NameColor></ContainerColors>
                                        <ContainerColors onClick={() => { setCor_hexa('#009aff'); setCor('Azul'); setSelectedCor(10) }} style={{ background: '#009aff', opacity: selectedCor == 10 ? 1 : 0.4 }} ><NameColor>Azul</NameColor></ContainerColors>
                                        <ContainerColors onClick={() => { setCor_hexa('#D2691E'); setCor('Chocolate'); setSelectedCor(11) }} style={{ background: '#D2691E', opacity: selectedCor == 11 ? 1 : 0.5 }} ><NameColor>Chocolate</NameColor></ContainerColors>
                                        <ContainerColors onClick={() => { setCor_hexa('#ffff40'); setCor('Amarelo'); setSelectedCor(12) }} style={{ background: '#ffff40', opacity: selectedCor == 12 ? 1 : 0.5 }} ><NameColor>Amarelo</NameColor></ContainerColors>
                                    </RowColors>
                                </WapperColors>
                                <CloseModalButton onClick={() => {
                                    setTimeout(() => {
                                        setEsconder(true);
                                    }, 40);
                                    setShowModal(!showModal);
                                }} />
                            </>
                        )}
                    <Buttons>
                        {addingRoute ? <button type="submit">Cadastrar</button> : null}
                        <button style={{ background: '#d9534f' }} type='button'
                            onClick={() => {
                                history.goBack()
                            }}
                        >
                            Cancelar
                        </button>
                        {addingRoute === true ?
                            <button type="button" style={{ background: '#5cb85c' }} onClick={() => setAddingRoute(false)}>Voltar a edição da rota</button> : (
                                <button type="button" style={{ background: '#5cb85c' }} onClick={() => setAddingRoute(true)}>Adicionar pontos na rota</button>
                            )}
                    </Buttons>
                </animated.div>
                {!showModal
                    ?
                    <OpenModalButton onClick={() => {
                        setShowModal(true)
                    }} />
                    :
                    ''
                }
                {addingRoute && !showModal
                    ?
                    <div style={{ background: "white", height: 258 }}></div>
                    :
                    null

                }
            </Form>
            <Map
                markers={markers}
                setMarkers={setMarkers}
                setRoutes={setRoutes}
                markersRef={markersRef}
                showModalEdit={showModalEdit}
                setShowModalEdit={setShowModalEdit}
                setSelected={setSelected}
                selected={selected}
                setTotalDistance={setTotalDistance}
                totalDistance={totalDistance}
                setId_waypoint={setId_waypoint}
                directions={directions}
                setDirections={setDirections}
                cor_hexa={cor_hexa}
            />
        </div>
    );
}