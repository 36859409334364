import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Title, Options, SerchArea, Pagination, ButtonAdd } from './styles';
import api from '../../services/api';
import Table from '../../components/Tables/Occurrences';
import ModalRegister from '../../components/Modal/Occurrences/Register';
import ModalEdit from '../../components/Modal/Occurrences/Edit';
import Filters from '../../components/Filters/Occurrences';
import { IoSearch, IoAddOutline } from "react-icons/io5";
import swal from 'sweetalert'
import Auth from '../../services/auth';
import { Logout } from '../../services/logout';

export default function Occurrences() {
    const [occurrences, setOccurrences] = useState([]);
    const [totalPages, setTotalPages] = useState('');
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [segmento, setSegmento] = useState({ show: 'TODOS' });
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [showModalRegister, setShowModalRegister] = useState(false);
    const [occurrenceSelected, setOccurrenceSelected] = useState('');
    const [filters, setFilters] = useState({ show: 'TODOS', search: '' });
    const [token, setToken] = useState(Auth.token())
    const history = useHistory();

    function handlePage(action) {
        setPage(action === 'back' ? page - 1 : page + 1);
    }

    // Talvez precisa colocar & depois da interrogação

    useEffect(() => {
        api.get(`/guard/occurrences?_tipo=${filters.show}&_page=${page}&auth=${token}`).then(response => {
            setOccurrences(response.data.ocorrencias);
            setTotalPages(response.data.totalPages);
        }).catch(error => {
            console.log(error);
            if (error.response.status === 401) {
                Logout();
            }
        });
    }, [filters, page]);

    function handleClickedEdit(id) {
        setShowModalEdit(!showModalEdit);
        setOccurrenceSelected(id);
    }

    async function handleDelete(id) {
        try {
            swal({
                title: "Você tem certeza?",
                text: "A ocorrência selecionada será deletada permanentemente",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {
                        await api.delete(`guard/occurrences/${id}?auth=${token}`)
                        /**
                         * Mantém na lista os IDS que não foram excluídos, e exclui somente o que foi clicado e enviado via json
                         */
                        setOccurrences(occurrences.filter(occurrence => occurrence.id !== id));
                        swal("Ocorrência deletada com sucesso!", {
                            icon: "success",
                        })
                    } else {
                        swal("Ocorrência não deletada!")
                    }
                })
        } catch (error) { swal('Atenção!', 'Serviço indisponível no momento', 'error'); }
    }

    return (
        <Container>
            <Title>Ocorrências</Title>
            <Options>
                {/* <ButtonAdd 
                    onClick={()=>{
                        setShowModalRegister(!showModalRegister)
                    }}
                >
                    <IoAddOutline 
                        id="iconAdd"
                        size={23}
                    />
                </ButtonAdd> */}
                {/* <button type="button" >Adicionar</button> */}
                {/* <SerchArea>
                    <input
                        type="search"
                        placeholder="Pesquise pelo nome do parceiro"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        minLength="3"
                    />
                    <IoSearch style={{position: "absolute", left: 6}}/>
                </SerchArea> */}
            </Options>

            <Filters
                filters={filters}
                setFilters={setFilters}
                data={['TODOS', 'FURTO', 'ROUBO',  'FRAUDE']}
            />

            {occurrences == ''
                ?
                <h1>Aperte no botão + para adicionar ocorrências</h1>
                :
                <>
                    <Table
                        data={occurrences}
                        // list={['tipo', 'data recebimento', 'status']}
                        handleClicked={handleClickedEdit}
                        handleDelete={handleDelete}
                    />

                    <Pagination>
                        {page <= 1 ? (
                            <button disabled>Anterior</button>
                        ) : (
                            <button onClick={() => handlePage('back')}>Anterior</button>
                        )}
                        <small color="secondary">Página {page} / {totalPages}</small>
                        {page < totalPages ? (
                            <button onClick={() => handlePage('next')}>Próximo</button>
                        ) : (
                            <button disabled>Próximo</button>
                        )}
                    </Pagination>
                </>
            }

            <ModalEdit
                showModal={showModalEdit}
                setShowModal={setShowModalEdit}
                id={occurrenceSelected}
            />

            {/* <ModalRegister
                showModal={showModalRegister}
                setShowModal={setShowModalRegister}
                id={occurrenceSelected}
            /> */}
        </Container>
    );
}