import { createGlobalStyle, css } from 'styled-components';

export default createGlobalStyle`
    :root {
        --blue-color: #3664F4;
        --dark-color: #070D1F;
        --dark-alt-color: #282B3A;
        --white-color: #E6E7E9;
        --background-color: #F4F4F4;
        --font-color: #212529;
        --small-font-size: .875rem;
        --tooltip-scale: 0;
        --tooltip-arrow-size: 5px;
        --tooltip-color: #000;
        --border-radius: 0.25rem;
        --box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
    }
    *{
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }
    body{
        font-family: 'Ubuntu', sans-serif;
        -webkit-font-smoothing: antialiased;
        font-size: 1rem;
        font-weight: 400;
        color: var(--font-color);
        background: var(--background-color);
        width: calc(100vw - 0.28rem);
        &::-webkit-scrollbar-track{
            box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            background-color: var(--background-color);
        }
        &::-webkit-scrollbar{
            width: 0.3rem;
            background-color: var(--background-color);
        }
        &::-webkit-scrollbar-thumb{
            box-shadow: inset 0 0 6px rgba(0,0,0,.3);
            background-color: var(--dark-color);
        }
    }
    a{
        text-decoration: none;
        cursor: pointer;
    }
    button{
        cursor: pointer;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        font-weight: 600;
        border: 1px solid transparent;
        padding: .625rem 1.25rem;
        line-height: 1.5;
        border-radius: var(--border-radius);
        text-transform: uppercase;
        will-change: transform;
        letter-spacing: .025em;
        font-size: .875rem;
        color: #fff;
        background-color: var(--blue-color);
        border-color: var(--blue-color);
        box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
        transition: all .3s linear;
        &:hover{
            filter: brightness(90%);
            box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
            transform: translateY(-1px);
        }
        &:disabled{
            opacity: 50%;
            cursor: not-allowed;
        }
        svg{
            vertical-align: middle;
            margin-left: 0.1rem;
        }
    }
    input[type=text],input[type=search], input[type=date],input[type=email],input[type=password]{
        display: block;
        background: #E6E7E9;
        margin: 0;
        padding: 0.8rem 1.6rem;
        color: var(--font-color);
        width: 100%;
        font-size: var(--font-size);
        border: none;
        border-radius: var(--border-radius);
        transition: box-shadow 300ms;
        &::placeholder{
            color: #999;
        }
        &:focus{
            outline: none;
            box-shadow: var(--box-shadow);
        }
        &:hover{
            box-shadow: var(--box-shadow);
        }
        &:placeholder-shown + label{
            visibility: hidden;
            z-index: -1;
        }
        &:not(:placeholder-shown) + label,
        &:focus:not(:placeholder-shown) + label{
            visibility: visible;
            z-index: 1;
            opacity: 1;
            transform: translate3d(0, -1.5rem, 0) scale(0.8);
            transition: transform 300ms, visibility 0ms, z-index 0ms;
        }
    }
    label{
        display: block;
        white-space: nowrap;
        position: absolute;
        bottom: 50%;
        left: 1rem;
        color: var(--dark-color);
        font-size: var(--small-font-size);
        font-weight: 800;
        text-transform: uppercase;
        opacity: 0;
        transform: translate3d(0, 50%, 0) scale(1);
        transform-origin: 0 0;
        transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1), transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0ms 300ms cubic-bezier(0.645, 0.045, 0.355, 1), z-index 0ms 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    select{
        display: block;
        background: #E6E7E9;
        margin: 0;
        padding: 0.7rem 1.6rem;
        color: var(--font-color);
        width: 100%;
        font-size: var(--font-size);
        border: none;
        border-radius: var(--border-radius);
        transition: box-shadow 300ms;
        &::placeholder{
            color: #999;
        }
        &:focus{
            outline: none;
            box-shadow: var(--box-shadow);
        }
        &:hover{
            box-shadow: var(--box-shadow);
        }
        &:placeholder-shown + label{
            visibility: hidden;
            z-index: -1;
        }
        &:not(:placeholder-shown) + label,
        &:focus:not(:placeholder-shown) + label{
            visibility: visible;
            z-index: 1;
            opacity: 1;
            transform: translate3d(0, -1.5rem, 0) scale(0.8);
            transition: transform 300ms, visibility 0ms, z-index 0ms;
        }
    }
`;
export const Tooltip = css`
    &::before, &::after{
        position: absolute;
        top: -.25rem;
        left: 50%;
        transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--tooltip-scale));
        transition: 150ms transform;
        transform-origin: bottom center;
    }
    &::before{
        --translate-y: calc(-100% - var(--tooltip-arrow-size));
        content: attr(data-tooltip);
        font-size: 10px;
        color: #fff;
        padding: 5px;
        border-radius: var(--border-radius);
        text-align: center;
        width: max-content;
        max-width: 100%;
        background: var(--tooltip-color);
    }
    &::after{
        --translate-y: calc(-1 * var(--tooltip-arrow-size));
        content: '';
        border: var(--tooltip-arrow-size) solid transparent;
        border-top-color: var(--tooltip-color);
        transform-origin: top center;
    }
    &:hover::before, &:hover::after{
        --tooltip-scale: 1;
    }
`;