import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import { Tooltip } from '../../../styles/global';

export const Background = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    left: 0;
`;

export const ModalContent = styled.div`
    max-width: 50%;
    height: 90%;
    margin: 0 auto;
    background: #fff;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    padding: 1rem;
    position: relative;
    overflow-y: auto;
    @media screen and (max-width: 939px){
        /* height: 800px;   */
        /* height: 90vh; */
        max-width: 75%;
    }
`;

export const CloseModalButton = styled(MdClose)`
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
`;

export const Form = styled.form`
    width: 100%;
    text-align: center;
    h1{
        margin-bottom: 2rem;
        text-transform: uppercase;
    }   
`;

export const FormContent = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const Buttons = styled.div`
    display: flex;
    align-items:center;
    justify-content: space-around;
    margin-top: 5px;
`;

export const FormGroup = styled.div`
    flex: 1 1 20rem;
    margin: 0 0.5rem 1.5rem 0.5rem;
    position: relative;
    svg{
        position: absolute;
        top: 25%;
        right: 1%;
        cursor: pointer;
    }
    img{
        max-width: 3rem;
        height: auto;
    }
`;

export const TextArea = styled.div`
    display:flex;
    align-items:left;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;
    textarea{
        display: block;
        background: #E6E7E9;
        margin: 0;
        padding: 0.8rem 1.6rem;
        color: var(--font-color);
        width: 100%;
        font-size: var(--font-size);
        border: none;
        border-radius: var(--border-radius);
        transition: box-shadow 300ms;
        &::placeholder{
            color: #999;
        }
        &:focus{
            outline: none;
            box-shadow: var(--box-shadow);
        }
        &:hover{
            box-shadow: var(--box-shadow);
        }
        &:placeholder-shown + label{
            visibility: hidden;
            z-index: -1;
        }
        &:not(:placeholder-shown) + label,
        &:focus:not(:placeholder-shown) + label{
            visibility: visible;
            z-index: 1;
            opacity: 1;
            transform: translate3d(0, -1.5rem, 0) scale(0.8);
            transition: transform 300ms, visibility 0ms, z-index 0ms;
        }
    }
    label{
        display: block;
        white-space: nowrap;
        position: absolute;
        bottom: 50%;
        left: 1rem;
        top:8px;
        color: var(--dark-color);
        font-size: var(--small-font-size);
        font-weight: 800;
        text-transform: uppercase;
        opacity: 0;
        transform: translate3d(0, 50%, 0) scale(1);
        transform-origin: 0 0;
        transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1), transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0ms 300ms cubic-bezier(0.645, 0.045, 0.355, 1), z-index 0ms 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
    }
`;

export const Options = styled.div`
    width: 20%;
    bottom: 2%;
    display:flex;
    margin-left: 88%;
    margin-top: 5px;
    
    svg{
        font-size: 1.5rem;
        color: red;
        cursor: pointer;
    }

`;


export const Delete = styled.div`
    position: absolute;
    bottom: 0%;
    right: 0%;
    svg{
        font-size: 2rem;
        color: red;
        cursor: pointer;

    }

`;

export const FormButtons = styled.div`
    display: flex;
    width:30%;
    justify-content: space-around;
    margin-left: 33%;
`;