import React, { useState, useEffect } from 'react';
import { Container, Title, Options, SerchArea, Pagination, ButtonAdd, Tr } from './styles';
import Table from '../../components/Tables/Routes';
import ModalAddStravaLink from '../../components/Modal/Routes/AddStravaLink';
import api from '../../services/api';
import { IoSearch, IoAddOutline } from "react-icons/io5";
import swal from 'sweetalert'
import swal2 from 'sweetalert2'
import Navbar from '../../components/Navbar'
import Auth from "../../services/auth";
import { Logout } from '../../services/logout';
import { useHistory } from 'react-router-dom';

export default function AdminBicycles() {
    const [routes, setRoutes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [routeSelected, setRouteSelected] = useState(1);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [reload, setReload] = useState(false);
    const [token, setToken] = useState(Auth.token());
    const history = useHistory();
    const [modalStrava, setModalStrava] = useState(false);

    function chooseAdd() {
        swal2.fire({
            title: 'Adicionar Rota',
            text: 'Escolha um modo:',
            showCancelButton: true,
            confirmButtonText: 'Maps',
            confirmButtonColor: '#41ad58',
            cancelButtonText: 'Strava',
            cancelButtonColor: '#fc671f',
        }).then((result) => {
            if (result.isConfirmed) {
                history.push("/admin/routes/add");
            } else if (result.dismiss === 'cancel') {
                setModalStrava(!modalStrava);
            }
        })
    }

    function handleClicked(id) {
        setShowModal(!showModal);
        setRouteSelected(id);
    }

    function handleDelete(id) {
        try {
            swal({
                title: "Você tem certeza?",
                text: "Uma vez deletado o usuário terá que refazer a rota",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {
                        api.delete(`admin/routes/${id}?auth=${token}`);
                        setRoutes(routes.filter(route => route.id !== id));
                        setReload(!reload)
                        swal("Rota deletada com sucesso!", {
                            icon: "success",
                        })
                    } else {
                        swal("Rota não deletada!")
                    }
                })
        } catch (error) {
            alert('Erro ao deletar a Rota!');
        }
    }
    function handlePage(action) {
        setPage(action === 'back' ? page - 1 : page + 1);
    }
    useEffect(() => {
        api.get(`admin/routes?&_search=${search}&_page=${page}&auth=${token}`).then(response => {
            setRoutes(response.data.routes);
            setTotalPages(response.data.totalPages);
        }).catch(error => {
            if (error.response.status === 401) {
                Logout();
            }
            console.log(error);
        });
    }, [search, page]);
    return (
        <>
            <Navbar />
            <Container>
                <Title>Rotas</Title>
                <Options>
                    <ButtonAdd onClick={e => chooseAdd()}>
                        <IoAddOutline id="iconAdd" />
                    </ButtonAdd>
                    <SerchArea>
                        <input
                            type="search"
                            placeholder="Pesquise pelo nome da rota"
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            minLength="3"
                        />
                        <IoSearch />
                    </SerchArea>
                </Options>
                <ModalAddStravaLink
                    showModal={modalStrava}
                    setShowModal={setModalStrava}
                />
                {routes == ''
                    ?
                    <h1>Aperte no botão + para adicionar mais rotas</h1>
                    :
                    <>
                        <Table
                            data={routes}
                            list={['nome', 'cor', 'endereco']}
                            handleClicked={handleClicked}
                            handleDelete={handleDelete}
                        />
                        <Pagination>
                            {page <= 1 ? (
                                <button disabled>Anterior</button>
                            ) : (
                                <button onClick={() => handlePage('back')}>Anterior</button>
                            )}
                            <small color="secondary">Página {page} / {totalPages}</small>
                            {page < totalPages ? (
                                <button onClick={() => handlePage('next')}>Próximo</button>
                            ) : (
                                <button disabled>Próximo</button>
                            )}
                        </Pagination>
                    </>
                }
            </Container>
        </>
    );
}